import { Routes } from '@angular/router';
import { FooterHelpComponent } from './project-common/components/footer-help/footer-help.component';
import { StoreNotificationsComponent } from './project-common/components/store-notifications/store-notifications.component';
import { PreBookComponent } from './project-common/components/pre-book/pre-book.component';
import { CampaignGuard } from './project-common/services/campaign.guard';
import { LogoutHandlerComponent } from './storefront/logout-handler/logout-handler.component';
var ɵ0 = { title: 'Search results' }, ɵ1 = { title: 'Payment failed' }, ɵ2 = { title: 'Order payment success' }, ɵ3 = { title: 'Kalgudi help' };
var routes = [
    { path: 'pre-book', component: PreBookComponent },
    { path: 'notifications', component: StoreNotificationsComponent },
    { path: 'checkout', loadChildren: '../app/checkout/checkout.module#CheckoutModule', canActivate: [CampaignGuard] },
    { path: 'auth', loadChildren: '../app/authentication/authentication.module#AuthenticationModule' },
    { path: 'account', loadChildren: '../app/profile/profile.module#ProfileModule' },
    { path: 'order', loadChildren: '../app/orders/orders.module#OrdersModule' },
    { path: 'support', loadChildren: '../app/support/support.module#SupportModule' },
    { path: 'search', loadChildren: '../app/modules/search/search.module#SearchModule', data: ɵ0 },
    { path: 'order_details/:id', redirectTo: 'order/view/:id' },
    { path: 'order_summary/:id', redirectTo: 'order/success/:id' },
    { path: 'payment-failure/:orderId', redirectTo: 'order/failed', data: ɵ1 },
    { path: 'order-payment-success/:orderId', redirectTo: 'order/payment/success', data: ɵ2 },
    { path: 'help/:helpertext', component: FooterHelpComponent, data: ɵ3 },
    {
        path: '',
        loadChildren: '../app/storefront/storefront.module#StorefrontModule'
    },
    {
        path: 'home',
        redirectTo: '',
        pathMatch: 'full'
    },
    {
        path: 'logout', component: LogoutHandlerComponent
    },
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
