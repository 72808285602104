import { Assistant } from './../../models/requirement.model';
import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
    selector: 'side-links',
    templateUrl: './side-links.component.html',
    styleUrls: ['./side-links.component.scss']
})
export class SideLinksComponent implements OnInit, OnDestroy {

    showBackToTopBtn = false;
    isButtonAvailableInBottom = false;
    isProduction: boolean;

    constructor(public router: Router) { }
    scrollTop = () => {
        const rootElement = document.getElementById('app-root');
        rootElement.scrollIntoView({ behavior: 'smooth'});
    }

    // @HostListener('window: scroll', [])
    onWindowScroll() {
        this.showBackToTopBtn = window.pageYOffset > 500 ? true : false;
    }

    private setChatBoxPosition() {
        const smartsupp = document.getElementById('backToTop');
        const buttonsInBottom = document.getElementById('placeBtnInBottom');
        if (smartsupp) {
            if (window.screen.width < 600) {
                if (buttonsInBottom) {
                    smartsupp.style.bottom = "60px";
                } else {
                    smartsupp.style.bottom = "20px";
                }
            }
        }
    }

    get isHome() {
        const url = this.router.url;
        return url.includes('/auth');
    }

    ngOnInit() {
        // setTimeout(() => {
        //     this.setChatBoxPosition();
        // }, 5000);
        this.isProduction = environment.production && location.origin.includes('andhragreens');
        window.addEventListener('scroll', this.onWindowScroll.bind(this), { passive: true });
    }

    ngOnDestroy() {
        window.removeEventListener('scroll', this.onWindowScroll.bind(this), false);
    }

    get hideRequirement() {
        const url = this.router.url;
        return url.includes('/auth') || url.includes('/quotation/send') || this.assistant;
    }

    get assistant(): Assistant | null {
        return JSON.parse(localStorage['assistant'] || null);
    }
}
