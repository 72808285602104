const host = 'andhragreens.com';

export const environment = {
    production: window.location.origin.includes('andhragreens.com'),
    awsAccessKey: ``,
    googleApiKey: `AIzaSyDbvNkwROoidEzYlhN9rLL52X0iGUmuQ-o`,
    nodeServer: location.origin.includes('localhost') ? 'https://andhragreens.com' : location.origin,
    bucketUrl: `https://kalgudidata.s3.amazonaws.com`,
    consumerStoreDomain: `https://kalgudi.com/store`,
    bizStoreDomain: `https://andhragreens.com`,
    coreDomain: 'https://core.kalgudi.com',
    farmerStoreDomain: `https://${host}`,
    baseUrl: location.origin.includes('localhost') ? `https://${host}` : location.origin,
    restBaseUrl: `https://${host}/rest/v1`,
    restBaseUrlV2: `https://${host}/v2`,
    version: '190624',
    source: 'WEB',
    sellerProfile: 'M01na730PRFREG2019103031312545UNH001'
};
