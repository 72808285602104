import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { NotificationService } from '../../project-common/services/notification.service';
import { AttachedImage } from '../models/image.model';
import { ScaleImagePipe } from '../pipes/scale-image.pipe';
import { GoogleAnalyticsService } from './google-analytics.service';

declare var LocalFileSystem: any;
declare var FileTransfer: any;
declare global {
    interface Window {
        cordova: any;
        requestFileSystem: any;
    }
}

@Injectable({
    providedIn: 'root'
})
export class UtilityService {

    constructor(private router: Router, private notification: NotificationService, private gaTrack: GoogleAnalyticsService) { }


    /**
     * This will return an object by grouping the list objects by their passed key's value
     * Each fields returned by this method, will contain an array list having the same value
     * in given @param key.
     * @param list Set of objects
     * @param key A key available in all object of the set
     */
    groupBy(list: any[], key: string): any {
        return list.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    }

    toArray(object: any) {
        return Object.keys(object).map(function (key) {
            return { 'key': key, 'list': object[key], 'displayName': object[key][0].displayName };
        });
    }

    sort(array: any[], key: string) {
        array.sort((a, b) =>
            a[key].localeCompare(b[key])
        );
        return array;
    }

    /**
     * To open full description page of product
     * @param product product tile object
     */
    openProductFullView(product, position?: number) {
        const productName = this.getEncodedProductName(product.productName_level2);
        this.sendProductClickTo(product, position);
        if (this.isMobileDevice()) {
            this.openInSameTab(product, productName);
        } else {
            this.openInNewTab(product, productName);
        }
    }

    getProductPageUrl(product): string[] {
        const routers = [];
        routers.push('/p');
        routers.push(product.category || 'c');
        routers.push(this.getEncodedProductName(product.productName_level3 || product.productName_level2));
        routers.push(`${(product.level2ShortId || product.productId_level2)}_${product.level3ShortId || product.productId_level3}`);
        return routers;
    }

    getFullViewLink(product, position?: number) {

        // this.sendProductClickTo(product, position);
        const productName = this.getEncodedProductName(product.productName_level3);
        // const pathname = this.getPathName(window.location.pathname);
        const category = this.getEncodedProductName(product.category || 'c');
        let url = `${this.getPathName()}p/${category.toLocaleLowerCase()}/${productName.toLocaleLowerCase()}/${product.productId_level2}_${product.productId_level3}`;
        return url;
    }

    getProductFullViewRoutingPath(product) {
        return [
            '/p',
            this.getEncodedProductName(product.category || 'c'),
            this.getEncodedProductName(product.productName_level3),
            product.productId_level2 + '_' + product.productId_level3
        ]
    }

    sendProductClickTo(product, position: number = 0) {
        const listType = this.router.url === '/home' ? 'Home page' : 'Search Results';
        try {
            this.gaTrack.updateProductClickToAnalytics (product, listType, position);
        } catch (e) {
            console.error(`Failed to submit details for analytics`);
        }

    }

    isMobileDevice() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    private openInSameTab(product, productName: string) {
        if (!product.productId_level3) {

            this.router.navigate([
                'c', productName,
                'cid', product.productId_level2
            ]);
            return;
        }
        this.router.navigate([
            'c', productName,
            'cid', product.productId_level2,
            'csub', product.productId_level3
        ]);
    }

    private openInNewTab(product, productName: string) {
        const pathname = this.getPathName(window.location.pathname);
        let url = window.location.origin + pathname + 'c/' + productName +
            '/cid/' + product.productId_level2;

        if (product.productId_level3) {
            url += '/csub/' + product.productId_level3;
        }
        window.open(url, '_blank');
    }

    public getPathName(path?: string) {
        const pathName = path || window.location.pathname;
        return pathName.includes('trade') ? '/trade/' : '/';
    }

    public getEncodedProductName(input: string): string {
        try {
            input = input.replace(/[\(\)\&-\/\\\%\s]/g, '-').replace(/ /g, '-')    // Replaces special characters + spaces with -
                .replace(/[-]{1,}/g, '-');            // Replaces more than one - with single -

            return input.substr(0, (input.lastIndexOf('-') === input.length - 1 ? input.length - 1 : input.length))
                .toLowerCase();                         // Convert the uri to lowercase
        } catch (e) {
            return `-`;
        }
        // return text.replace(/[&\/\\#,+()$~%.'":*?<>{} ]/g, '-').toLowerCase();
    }

    public getImageDimension(image: AttachedImage, index = '0'): Observable<AttachedImage> {
        return new Observable(observer => {
            const img = new Image();
            const scaleImage = new ScaleImagePipe();
            img.onload = function (event) {
                const loadedImage: any = event.currentTarget;
                image.width = loadedImage.width;
                image.height = loadedImage.height;
                image.index = index;
                observer.next(image);
                observer.complete();
            }
            img.src = scaleImage.transform(image.url, 'sm');
        });
    }


    /**
    * To get mobile number of user
    * @param UserProfileData user profile object
    */
    getUserMobileNumber(user) {
        let mobile = '';
        if (!user.mobileNo.includes(user.profileKey.substring(1, 7))) {
            mobile = user.mobileNo.replace(user.mobileCode, '');
        } else if (user.alternateMobileNo) {
            mobile = user.alternateMobileNo;
        }
        return mobile;
    }

    /**
     * To accept numbers only
     * @param event
     */
    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    checkOfferValidity(data?) {

        var offer = data;

        if (!data) {
            offer = this.getStoreMasterData();
            offer = offer.offer;
        }

        if (!offer) {
            return false;
        }

        if (!offer.offerEnds || !offer.offerStarts) {
            return offer.isOfferValid;
        }

        const offerStarts = new Date(offer.offerStarts);
        const today = new Date();
        const offerEnds = new Date(offer.offerEnds);
        return (today >= offerStarts && today <= offerEnds) ? true : false;
    }

    getStoreMasterData() {
        if (localStorage['storeMasterData']) {
            return JSON.parse(localStorage['storeMasterData']);
        } else {
            return {};
        }
    }

    /**
     * To switch the language
     * @param languageCode language code example : `en`
     */
    switchLanguage(languageCode) {
        this.notification.notifyForLanguageChange(languageCode);
    }

    public downloadAndShowFileOnApk(filePath: string, fileName: string): void {
        window.requestFileSystem(LocalFileSystem.PERSISTENT, 0, fileSystem => {
            const directoryEntry = fileSystem.root; // to get root path of directory
            directoryEntry.getDirectory("Download", {
                create: true, exclusive: false
            }, parent => {
                console.log('directory created ', parent);
            }, failed => {
                console.log('Failed to create directory ', failed);
            });

            var fp = window.cordova.file.externalRootDirectory; // Returns Fullpath of local directory

            fp = fp + "Download" + "/" + fileName; // fullpath and name of the file which we want to give
            var fileTransfer = new FileTransfer();
            fileTransfer.download(filePath, fp, entry => {
                window.open(entry.toURL(), '_system', 'location=yes');
            }, error => {
                console.log(error);
            });
        }, event => {
            console.log(event);
        });
    }

    getEmailId(user) {
        let email = '';
        if (user.emailId) {
            return user.emailId;
        }
        else if (user.alternateEmailId) {
            email = user.alternateEmailId;
        }
        return email;
    }


    goToAttachSeller( requirement ) {
    const { VProductId } = requirement.product;
    localStorage['rfqBaseProductId'] = VProductId;

    let priceRange: any = requirement.priceRange;
        const { minimum, maximum } = priceRange;
        if((minimum > 0) || (maximum > 0)) {
        this.router.navigate(['/dashboard', 'attach-seller', requirement.requirementId], {
            queryParams: {
              minimum: minimum,
              maximum: maximum
            }
        });
    }else {
      this.router.navigate(['/dashboard', 'attach-seller', requirement.requirementId])
    }
  }

  prepareShortL3Object (l3) {
    return {
      "LUT":l3.LUT,
      "mrp":l3.mrp,
      "createdTS":l3.createdTS,
      "languageId":l3.languageId,
      "sellerType":l3.sellerType,
      "isBulkOrder":l3.isBulkOrder,
      "isForReview":l3.isForReview,
      "isPublished":l3.isPublished,
      "productType":l3.productType,
      "selectedSKU":l3.selectedSKU,
      "languageName":l3.languageName,
      "pricePerUnit":l3.pricePerUnit,
      "smartElements":l3.smartElements,
      "masterLevel1Id":l3.masterLevel1Id,
      "masterLevel2Id":l3.masterLevel2Id,
      "masterLevel3Id":l3.masterLevel3Id,
      "productLevel1Id":l3.productLevel1Id,
      "productLevel2Id":l3.productLevel2Id,
      "productLevel3Id":l3.productLevel3Id,
      "productLevel1Title":l3.productLevel1Title,
      "productLevel2Title":l3.productLevel2Title,
      "productLevel3Title":l3.productLevel3Title,
      "freeShippingEligible":l3.freeShippingEligible,
      "productLevel3ShortTitle":l3.productLevel3ShortTitle,
      "baseProduct":l3.baseProduct,
      "defaultImage":l3.defaultImage,
      "attachments": l3.attachments,
      "manufacturerDetails": l3.manufacturerDetails
    }
  }
}

