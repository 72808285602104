import { environment } from 'src/environments/environment';
import { LocalStorage } from '@ngx-pwa/local-storage';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-pwa/local-storage";
var GoogleAnalyticsService = /** @class */ (function () {
    function GoogleAnalyticsService(storage) {
        this.storage = storage;
    }
    /**
    * To track current page to google analytics
    *
    * @param {string} pagePath Path of the page which user views
    * @param {string} pageTitle Title of the page
    */
    GoogleAnalyticsService.prototype.configPageViewToAnalytics = function (pagePath, pageTitle) {
        if (environment.production) {
            gtag("config", "UA-166326147-1", {
                page_path: pagePath,
                page_title: pageTitle
            });
            /* gtag('event', 'page_view', {
              'send_to': 'AW-936859343',
              'user_id': localStorage['dXNlcm5hbWU='] ? atob(localStorage['dXNlcm5hbWU=']) : 'Guest_user'
            }); */
        }
    };
    GoogleAnalyticsService.prototype.updateCheckoutStepOnAnalytics = function (cart, step) {
        var _this = this;
        this.productCheckout = {
            'event': 'checkout',
            'ecommerce': {
                'checkout': {
                    'actionField': {},
                    'products': []
                }
            }
        };
        this.productCheckout.ecommerce.checkout.actionField.step = step;
        cart.products.forEach(function (product) {
            _this.productCheckout.ecommerce.checkout.products.push({
                'name': product.productLevel3Title,
                'id': product.productLevel3Id,
                'price': product.pricePerUnit,
                'brand': product.sellerName,
                'category': product.productLevel1Title,
                'variant': '',
                'quantity': product.productQuantity
            });
            _this.pushToDataLayer(_this.productCheckout);
        });
    };
    /**
     *
     * @param orderDetails of buyer
     */
    GoogleAnalyticsService.prototype.updateOrderSuccessInfoOnAnalytics = function (orderDetails) {
        var _this = this;
        orderDetails.forEach(function (order) {
            _this.purchaseObject = {
                'event': 'purchase',
                'ecommerce': {
                    'purchase': {
                        'actionField': {
                            'id': order.orderId,
                            'affiliation': 'Andhragreens',
                            'revenue': order.totalAmount,
                            'tax': 0,
                            'shipping': order.fulfillmentDeatils.TRANSPORT.commission,
                            'coupon': ''
                        },
                        'products': order.productList.map(function (product) {
                            var variant = product.smartElements[product.selectedSKU[0]];
                            return {
                                'name': product.productLevel3Title,
                                'id': product.productLevel3Id,
                                'price': product.pricePerUnit,
                                'brand': product.sellerName,
                                'category': product.productCategory,
                                'variant': variant.value + " " + variant.unit,
                                'quantity': product.productQuantity,
                                'coupon': ''
                            };
                        })
                    }
                }
            };
            _this.pushToDataLayer(_this.purchaseObject);
        });
    };
    /**
     *
     * @param {number} step position of checkout process
     * @param option activity occurs in that step
     */
    GoogleAnalyticsService.prototype.updateCheckoutOptionOnAnalytics = function (step, option) {
        this.productCheckoutOption = {
            'event': 'checkoutOption',
            'ecommerce': {
                'checkout_option': {
                    'actionField': {}
                }
            }
        };
        this.productCheckoutOption.ecommerce.checkout_option.actionField.step = step;
        this.productCheckoutOption.ecommerce.checkout_option.actionField.option = option;
        this.pushToDataLayer(this.productCheckoutOption);
    };
    /**
     *
     * @param clickedProduct Product clicked by user
     * @param listType product is clicked on search page or home page
     * @param position position of product
     */
    GoogleAnalyticsService.prototype.updateProductClickToAnalytics = function (clickedProduct, listType, position) {
        this.productClickObject = {
            'event': 'productClick',
            'ecommerce': {
                'click': {
                    'actionField': {},
                    'products': []
                }
            }
        };
        this.productClickObject.ecommerce.click.actionField.list = listType;
        this.productClickObject.ecommerce.click.products.push({
            'name': clickedProduct.productName_level3,
            'id': clickedProduct.productId_level3,
            'price': clickedProduct.pricePerSKU,
            'brand': clickedProduct.sellerName,
            'category': clickedProduct.category,
            'variant': clickedProduct.SKUSmartElements[clickedProduct.selectedSKU[0]].value + ' ' + clickedProduct.SKUSmartElements[clickedProduct.selectedSKU[0]].unit,
            'position': position + 1
        });
        this.pushToDataLayer(this.productClickObject);
    };
    /**
     *
     * @param selectedProduct products added to cart
     */
    GoogleAnalyticsService.prototype.updateAddToCartToAnalytics = function (selectedProduct) {
        this.addToCartObject = {
            'event': 'addToCart',
            'ecommerce': {
                'currencyCode': 'INR',
                'add': {
                    'products': []
                }
            }
        };
        //  adding  products to shopping cart.
        this.addToCartObject.ecommerce.add.products.push({
            'name': selectedProduct.productLevel3Title,
            'id': selectedProduct.productLevel3Id,
            'price': selectedProduct.pricePerUnit,
            'brand': selectedProduct.sellerName,
            'category': selectedProduct.productLevel1Title,
            'variant': selectedProduct.smartElements.weight ? selectedProduct.smartElements.weight.value + selectedProduct.smartElements.weight.unit : '',
            'quantity': selectedProduct.productQuantity
        });
        this.pushToDataLayer(this.addToCartObject);
    };
    /**
     *
     * @param selectedProduct products removed from cart
     */
    GoogleAnalyticsService.prototype.updateRemoveFromCartToAnalytics = function (selectedProduct) {
        this.removeFromCartObject = {
            'event': 'removeFromCart',
            'ecommerce': {
                'remove': {
                    'products': []
                }
            }
        };
        // Removing products from shopping cart.
        try {
            this.removeFromCartObject.ecommerce.remove.products.push({
                'name': selectedProduct.productLevel3Title,
                'id': selectedProduct.productLevel3Id,
                'price': selectedProduct.pricePerUnit,
                'brand': selectedProduct.sellerName,
                'category': selectedProduct.productLevel1Title,
                'variant': selectedProduct.smartElements.weight ? selectedProduct.smartElements.weight.value + selectedProduct.smartElements.weight.unit : '',
                'quantity': selectedProduct.productQuantity
            });
        }
        catch (error) {
            console.error('Unable to send remove from cart to google analytics');
        }
        this.pushToDataLayer(this.removeFromCartObject);
    };
    /**
     *
     * @param productLevel2Data  product level2 data
     * @param selectedLevel3Product selected level3 product details
     */
    GoogleAnalyticsService.prototype.updateProductViewsToAnalytics = function (productLevel2Data, selectedLevel3Product) {
        this.productDetailsObject = {
            'event': 'productViews',
            'ecommerce': {
                'detail': {
                    'actionField': {},
                    'products': []
                }
            }
        };
        this.productDetailsObject.ecommerce.detail.actionField.list = productLevel2Data.baseCategory.value;
        this.productDetailsObject.ecommerce.detail.products.push({
            'name': selectedLevel3Product.productLevel3Title,
            'id': selectedLevel3Product.productLevel3Title,
            'price': selectedLevel3Product.pricePerUnit,
            'brand': productLevel2Data.manufacturerDetails.firstName,
            'category': productLevel2Data.baseCategory.id,
            'variant': ''
        });
        this.pushToDataLayer(this.productDetailsObject);
    };
    /**
     *
     * @param productImpression Product seen by customer
     * @param baseCategory category of product
     * @param position position
     * @param list list
     */
    GoogleAnalyticsService.prototype.updateProductImpressionOnAnalytics = function (productImpression, baseCategory, position, list) {
        var _this = this;
        this.productImpressionObject = {
            'event': 'productImpression',
            'ecommerce': {
                impressions: [],
                currencyCode: 'INR'
            }
        };
        productImpression.forEach(function (product) {
            _this.productImpressionObject.ecommerce.impressions.push({
                'name': product.productName_level3,
                'id': product.productId_level3,
                'price': product.pricePerSKU,
                'brand': product.sellerName,
                'category': baseCategory,
                'variant': '',
                'list': list,
                'position': position++
            });
        });
        this.pushToDataLayer(this.productImpressionObject);
    };
    /**
     * Prepares click event for analytics
     * @param event name of the event
     * @param userData logged in user profile data
     */
    GoogleAnalyticsService.prototype.prepareClickEventForAnalytics = function (event, userData) {
        var userDetails = 'Guest user';
        if (userData) {
            userDetails = userData.firstName + " " + (userData.mobileNo || userData.emailId || userData.profileKey);
        }
        switch (event) {
            case 'createRFQ-event':
                this.sendClickEventToAnalytics(event, 'Create RFQ', userDetails + " wants to create a RFQ");
                break;
            default:
                return;
        }
    };
    /**
     *
     * @param {string} eventAction  It is literally what the user does
     * @param {string} eventLabel Provides a bit more information about the user's action
     * @param {string} eventCategory Way to bundle user activity together.
     */
    GoogleAnalyticsService.prototype.sendClickEventToAnalytics = function (eventAction, eventLabel, eventCategory) {
        if (environment.production) {
            gtag('event', eventAction, {
                'event_label': eventLabel,
                'event_category': eventCategory,
                'non_interaction': true
            });
        }
    };
    //Get userData from local storage
    GoogleAnalyticsService.prototype.getUserData = function (event) {
        var _this = this;
        this.storage.getItem('userdata').subscribe(function (data) {
            if (data) {
                _this.loggedInUserData = data;
            }
            else {
                _this.loggedInUserData = '';
            }
            _this.prepareClickEventForAnalytics(event, _this.loggedInUserData);
        });
    };
    GoogleAnalyticsService.prototype.clickEventToAnalytics = function (event) {
        this.getUserData(event);
    };
    /**
     *
     *  @param {any} activity The activity which will push to dataLayer
     */
    GoogleAnalyticsService.prototype.pushToDataLayer = function (activity) {
        if (environment.production && location.origin.includes(environment.baseUrl)) {
            dataLayer.push(activity);
        }
    };
    GoogleAnalyticsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GoogleAnalyticsService_Factory() { return new GoogleAnalyticsService(i0.ɵɵinject(i1.LocalStorage)); }, token: GoogleAnalyticsService, providedIn: "root" });
    return GoogleAnalyticsService;
}());
export { GoogleAnalyticsService };
