import { MatSnackBar } from '@angular/material';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { ToasterService } from './toaster.service';
import { HttpWithAuthService } from './http-interceptor.service';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { EventEmitter } from 'events';
import { Wishlist } from '../models/wishlist.model';
import { TranslateService } from '@ngx-translate/core';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-pwa/local-storage";
import * as i2 from "./toaster.service";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./http-interceptor.service";
import * as i5 from "@angular/material/snack-bar";
var WishListService = /** @class */ (function () {
    function WishListService(localStorage, toaster, translator, httpWithAuth, snackbar) {
        this.localStorage = localStorage;
        this.toaster = toaster;
        this.translator = translator;
        this.httpWithAuth = httpWithAuth;
        this.snackbar = snackbar;
        this.wishlist = new Wishlist({});
        this.valueChange = new EventEmitter();
    }
    WishListService.prototype.init = function () {
        if (!JSON.parse(window.localStorage['YUdGelRHOW5aMlZrU1c0PQ=='] || 'false'))
            return;
        this.get();
    };
    /**
     * To get the user wishList
     */
    WishListService.prototype.get = function () {
        var _this = this;
        // const endPoint = `${environment.baseUrl}/rest/v1/catalog/wishlist`;
        var endPoint = environment.nodeServer + "/rest/v2/farmstore/favorite/products";
        this.httpWithAuth.get(endPoint).pipe(map(function (response) { return response; }))
            .subscribe(function (res) { return _this.wishlist = new Wishlist(JSON.parse(res.data)); });
    };
    /**
     * To get the user wishList
     */
    WishListService.prototype.getUserWishList = function () {
        // const endPoint = `${environment.baseUrl}/rest/v1/catalog/wishlist`;
        var endPoint = environment.nodeServer + "/rest/v2/bizstore/favorite/products";
        return this.httpWithAuth.get(endPoint).pipe(map(function (response) {
            var result = response;
            return result;
        }));
    };
    /**
     * To delete a product from wish list
     */
    WishListService.prototype.deleteProductFromWishList = function (productLevel3Id) {
        var _this = this;
        // const endPoint = `${environment.baseUrl}/rest/v2/bizstore/favorite/products/${productLevel3Id}`;
        var endPoint = environment.nodeServer + "/rest/v1/catalog/wishlist/" + productLevel3Id;
        return this.httpWithAuth.delete(endPoint).pipe(map(function (response) {
            var result = response;
            if (result.code === 200 || result.code === 201) {
                _this.addWishListTOIndexDb(result.data);
                _this.translator.get(["Item removed from wishlist"]).subscribe(function (translatedText) {
                    _this.snackbar.open(translatedText["Item removed from wishlist"], '', {
                        duration: 1000, panelClass: ['snakbar-color']
                    });
                });
            }
            return result;
        }));
    };
    /**
     * To add the product into user wishList
     *
     * @param productLevel2Id
     * @param productLevel3Id
     */
    WishListService.prototype.addProductToWishList = function (productLevel2Id, productLevel3Id) {
        var _this = this;
        // const endPoint = `${environment.baseUrl}/rest/v1/catalog/wishlist/${productLevel2Id}/${productLevel3Id}`;
        var endPoint = environment.nodeServer + "/rest/v2/bizstore/favorite/products/" + productLevel2Id + "/" + productLevel3Id;
        return this.httpWithAuth.put(endPoint, {}).pipe(map(function (response) {
            var result = response;
            if (result.code === 200 || result.code === 201) {
                _this.addWishListTOIndexDb(result.data);
                _this.translator.get(["Item added to wishlist"]).subscribe(function (translatedText) {
                    _this.snackbar.open(translatedText["Item added to wishlist"], '', {
                        duration: 1000, panelClass: ['snakbar-color']
                    });
                });
            }
            return result;
        }));
    };
    /**
     * To add user wish list in indexDB
     *
     */
    WishListService.prototype.addWishListTOIndexDb = function (userWishList) {
        this.localStorage.setItemSubscribe('userWishList', userWishList);
    };
    /**
     * To add the maker in favouriteMakers list
     *
     * v1/catalog/favourites/makers/M00001v0PRFREG2018110118428529UNH001
     * @param makerProfileKey
     */
    WishListService.prototype.updateFavouriteMakers = function (makerProfileKey) {
        var _this = this;
        return this.httpWithAuth.put(environment.nodeServer + "/rest/v2/bizstore/favorite/makers/" + makerProfileKey, {}).pipe(map(function (response) {
            var result = response;
            if (result.code === 200 || result.code === 202) {
                _this.addFavouriteMakersInIndexDb(result.data);
            }
            return result;
        }));
    };
    /**
     * To get the favouriteMakers
     * @param basicDetailsFlag //boolean value : true means service will return list of basicProfiledata other wise list of profileKey
     *
     */
    WishListService.prototype.getFavouriteMakers = function (basicDetailsFlag) {
        return this.httpWithAuth.get(environment.nodeServer + "/rest/v2/bizstore/favorite/makers").pipe(map(function (response) {
            var result = response;
            return result;
        }));
    };
    /**
     * To delete one maker from favouriteMakers
     * @param makerProfileKey //profileKey of maker thats needto add in maker list
     */
    WishListService.prototype.deleteFavouriteMaker = function (makerProfileKey) {
        var _this = this;
        return this.httpWithAuth.delete(environment.nodeServer + "/rest/v2/bizstore/favorite/makers/" + makerProfileKey).pipe(map(function (response) {
            var result = response;
            if (result.code === 202) {
                _this.addFavouriteMakersInIndexDb(result.data);
            }
            return result;
        }));
    };
    /**
     * To add favouriteMakers in indexDB
     *
     */
    WishListService.prototype.addFavouriteMakersInIndexDb = function (favouriteMakers) {
        // console.log(12465);
        this.localStorage.setItemSubscribe('FAVOURITE_MAKERS', favouriteMakers);
    };
    /**
     * @returns user's favourite maker list
     * Will take list from indexDB if availabe other wise call the service
     */
    WishListService.prototype.getMakerList = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.localStorage.getItem('FAVOURITE_MAKERS').subscribe(function (list) {
                // console.log('List '+ list);
                if (list == null) {
                    _this.getFavouriteMakers(false).subscribe(function (data) {
                        // console.log('data '+data);
                        if (data.code == 200) {
                            _this.addFavouriteMakersInIndexDb(data.data);
                            resolve(JSON.parse(data.data));
                        }
                        else {
                            reject(data);
                        }
                    });
                }
                else {
                    resolve(JSON.parse(list));
                }
            });
        });
    };
    /**
     * Will take logedIn user's "saver for later" product list from service
     */
    WishListService.prototype.getSavedProducts = function () {
        var _this = this;
        return this.httpWithAuth.get(environment.baseUrl + "/rest/v1/catalog/cart/saveditem").pipe(map(function (response) {
            var result = response;
            if (result.code == 200) {
                _this.setSaveProductInLocalDb(JSON.parse(result.data));
            }
            return result;
        }));
    };
    /**
     * Save product for later @put service
     */
    WishListService.prototype.saveProductForLater = function (payload) {
        return this.httpWithAuth.put(environment.baseUrl + "/rest/v1/catalog/cart/saveditem", payload).pipe(map(function (response) {
            var result = response;
            return result;
        }));
    };
    /**
     * Will store product list in indexDB
     * @param data product list in json formate
     */
    WishListService.prototype.setSaveProductInLocalDb = function (data) {
        this.localStorage.setItemSubscribe('SAVED_PRODUCTS', (data));
    };
    /**
     * Will remove saved product list from inedxDb
     */
    WishListService.prototype.removeSavedProductsFromLocal = function () {
        this.localStorage.removeItem('SAVED_PRODUCTS');
    };
    /**
     * will @returns save product list from indexDB
     */
    WishListService.prototype.getSavedProductsFromLocalDb = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.localStorage.getItem('SAVED_PRODUCTS').subscribe(function (savedProduct) {
                if (savedProduct != null) {
                    resolve((savedProduct));
                }
                else {
                    var product = {
                        products: [],
                        productIds: []
                    };
                    resolve(product);
                }
            });
        });
    };
    WishListService.prototype.dispatchUpdate = function (flag) {
        var _this = this;
        if (flag === void 0) { flag = 'true'; }
        setTimeout(function () {
            _this.valueChange.emit(flag);
        }, 300);
    };
    /**
     * Will remove the product from save list if available
     * @param product
     */
    WishListService.prototype.removeProductFromSavedList = function (product) {
        var _this = this;
        this.getSavedProductsFromLocalDb().then(function (products) {
            for (var i in products.productIds) {
                if (products.productIds[i] === product.productLevel3Id) {
                    products.products.splice(i, 1);
                    products.productIds.splice(i, 1);
                    _this.saveProductForLater(products).subscribe(function (data) { });
                    _this.setSaveProductInLocalDb(products);
                    _this.dispatchUpdate('true');
                    break;
                }
            }
        });
    };
    /**
     * Will add the product into save list if not available
     * @param product
     */
    WishListService.prototype.addProductIntoSavedList = function (product) {
        var _this = this;
        this.getSavedProductsFromLocalDb().then(function (products) {
            for (var i in products.productIds) {
                if (products.productIds[i] === product.productLevel3Id) {
                    _this.translator.get(["Selected product is already available.",]).subscribe(function (translatedText) {
                        _this.snackbar.open(translatedText["Selected product is already available."], '', {
                            duration: 2000, panelClass: ['snakbar-color']
                        });
                    });
                    return;
                }
            }
            product.cartUTS = JSON.stringify(new Date());
            products.productIds.push(product.productLevel3Id);
            products.products.push(product);
            _this.saveProductForLater(products).subscribe(function (data) { });
            _this.setSaveProductInLocalDb(products);
            _this.dispatchUpdate('true');
        });
    };
    WishListService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WishListService_Factory() { return new WishListService(i0.ɵɵinject(i1.LocalStorage), i0.ɵɵinject(i2.ToasterService), i0.ɵɵinject(i3.TranslateService), i0.ɵɵinject(i4.HttpWithAuthService), i0.ɵɵinject(i5.MatSnackBar)); }, token: WishListService, providedIn: "root" });
    return WishListService;
}());
export { WishListService };
