<!-- Side nav in mobile -->
<div class="overlay" fxHide fxShow.xs @fade *ngIf="showSideNav" (click)="toggle.emit()" detectSwipe (swipe-left)="toggle.emit()"></div>
<div fxHide fxShow.xs [@side-nav]="showSideNav ? 'opened' : 'closed'" class="side-nav-sec">
    <div class="profile-sec" (click)="toggle.emit()">
        <div class="profile" fxLayoutGap="15px">
            <div class="profile-img" fxFlex="50px">
                <img [src]="(userData?.profilePicUrl | scaleImage: 'pic150') || 'assets/icons/user.png'" height="100%" width="100%">
            </div>
            <div class="detail" fxFlex fxLayout="column" fxLayoutAlign="space-around start">
                <div class="greet" translate>Hello</div>
                <div class="name">
                    <div *ngIf="userData?.firstName" [innerHTML]="userData?.firstName"></div>
                    <div *ngIf="!userData?.firstName">
                        <span class="sign-in" routerLink="/auth/login" translate>Sign In</span>
                        <span> | </span>
                        <span class="register" routerLink="/auth/signup" translate>Register</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Will show all the categories list -->
    <!-- <div class="side-title" translate>CATEGORIES</div> -->
    <!-- <div *ngFor="let category of allCategories">
        <app-mobile-cat [category]="category" (subCategory)="searchForCategoryMobile($event)"></app-mobile-cat>
    </div> -->
    <!-- End of categories list -->

    <div class="nav-links-sec">
        <div class="nav-links">
            <div class="side-title" translate>CATEGORIES</div>
            <!-- <ng-container *ngFor="let category of (categories$ | async)">
                <div class="link" (click)="toggle.emit()" [routerLink]="['/search', category?.name]" [queryParams]="{category: category?.id }" translate>{{category.name | lowercase}}</div>
            </ng-container> -->

            <div *ngFor="let category of (categories$ | async)">
                <app-mobile-cat [category]="category" (subCategory)="searchForCategoryMobile($event)"></app-mobile-cat>
            </div>
        </div>
        <div *ngIf="isUserLoggedIn">
            <div class="nav-links">
                <div class="side-title" translate>orders and reviews</div>
                <div class="link" (click)="toggle.emit()" routerLink="account/my-orders" translate>Orders</div>
                <div class="link" (click)="toggle.emit()" *ngIf="canAccessSupportPage" routerLink="/support/orders">
                    Fulfillment</div>
                <div class="link" (click)="toggle.emit()" routerLink="/account/my-reviews" translate>Reviews</div>
            </div>
            <div class="nav-links">
                <div class="side-title" translate>MY ACCOUNT</div>
                <div class="link" (click)="toggle.emit()" routerLink="account/profile" translate>My profile</div>
                <!-- <div class="link" (click)="toggle.emit()" routerLink="/account/wish-list" translate>My wishlist
                </div> -->
                <div class="link" (click)="toggle.emit()" routerLink="account/address" translate>My addresses
                </div>
                <div class="link" (click)="toggle.emit()" routerLink="account/change-password" translate>Change password
                </div>
            </div>
        </div>
        <div *ngIf="!isUserLoggedIn">
            <div class="nav-links">
                <div class="side-title" translate>MY ACCOUNT</div>
                <div class="link" (click)="toggle.emit()" routerLink="account/profile" translate>My profile</div>
                <div class="link" (click)="toggle.emit()" routerLink="account/my-orders" translate>Orders</div>
            </div>
        </div>



        <div class="logout-link" *ngIf="isUserLoggedIn" (click)="toggle.emit();logout.emit()" translate>Logout</div>

    </div>

    <div class="change-language" fxLayout>
        <div (click)="switchLanguage('te')" class="lang" [class.active]="selectedLanguage == 'te'" fxFlex> <span translate>telugu</span>
        </div>
        <div (click)="switchLanguage('en')" class="lang" [class.active]="selectedLanguage == 'en'" fxFlex> <span translate>english</span>
        </div>
    </div>

</div>


<div class="swipe-area" detectSwipe (swipe-right)="toggle.emit()" fxHide fxShow.xs></div>