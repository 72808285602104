var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SpinnerService } from '../../services/spinner.service';
import { finalize } from 'rxjs/operators';
import { UserSubscriberService } from '../../services/user-subscriber.service';
var PincodeRequestDialogComponent = /** @class */ (function () {
    function PincodeRequestDialogComponent(data, fb, spinner, subscriberService) {
        this.data = data;
        this.fb = fb;
        this.spinner = spinner;
        this.subscriberService = subscriberService;
        this.requestedSuccessfully = false;
    }
    PincodeRequestDialogComponent.prototype.ngOnInit = function () {
        var _a = this.data.pincodeAvalilable, pincodeAvalilable = _a === void 0 ? false : _a;
        if (pincodeAvalilable)
            return;
        this.pincodeRequestForm = this.fb.group({
            name: ['', [Validators.required]],
            email: ['', [Validators.email]],
            phone: ['', [
                    Validators.minLength(10),
                    Validators.maxLength(10),
                    Validators.pattern(/^\d{10}$/)
                ]]
        });
        this.prefillFields();
    };
    PincodeRequestDialogComponent.prototype.prefillFields = function () {
        if (!this.data || !this.data.userData)
            return;
        var _a = this.data.userData, _b = _a.emailId, emailId = _b === void 0 ? "" : _b, _c = _a.mobileNo, mobileNo = _c === void 0 ? "" : _c, firstName = _a.firstName, _d = _a.alternateMobileNo, alternateMobileNo = _d === void 0 ? "" : _d, _e = _a.altemailId, altemailId = _e === void 0 ? "" : _e;
        this.patchValue('name', firstName);
        if (emailId) {
            this.patchValue('email', emailId);
            if (!alternateMobileNo)
                return;
            this.patchValue('phone', alternateMobileNo);
            return;
        }
        mobileNo = +mobileNo.replace('+91', '');
        this.patchValue('phone', mobileNo);
        if (!altemailId)
            return;
        this.patchValue('email', altemailId);
    };
    PincodeRequestDialogComponent.prototype.patchValue = function (controlName, value) {
        this.pincodeRequestForm.get(controlName).patchValue(value);
    };
    Object.defineProperty(PincodeRequestDialogComponent.prototype, "isValid", {
        get: function () {
            if (!this.pincodeRequestForm || !this.pincodeRequestForm.value)
                return false;
            var mobileControl = this.pincodeRequestForm.get('phone');
            var emailControl = this.pincodeRequestForm.get('email');
            return (this.pincodeRequestForm.valid
                && (mobileControl.valid || emailControl.valid)
                && (mobileControl.value || emailControl.value));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PincodeRequestDialogComponent.prototype, "formValue", {
        get: function () {
            return this.pincodeRequestForm.getRawValue();
        },
        enumerable: true,
        configurable: true
    });
    PincodeRequestDialogComponent.prototype.submitRequest = function () {
        return __awaiter(this, void 0, void 0, function () {
            var request;
            var _this = this;
            return __generator(this, function (_a) {
                if (!this.isValid)
                    return [2 /*return*/];
                request = Object.assign(PINCODE_REQUEST, this.formValue, this.sessionInfo);
                request.pinCode = this.data.pincode;
                this.spinner.start();
                this.subscriberService.requestForNewPin(request)
                    .pipe(finalize(function () { return _this.spinner.stop(); }))
                    .subscribe(function (res) {
                    if (res.code !== 201)
                        return;
                    _this.requestedSuccessfully = true;
                });
                return [2 /*return*/];
            });
        });
    };
    Object.defineProperty(PincodeRequestDialogComponent.prototype, "sessionInfo", {
        get: function () {
            if (!this.data || !this.data.userData)
                return { sessionInfo: PINCODE_REQUEST.sessionInfo };
            var _a = this.data.userData, _b = _a.emailId, emailId = _b === void 0 ? "" : _b, _c = _a.mobileNo, mobileNo = _c === void 0 ? "" : _c, profileKey = _a.profileKey, firstName = _a.firstName;
            var sessionInfo = Object.assign(PINCODE_REQUEST.sessionInfo, { emailId: emailId, mobileNo: mobileNo, profileKey: profileKey, firstName: firstName });
            return { sessionInfo: sessionInfo };
        },
        enumerable: true,
        configurable: true
    });
    return PincodeRequestDialogComponent;
}());
export { PincodeRequestDialogComponent };
var PINCODE_REQUEST = {
    "callback": false,
    "email": "",
    "enquiryType": "apGreens Pincode",
    "name": "",
    "phone": "",
    "pinCode": "",
    "products": [],
    "page": "",
    "countryCodeInfo": null,
    "sessionInfo": {
        "firstName": "",
        "profileKey": "",
        "loginId": "",
        "mobileNo": "",
        "emailId": ""
    }
};
