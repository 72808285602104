import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { SpinnerService } from './project-common/services/spinner.service';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./project-common/services/spinner.service";
var StoreErrorHandlerService = /** @class */ (function () {
    function StoreErrorHandlerService(router, spinner) {
        this.router = router;
        this.spinner = spinner;
    }
    StoreErrorHandlerService.prototype.handleError = function (error) {
        this.spinner.changeStatus(false, false);
        var message = '';
        var isFatal = false;
        if (error instanceof HttpErrorResponse) {
            message = 'There was an HTTP error.\n' + error.message + 'Status code:' + error.status;
        }
        else if (error instanceof TypeError) {
            message = 'There was a Type error.\n' + error.message;
            isFatal = true;
        }
        else if (error instanceof Error) {
            message = 'There was a general error.\n' + error.message;
        }
        else {
            message = 'Nobody threw an error but something happened!\n' + error;
        }
        if (environment.production && location.origin.includes(environment.baseUrl)) {
            window.gtag('event', 'exception', {
                'description': this.router.url + ': ' + (error.message || error),
                'exFatal': isFatal
            });
        }
        console.error(error);
    };
    StoreErrorHandlerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StoreErrorHandlerService_Factory() { return new StoreErrorHandlerService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.SpinnerService)); }, token: StoreErrorHandlerService, providedIn: "root" });
    return StoreErrorHandlerService;
}());
export { StoreErrorHandlerService };
