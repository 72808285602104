import {
   Component, ViewChild,
  ViewContainerRef, OnInit
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { combineLatest } from 'rxjs';
import { S3UploadService } from '../../services/s3-upload.service';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { StorefrontService } from '../../../storefront/storefront.service';


@Component({
  selector: 'footer-help',
  templateUrl: './footer-help.component.html',
  styleUrls: ['./footer-help.component.scss']
})
export class FooterHelpComponent implements OnInit {
  @ViewChild('dynamicTemplate', { static: false, read: ViewContainerRef}) dynamicTemplate;

  helperText: string;
  url: string;
  helperHtml: string;
  header: any;
  subheader: any;

  constructor(private router: Router, private route: ActivatedRoute, private s3: S3UploadService,
      private title: Title, private gaTrack: GoogleAnalyticsService, private store: StorefrontService) {

  }

  ngOnInit() {
    combineLatest([
      this.route.paramMap
    ]).subscribe(params =>{
      this.helperText = params[0].get('helpertext') || '';
      this.header = this.helperText.split('_');
      if (this.header.length) {
        this.subheader = this.header[1].split('-').join(' ');
        this.header = this.header[0];
      }
      this.title.setTitle(this.subheader || 'Kalgudi help');
      /* console.log('subheader'+this.subheader); */
      /* console.log('header'+this.header); */
      const url = `${environment.baseUrl}/data/footerlinks/`+this.helperText+`.html`
      const request = this.getFileName ();
      if(this.helperText === 'Policies_Privacy' || this.helperText === 'Policies_Service-terms' || this.helperText === 'Shop_Returns') {
        this.store.getPrivacyTerm(request).subscribe(data => {
          // console.log(data);
          this.helperHtml = String(data);
        })
      }else {
        this.s3.getHtmlFromS3(url).subscribe(data =>{
          this.helperHtml = String(data);
        }, error => {
          this.helperHtml = "Sorry data not available";
        });
      }
    });
    
    this.gaTrack.configPageViewToAnalytics('FooterLinks',  this.helperText.replace('_', ' -> '));
      
  }

  getFileName () {
    // if (this.helperText === 'Kalgudi_About-us') {
    //   return `footer-help.kalgudi.about-us.html`;

    // } else if ( this.helperText === 'Kalgudi_Team')  {
    //   return `footer-help-team.html`;

    // }  else if ( this.helperText === 'Kalgudi_Insights')  {
    //   return `footer-help.kalgudi.insights.html`;

    // }
    if ( this.helperText === 'Policies_Privacy' ) {
      return `footer-help.policies.privacy.html`;

    } else if (this.helperText === 'Policies_Service-terms')  {
      return `footer-help.policies.service-terms.html`;
    } else if (this.helperText === 'Shop_Returns')  {
      return `footer-help.shop.returns.html`;
    }
    // else if (this.helperText === 'Shop_Service-terms')  {
    //   return `footer-help.shop.services-terms.html`;

    // } else if (this.helperText === 'Shop_Pricing')  {
    //   return `footer-help.shop.pricing.html`;

    // } else if (this.helperText === 'Shop_Returns')  {
    //   return `footer-help.shop.returns.html`;

    // } else if (this.helperText === 'Shop_Settlements')  {
    //   return `footer-help.shop.settlements.html`;

    // } else if(this.helperText === 'Kalgudi_iAgrimarC') {
    //   return `footer-help-iAgrimarC.html`;

    // } else if(this.helperText === 'Farmers_Kalgudi-for-producers') {
    //   return `footer-help-producers.html`;

    // } else if(this.helperText === 'Farmers_Knowledge') {
    //   return `footer-help-knowledge.html`;

    // } else if(this.helperText === 'Farmers_QA') {
    //   return `footer-help-QA.html`;

    // } else if(this.helperText === 'Farmers_Marketplace') {
    //   return `footer-help-marketplace-farmer.html`;

    // } else if(this.helperText === 'Farmers_Profiling') {
    //   return `footer-help-profiling.html`;

    // } else if(this.helperText === 'Farmers_Groups-FPO') {
    //   return `footer-help-groups.html`;

    // } else if(this.helperText === 'Agribusinesses_Kalgudi-for-agribusinesses') {
    //   return `footer-help-agribusiness.html`;

    // } else if(this.helperText === 'Agribusinesses_Marketplaces') {
    //   return `footer-help-marketplace-agribusiness.html`;

    // } else if(this.helperText === 'Agribusinesses_Transaction-duality') {
    //   return `footer-help-transaction-account.html`;

    // } else if(this.helperText === 'Agribusinesses_Traceability') {
    //   return `footer-help-traceability.html`;

    // } else if(this.helperText === 'Agribusinesses_Incontext-communications') {
    //   return `footer-help-incontext-communications.html`;

    // } else if(this.helperText === 'Agribusinesses_Trustrank') {
    //   return `footer-help-trustrank.html`;

    // } else if(this.helperText === 'Impact_Kalgudi-for-Impact') {
    //   return `footer-help-impact.html`;

    // } else if(this.helperText === 'Impact_Surveys') {
    //   return `footer-help-surveys.html`;

    // } else if(this.helperText === 'Impact_Extensions') {
    //   return `footer-help-extensions.html`;

    // } else if(this.helperText === 'Impact_Marketplace-facilitation') {
    //   return `footer-help-marketplace-facilitation.html`;

    // } else if(this.helperText === 'Impact_Analytics') {
    //   return `footer-help-analytics.html`;

    // } else if(this.helperText === 'Impact_Digital-Assistance') {
    //   return `footer-help-digital-assistance.html`;

    // } else if(this.helperText === 'Impact_Sustainability') {
    //   return `footer-help-sustainability.html`;

    // } else if(this.helperText === 'Agribusinesses_Accounts')  {
    //   return `footer-help.html`;

    // } else if(this.helperText === 'Policies_Terms-of-use') {
    //   return `footer-help-terms-of-use.html`;

    // } else if(this.helperText === 'Policies_Terms-of-Agreement')  {
    //   return `footer-help-agreement.html`;

    // }else if (this.helperText === 'Kalgudi_Contact-us')  {
    //   return `footer.html.kalgudi.contact-us.html`;

    // }else if (this.helperText === 'Kalgudi_Careers')  {
    //   return `footer-help-careers.html`;

    // }else if (this.helperText === 'Shop_Seller-center')  {
    //   return `footer-help-seller-center.html`;

    // } else {
    //   return `footer-help.html`;

    // }
  } 
}
