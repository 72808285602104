import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { NotificationService } from '../../project-common/services/notification.service';
import { ScaleImagePipe } from '../pipes/scale-image.pipe';
import { GoogleAnalyticsService } from './google-analytics.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./notification.service";
import * as i3 from "./google-analytics.service";
var UtilityService = /** @class */ (function () {
    function UtilityService(router, notification, gaTrack) {
        this.router = router;
        this.notification = notification;
        this.gaTrack = gaTrack;
    }
    /**
     * This will return an object by grouping the list objects by their passed key's value
     * Each fields returned by this method, will contain an array list having the same value
     * in given @param key.
     * @param list Set of objects
     * @param key A key available in all object of the set
     */
    UtilityService.prototype.groupBy = function (list, key) {
        return list.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };
    UtilityService.prototype.toArray = function (object) {
        return Object.keys(object).map(function (key) {
            return { 'key': key, 'list': object[key], 'displayName': object[key][0].displayName };
        });
    };
    UtilityService.prototype.sort = function (array, key) {
        array.sort(function (a, b) {
            return a[key].localeCompare(b[key]);
        });
        return array;
    };
    /**
     * To open full description page of product
     * @param product product tile object
     */
    UtilityService.prototype.openProductFullView = function (product, position) {
        var productName = this.getEncodedProductName(product.productName_level2);
        this.sendProductClickTo(product, position);
        if (this.isMobileDevice()) {
            this.openInSameTab(product, productName);
        }
        else {
            this.openInNewTab(product, productName);
        }
    };
    UtilityService.prototype.getProductPageUrl = function (product) {
        var routers = [];
        routers.push('/p');
        routers.push(product.category || 'c');
        routers.push(this.getEncodedProductName(product.productName_level3 || product.productName_level2));
        routers.push((product.level2ShortId || product.productId_level2) + "_" + (product.level3ShortId || product.productId_level3));
        return routers;
    };
    UtilityService.prototype.getFullViewLink = function (product, position) {
        // this.sendProductClickTo(product, position);
        var productName = this.getEncodedProductName(product.productName_level3);
        // const pathname = this.getPathName(window.location.pathname);
        var category = this.getEncodedProductName(product.category || 'c');
        var url = this.getPathName() + "p/" + category.toLocaleLowerCase() + "/" + productName.toLocaleLowerCase() + "/" + product.productId_level2 + "_" + product.productId_level3;
        return url;
    };
    UtilityService.prototype.getProductFullViewRoutingPath = function (product) {
        return [
            '/p',
            this.getEncodedProductName(product.category || 'c'),
            this.getEncodedProductName(product.productName_level3),
            product.productId_level2 + '_' + product.productId_level3
        ];
    };
    UtilityService.prototype.sendProductClickTo = function (product, position) {
        if (position === void 0) { position = 0; }
        var listType = this.router.url === '/home' ? 'Home page' : 'Search Results';
        try {
            this.gaTrack.updateProductClickToAnalytics(product, listType, position);
        }
        catch (e) {
            console.error("Failed to submit details for analytics");
        }
    };
    UtilityService.prototype.isMobileDevice = function () {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    };
    UtilityService.prototype.openInSameTab = function (product, productName) {
        if (!product.productId_level3) {
            this.router.navigate([
                'c', productName,
                'cid', product.productId_level2
            ]);
            return;
        }
        this.router.navigate([
            'c', productName,
            'cid', product.productId_level2,
            'csub', product.productId_level3
        ]);
    };
    UtilityService.prototype.openInNewTab = function (product, productName) {
        var pathname = this.getPathName(window.location.pathname);
        var url = window.location.origin + pathname + 'c/' + productName +
            '/cid/' + product.productId_level2;
        if (product.productId_level3) {
            url += '/csub/' + product.productId_level3;
        }
        window.open(url, '_blank');
    };
    UtilityService.prototype.getPathName = function (path) {
        var pathName = path || window.location.pathname;
        return pathName.includes('trade') ? '/trade/' : '/';
    };
    UtilityService.prototype.getEncodedProductName = function (input) {
        try {
            input = input.replace(/[\(\)\&-\/\\\%\s]/g, '-').replace(/ /g, '-') // Replaces special characters + spaces with -
                .replace(/[-]{1,}/g, '-'); // Replaces more than one - with single -
            return input.substr(0, (input.lastIndexOf('-') === input.length - 1 ? input.length - 1 : input.length))
                .toLowerCase(); // Convert the uri to lowercase
        }
        catch (e) {
            return "-";
        }
        // return text.replace(/[&\/\\#,+()$~%.'":*?<>{} ]/g, '-').toLowerCase();
    };
    UtilityService.prototype.getImageDimension = function (image, index) {
        if (index === void 0) { index = '0'; }
        return new Observable(function (observer) {
            var img = new Image();
            var scaleImage = new ScaleImagePipe();
            img.onload = function (event) {
                var loadedImage = event.currentTarget;
                image.width = loadedImage.width;
                image.height = loadedImage.height;
                image.index = index;
                observer.next(image);
                observer.complete();
            };
            img.src = scaleImage.transform(image.url, 'sm');
        });
    };
    /**
    * To get mobile number of user
    * @param UserProfileData user profile object
    */
    UtilityService.prototype.getUserMobileNumber = function (user) {
        var mobile = '';
        if (!user.mobileNo.includes(user.profileKey.substring(1, 7))) {
            mobile = user.mobileNo.replace(user.mobileCode, '');
        }
        else if (user.alternateMobileNo) {
            mobile = user.alternateMobileNo;
        }
        return mobile;
    };
    /**
     * To accept numbers only
     * @param event
     */
    UtilityService.prototype.numberOnly = function (event) {
        var charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    };
    UtilityService.prototype.checkOfferValidity = function (data) {
        var offer = data;
        if (!data) {
            offer = this.getStoreMasterData();
            offer = offer.offer;
        }
        if (!offer) {
            return false;
        }
        if (!offer.offerEnds || !offer.offerStarts) {
            return offer.isOfferValid;
        }
        var offerStarts = new Date(offer.offerStarts);
        var today = new Date();
        var offerEnds = new Date(offer.offerEnds);
        return (today >= offerStarts && today <= offerEnds) ? true : false;
    };
    UtilityService.prototype.getStoreMasterData = function () {
        if (localStorage['storeMasterData']) {
            return JSON.parse(localStorage['storeMasterData']);
        }
        else {
            return {};
        }
    };
    /**
     * To switch the language
     * @param languageCode language code example : `en`
     */
    UtilityService.prototype.switchLanguage = function (languageCode) {
        this.notification.notifyForLanguageChange(languageCode);
    };
    UtilityService.prototype.downloadAndShowFileOnApk = function (filePath, fileName) {
        window.requestFileSystem(LocalFileSystem.PERSISTENT, 0, function (fileSystem) {
            var directoryEntry = fileSystem.root; // to get root path of directory
            directoryEntry.getDirectory("Download", {
                create: true, exclusive: false
            }, function (parent) {
                console.log('directory created ', parent);
            }, function (failed) {
                console.log('Failed to create directory ', failed);
            });
            var fp = window.cordova.file.externalRootDirectory; // Returns Fullpath of local directory
            fp = fp + "Download" + "/" + fileName; // fullpath and name of the file which we want to give
            var fileTransfer = new FileTransfer();
            fileTransfer.download(filePath, fp, function (entry) {
                window.open(entry.toURL(), '_system', 'location=yes');
            }, function (error) {
                console.log(error);
            });
        }, function (event) {
            console.log(event);
        });
    };
    UtilityService.prototype.getEmailId = function (user) {
        var email = '';
        if (user.emailId) {
            return user.emailId;
        }
        else if (user.alternateEmailId) {
            email = user.alternateEmailId;
        }
        return email;
    };
    UtilityService.prototype.goToAttachSeller = function (requirement) {
        var VProductId = requirement.product.VProductId;
        localStorage['rfqBaseProductId'] = VProductId;
        var priceRange = requirement.priceRange;
        var minimum = priceRange.minimum, maximum = priceRange.maximum;
        if ((minimum > 0) || (maximum > 0)) {
            this.router.navigate(['/dashboard', 'attach-seller', requirement.requirementId], {
                queryParams: {
                    minimum: minimum,
                    maximum: maximum
                }
            });
        }
        else {
            this.router.navigate(['/dashboard', 'attach-seller', requirement.requirementId]);
        }
    };
    UtilityService.prototype.prepareShortL3Object = function (l3) {
        return {
            "LUT": l3.LUT,
            "mrp": l3.mrp,
            "createdTS": l3.createdTS,
            "languageId": l3.languageId,
            "sellerType": l3.sellerType,
            "isBulkOrder": l3.isBulkOrder,
            "isForReview": l3.isForReview,
            "isPublished": l3.isPublished,
            "productType": l3.productType,
            "selectedSKU": l3.selectedSKU,
            "languageName": l3.languageName,
            "pricePerUnit": l3.pricePerUnit,
            "smartElements": l3.smartElements,
            "masterLevel1Id": l3.masterLevel1Id,
            "masterLevel2Id": l3.masterLevel2Id,
            "masterLevel3Id": l3.masterLevel3Id,
            "productLevel1Id": l3.productLevel1Id,
            "productLevel2Id": l3.productLevel2Id,
            "productLevel3Id": l3.productLevel3Id,
            "productLevel1Title": l3.productLevel1Title,
            "productLevel2Title": l3.productLevel2Title,
            "productLevel3Title": l3.productLevel3Title,
            "freeShippingEligible": l3.freeShippingEligible,
            "productLevel3ShortTitle": l3.productLevel3ShortTitle,
            "baseProduct": l3.baseProduct,
            "defaultImage": l3.defaultImage,
            "attachments": l3.attachments,
            "manufacturerDetails": l3.manufacturerDetails
        };
    };
    UtilityService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UtilityService_Factory() { return new UtilityService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.NotificationService), i0.ɵɵinject(i3.GoogleAnalyticsService)); }, token: UtilityService, providedIn: "root" });
    return UtilityService;
}());
export { UtilityService };
