/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./footer-help.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "@angular/cdk/bidi";
import * as i5 from "./footer-help.component";
import * as i6 from "@angular/router";
import * as i7 from "../../services/s3-upload.service";
import * as i8 from "@angular/platform-browser";
import * as i9 from "../../services/google-analytics.service";
import * as i10 from "../../../storefront/storefront.service";
var styles_FooterHelpComponent = [i0.styles];
var RenderType_FooterHelpComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterHelpComponent, data: {} });
export { RenderType_FooterHelpComponent as RenderType_FooterHelpComponent };
export function View_FooterHelpComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { dynamicTemplate: 0 }), (_l()(), i1.ɵeld(1, 16777216, [[1, 3], ["dynamicTemplate", 1]], null, 0, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "help-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "app-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "footer-link-template"], ["fxLayout", "column"], ["fxLayoutGap", "35px"], ["fxLayoutGap.xs", "15px"]], null, null, null, null, null)), i1.ɵdid(5, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(6, 1720320, null, 0, i2.DefaultLayoutGapDirective, [i1.ElementRef, i1.NgZone, i4.Directionality, i3.StyleUtils, [2, i2.LayoutGapStyleBuilder], i3.MediaMarshaller], { fxLayoutGap: [0, "fxLayoutGap"], "fxLayoutGap.xs": [1, "fxLayoutGap.xs"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 0, "h3", [["class", "title"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null))], function (_ck, _v) { var currVal_0 = "column"; _ck(_v, 5, 0, currVal_0); var currVal_1 = "35px"; var currVal_2 = "15px"; _ck(_v, 6, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.subheader; _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.helperHtml; _ck(_v, 8, 0, currVal_4); }); }
export function View_FooterHelpComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "footer-help", [], null, null, null, View_FooterHelpComponent_0, RenderType_FooterHelpComponent)), i1.ɵdid(1, 114688, null, 0, i5.FooterHelpComponent, [i6.Router, i6.ActivatedRoute, i7.S3UploadService, i8.Title, i9.GoogleAnalyticsService, i10.StorefrontService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FooterHelpComponentNgFactory = i1.ɵccf("footer-help", i5.FooterHelpComponent, View_FooterHelpComponent_Host_0, {}, {}, []);
export { FooterHelpComponentNgFactory as FooterHelpComponentNgFactory };
