import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var NotificationService = /** @class */ (function () {
    function NotificationService() {
        this.userDataChangedSubject = new Subject();
        this.userDataChanges = this.userDataChangedSubject.asObservable();
        this.languageSubject = new Subject();
        this.languageChanges = this.languageSubject.asObservable();
        this.bulkOrderRequest = new Subject();
        this.notificationActive = new Subject();
        this.liveChat = new Subject();
        this.isNewMsg = new Subject();
        this.isNewNotification = new Subject();
    }
    NotificationService.prototype.notifyForUserDataChange = function () {
        this.userDataChangedSubject.next();
    };
    NotificationService.prototype.notifyForLanguageChange = function (language) {
        localStorage['lang'] = language;
        this.languageSubject.next(language);
        var bodyElement = document.getElementById('app-body');
        (language === 'en')
            ? bodyElement.classList.remove('other-language')
            : bodyElement.classList.add('other-language');
    };
    NotificationService.prototype.sendBulkOrderProducts = function (productList) {
        this.bulkOrderRequest.next(productList);
    };
    NotificationService.prototype.getBulkOrderProducts = function () {
        return this.bulkOrderRequest.asObservable();
    };
    NotificationService.prototype.sendNotification = function (title, message, icon, deepLinkUrl, color, type) {
        this.notificationActive.next({
            title: title,
            message: message,
            icon: icon,
            deepLinkUrl: deepLinkUrl,
            color: color,
            type: type
        });
    };
    NotificationService.prototype.liveChatNotification = function (isLiveChat) {
        this.liveChat.next(isLiveChat);
    };
    NotificationService.prototype.handleNewNotifications = function (newNotification) {
        this.isNewNotification.next(newNotification);
    };
    NotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(); }, token: NotificationService, providedIn: "root" });
    return NotificationService;
}());
export { NotificationService };
