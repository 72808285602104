var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { LocalStorage } from '@ngx-pwa/local-storage';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Storefront } from '../properties/constants';
import { SubscribeUsPayload } from '../models/subscriber-payload';
import { UtilityService } from './utility.service';
import { Router } from '@angular/router';
import { NotificationService } from './notification.service';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-pwa/local-storage";
import * as i2 from "@angular/common/http";
import * as i3 from "./utility.service";
import * as i4 from "@angular/router";
import * as i5 from "./notification.service";
var UserSubscriberService = /** @class */ (function () {
    function UserSubscriberService(storage, http, util, router, notification) {
        var _this = this;
        this.storage = storage;
        this.http = http;
        this.util = util;
        this.router = router;
        this.notification = notification;
        this.storage.getItem('countryDetails').subscribe(function (countryDetails) { return _this.countryDetails = countryDetails; });
        this.getUserData();
        this.notification.userDataChanges.subscribe(function (response) {
            _this.getUserData();
        });
    }
    UserSubscriberService.prototype.subscribeToNewsLetters = function (emailId) {
        var payload = {
            countryCodeInfo: this.countryDetails,
            page: this.router.url,
            email: emailId,
            sessionInfo: this.sessionInfo
        };
        return this.subscribeUsApi(new SubscribeUsPayload("apGreen subscription", payload));
    };
    UserSubscriberService.prototype.subscribeToGetAppLink = function (phone) {
        var payload = {
            page: this.router.url,
            phone: phone,
            countryCodeInfo: this.countryDetails,
            sessionInfo: this.sessionInfo
        };
        return this.subscribeUsApi(new SubscribeUsPayload("apGreen subscription", payload));
    };
    UserSubscriberService.prototype.requestForNewPin = function (payload) {
        payload = __assign({}, payload, { countryCodeInfo: this.countryDetails, page: this.router.url, sessionInfo: this.sessionInfo });
        return this.subscribeUsApi(new SubscribeUsPayload('apGreens Pincode', payload));
    };
    UserSubscriberService.prototype.subscribeUsApi = function (request) {
        var api = environment.nodeServer + "/v2/bizstore/profiles/" + Storefront.APP_NAME + "/signup-request";
        return this.http.post(api, request);
    };
    UserSubscriberService.prototype.getUserData = function () {
        var _this = this;
        this.storage.getItem('userdata').subscribe(function (user) {
            if (user) {
                _this.sessionInfo = {
                    firstName: user.firstName,
                    profileKey: user.profileKey,
                    emailId: _this.util.getEmailId(user),
                    mobileNo: _this.util.getUserMobileNumber(user),
                    loginId: user.mobileNo
                };
            }
        });
    };
    UserSubscriberService.prototype.subscribeForProduct = function (request) {
        var payload = {
            page: this.router.url,
            enquiryType: 'Product subscription',
            subscriptionFrequency: '',
            productId: request.productLevel2Id,
            productName: request.productLevel2Title,
            email: request.email,
            name: request.name,
            countryCodeInfo: this.countryDetails,
            sessionInfo: this.sessionInfo
        };
        return this.subscribeUsApi(new SubscribeUsPayload('product subscription', payload));
    };
    UserSubscriberService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserSubscriberService_Factory() { return new UserSubscriberService(i0.ɵɵinject(i1.LocalStorage), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.UtilityService), i0.ɵɵinject(i4.Router), i0.ɵɵinject(i5.NotificationService)); }, token: UserSubscriberService, providedIn: "root" });
    return UserSubscriberService;
}());
export { UserSubscriberService };
