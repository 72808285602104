import { ViewportScroller } from '@angular/common';
import { ErrorHandler, Injector } from '@angular/core';
import { Router, Scroll } from '@angular/router';
import { HttpLoaderFactory } from './project-common/factories/translator.factory';
import { StoreErrorHandlerService } from './store-error-handler.service';
/* To handle exceptions globally  */
var StoreErrorHandlers = /** @class */ (function () {
    function StoreErrorHandlers(injector) {
        this.injector = injector;
    }
    StoreErrorHandlers.prototype.handleError = function (error) {
        var errorHandler = this.injector.get(StoreErrorHandlerService);
        errorHandler.handleError(error);
    };
    return StoreErrorHandlers;
}());
export { StoreErrorHandlers };
var ɵ0 = HttpLoaderFactory;
var AppModule = /** @class */ (function () {
    function AppModule(router, viewportScroller) {
        router.events.subscribe(function (e) {
            if (!(e instanceof Scroll))
                return;
            if (e.position) {
                // backward navigation
                setTimeout(function () {
                    viewportScroller.scrollToPosition(e.position);
                }, 50);
            }
            else if (e.anchor) {
                // anchor navigation
                viewportScroller.scrollToAnchor(e.anchor);
            }
            else {
                // forward navigation
                viewportScroller.scrollToPosition([0, 0]);
            }
        });
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
