import { environment } from 'src/environments/environment';
import { StoreType } from '@kalgudi/types';
import * as i0 from "@angular/core";
var CoreEnvironmentConfigService = /** @class */ (function () {
    function CoreEnvironmentConfigService() {
        this.production = environment.production;
        this.awsAccessKey = environment.awsAccessKey;
        this.s3BucketUrl = environment.bucketUrl;
        this.domain = environment.baseUrl;
        this.consumerStoreDomain = environment.baseUrl;
        this.bizStoreDomain = environment.bizStoreDomain;
        this.farmerStoreDomain = environment.farmerStoreDomain;
        this.restBaseUrl = environment.restBaseUrl;
        this.restBaseUrlV2 = environment.restBaseUrlV2;
        this.storeType = StoreType.FARM_STORE;
        this.coreDomain = environment.coreDomain;
        this.dirUrl = "";
    }
    CoreEnvironmentConfigService.prototype.getStoreType = function () {
        return StoreType.FARM_STORE;
    };
    CoreEnvironmentConfigService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CoreEnvironmentConfigService_Factory() { return new CoreEnvironmentConfigService(); }, token: CoreEnvironmentConfigService, providedIn: "root" });
    return CoreEnvironmentConfigService;
}());
export { CoreEnvironmentConfigService };
