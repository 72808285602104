import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AnalyticsProductCheckout, 
  AnalyticsPurchaseProduct, 
  AnalyticsProductCheckoutOption, 
  AnalyticsProductClick, 
  AnalyticsAddToCart, 
  AnalyticsRemoveFromCart, 
  AnalyticsProductImpressions, 
  AnalyticsProductDetails 
} from '../models/google-analytics.model';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { Cart } from '../models/cart-product.model';

declare var gtag: any;
declare var dataLayer: any;
@Injectable({
  providedIn: 'root'
})

export class GoogleAnalyticsService {

  productCheckout: AnalyticsProductCheckout;
  purchaseObject: AnalyticsPurchaseProduct;
  productCheckoutOption: AnalyticsProductCheckoutOption;
  productClickObject: AnalyticsProductClick;
  addToCartObject: AnalyticsAddToCart;
  removeFromCartObject: AnalyticsRemoveFromCart;
  productImpressionObject: AnalyticsProductImpressions;
  productDetailsObject: AnalyticsProductDetails;
  loggedInUserData: any;

  constructor(private storage: LocalStorage) { }

  /**
  * To track current page to google analytics 
  * 
  * @param {string} pagePath Path of the page which user views
  * @param {string} pageTitle Title of the page
  */
  public configPageViewToAnalytics(pagePath: string, pageTitle: string) {
    if (environment.production) {
      gtag("config", "UA-166326147-1", {

        page_path: pagePath,
        page_title: pageTitle

      });

      /* gtag('event', 'page_view', {
        'send_to': 'AW-936859343',
        'user_id': localStorage['dXNlcm5hbWU='] ? atob(localStorage['dXNlcm5hbWU=']) : 'Guest_user'
      }); */
    }
  }


  public updateCheckoutStepOnAnalytics(cart: Cart, step: number) {
    this.productCheckout = {
      'event': 'checkout',
      'ecommerce': {
        'checkout': {
          'actionField': {},
          'products': []
        }
      }
    }
    this.productCheckout.ecommerce.checkout.actionField.step = step;
    cart.products.forEach(product => {
      this.productCheckout.ecommerce.checkout.products.push({
        'name': product.productLevel3Title,
        'id': product.productLevel3Id,
        'price': product.pricePerUnit,
        'brand': product.sellerName,
        'category': product.productLevel1Title,
        'variant': '',
        'quantity': product.productQuantity
      });
      this.pushToDataLayer(this.productCheckout);
    });
  }

  /**
   * 
   * @param orderDetails of buyer
   */
  public updateOrderSuccessInfoOnAnalytics(orderDetails) {
    orderDetails.forEach(order => {
      this.purchaseObject = {
        'event': 'purchase',
        'ecommerce': {
          'purchase': {
            'actionField': {
              'id': order.orderId,                         // Transaction ID. Required for purchases and refunds.
              'affiliation': 'Andhragreens',
              'revenue': order.totalAmount,                     // Total transaction value (incl. tax and shipping)
              'tax': 0,
              'shipping': order.fulfillmentDeatils.TRANSPORT.commission,
              'coupon': ''
            },
            'products': order.productList.map(product => {
              const variant = product.smartElements[product.selectedSKU[0]];
              return {
                'name': product.productLevel3Title,     // Name or ID is required.
                'id': product.productLevel3Id,
                'price': product.pricePerUnit,
                'brand': product.sellerName,
                'category': product.productCategory,
                'variant': `${variant.value} ${variant.unit}`,
                'quantity': product.productQuantity,
                'coupon': ''
              }
            })
          }
        }
      };
      this.pushToDataLayer(this.purchaseObject);
    });
  }

  /**
   * 
   * @param {number} step position of checkout process
   * @param option activity occurs in that step
   */
  public updateCheckoutOptionOnAnalytics(step, option) {
    this.productCheckoutOption = {
      'event': 'checkoutOption',
      'ecommerce': {
        'checkout_option': {
          'actionField': {}
        }
      }
    }
    this.productCheckoutOption.ecommerce.checkout_option.actionField.step = step;
    this.productCheckoutOption.ecommerce.checkout_option.actionField.option = option;
    this.pushToDataLayer(this.productCheckoutOption);
  }
  /**
   * 
   * @param clickedProduct Product clicked by user
   * @param listType product is clicked on search page or home page
   * @param position position of product
   */
  public updateProductClickToAnalytics(clickedProduct, listType, position) {
    this.productClickObject = {
      'event': 'productClick',
      'ecommerce': {
        'click': {
          'actionField': {},      // Optional list property.
          'products': []
        }
      }
    }
    this.productClickObject.ecommerce.click.actionField.list = listType;
    this.productClickObject.ecommerce.click.products.push({
      'name': clickedProduct.productName_level3,                      // Name or ID is required.
      'id': clickedProduct.productId_level3,
      'price': clickedProduct.pricePerSKU,
      'brand': clickedProduct.sellerName,
      'category': clickedProduct.category,
      'variant': clickedProduct.SKUSmartElements[clickedProduct.selectedSKU[0]].value + ' ' + clickedProduct.SKUSmartElements[clickedProduct.selectedSKU[0]].unit,
      'position': position + 1
    })
    this.pushToDataLayer(this.productClickObject);
  }

  /**
   * 
   * @param selectedProduct products added to cart 
   */
  public updateAddToCartToAnalytics(selectedProduct) {
    this.addToCartObject = {
      'event': 'addToCart',
      'ecommerce': {
        'currencyCode': 'INR',
        'add': {                                // 'add' actionFieldObject measures.
          'products': []
        }
      }
    }
    //  adding  products to shopping cart.
    this.addToCartObject.ecommerce.add.products.push({
      'name': selectedProduct.productLevel3Title,
      'id': selectedProduct.productLevel3Id,
      'price': selectedProduct.pricePerUnit,
      'brand': selectedProduct.sellerName,
      'category': selectedProduct.productLevel1Title,
      'variant': selectedProduct.smartElements.weight ? selectedProduct.smartElements.weight.value + selectedProduct.smartElements.weight.unit : '',
      'quantity': selectedProduct.productQuantity
    })
    this.pushToDataLayer(this.addToCartObject);
  }

  /**
   * 
   * @param selectedProduct products removed from cart
   */
  public updateRemoveFromCartToAnalytics(selectedProduct) {
    this.removeFromCartObject = {
      'event': 'removeFromCart',
      'ecommerce': {
        'remove': {                                // 'remove' actionFieldObject measures.
          'products': []
        }
      }
    }
    // Removing products from shopping cart.
    try {
      this.removeFromCartObject.ecommerce.remove.products.push({
        'name': selectedProduct.productLevel3Title,
        'id': selectedProduct.productLevel3Id,
        'price': selectedProduct.pricePerUnit,
        'brand': selectedProduct.sellerName,
        'category': selectedProduct.productLevel1Title,
        'variant': selectedProduct.smartElements.weight ? selectedProduct.smartElements.weight.value + selectedProduct.smartElements.weight.unit : '',
        'quantity': selectedProduct.productQuantity
      });
    } catch (error) {
      console.error('Unable to send remove from cart to google analytics');
    }
    this.pushToDataLayer(this.removeFromCartObject);
  }

  /**
   * 
   * @param productLevel2Data  product level2 data
   * @param selectedLevel3Product selected level3 product details
   */
  public updateProductViewsToAnalytics(productLevel2Data, selectedLevel3Product) {
    this.productDetailsObject = {
      'event': 'productViews',
      'ecommerce': {
        'detail': {
          'actionField': {},    // 'detail' actions have an optional list property.
          'products': []
        }
      }
    }
    this.productDetailsObject.ecommerce.detail.actionField.list = productLevel2Data.baseCategory.value;
    this.productDetailsObject.ecommerce.detail.products.push({
      'name': selectedLevel3Product.productLevel3Title,         // Name or ID is required.
      'id': selectedLevel3Product.productLevel3Title,
      'price': selectedLevel3Product.pricePerUnit,
      'brand': productLevel2Data.manufacturerDetails.firstName,
      'category': productLevel2Data.baseCategory.id,
      'variant': ''
    });
    this.pushToDataLayer(this.productDetailsObject);
  }

  /**
   * 
   * @param productImpression Product seen by customer
   * @param baseCategory category of product
   * @param position position 
   * @param list list
   */
  public updateProductImpressionOnAnalytics(productImpression, baseCategory, position, list) {
    this.productImpressionObject = {
      'event': 'productImpression',
      'ecommerce': {
        impressions: [],
        currencyCode: 'INR'
      }
    }
    productImpression.forEach(product => {
      this.productImpressionObject.ecommerce.impressions.push({
        'name': product.productName_level3,       // Name or ID is required.
        'id': product.productId_level3,
        'price': product.pricePerSKU,
        'brand': product.sellerName,
        'category': baseCategory,
        'variant': '',
        'list': list,
        'position': position++
      })
    });
    this.pushToDataLayer(this.productImpressionObject);
  }

  /**
   * Prepares click event for analytics
   * @param event name of the event
   * @param userData logged in user profile data
   */
  private prepareClickEventForAnalytics(event, userData) {
    let userDetails = 'Guest user';
    if (userData) {
      userDetails = `${userData.firstName} ${userData.mobileNo || userData.emailId || userData.profileKey}`;
    }
    switch (event) {
      case 'createRFQ-event':
        this.sendClickEventToAnalytics(event, 'Create RFQ', `${userDetails} wants to create a RFQ`)
        break;

      default:
        return;
    }
  }
  /**
   * 
   * @param {string} eventAction  It is literally what the user does
   * @param {string} eventLabel Provides a bit more information about the user's action
   * @param {string} eventCategory Way to bundle user activity together.
   */
  private sendClickEventToAnalytics(eventAction, eventLabel, eventCategory) {
    if (environment.production) {
      gtag('event', eventAction, {
        'event_label': eventLabel,
        'event_category': eventCategory,
        'non_interaction': true
      });
    }
  }

  //Get userData from local storage
  private getUserData(event) {
    this.storage.getItem('userdata').subscribe(data => {
      if (data) {
        this.loggedInUserData = data;
      }
      else {
        this.loggedInUserData = '';
      }
      this.prepareClickEventForAnalytics(event, this.loggedInUserData);
    });
  }

  public clickEventToAnalytics(event) {
    this.getUserData(event);
  }

  /**
   * 
   *  @param {any} activity The activity which will push to dataLayer
   */
  public pushToDataLayer(activity: any) {
    if (environment.production && location.origin.includes(environment.baseUrl)) {
      dataLayer.push(activity);
    }
  }

}
