var Wishlist = /** @class */ (function () {
    function Wishlist(wishlistItems) {
        this.wishlistItems = wishlistItems;
    }
    Wishlist.prototype.isAvailable = function (id) {
        if (!this.wishlistItems)
            return false;
        return this.wishlistItems[id] ? true : false;
    };
    Object.defineProperty(Wishlist.prototype, "itemCount", {
        get: function () {
            return this.items ? this.items.length : 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Wishlist.prototype, "items", {
        get: function () {
            return Object.values(this.wishlistItems);
        },
        enumerable: true,
        configurable: true
    });
    return Wishlist;
}());
export { Wishlist };
