import { Attachment, AttachmentType } from 'src/app/project-common/models/attachment.model';
import { S3UploadService } from 'src/app/project-common/services/s3-upload.service';
import { SpinnerService } from 'src/app/project-common/services/spinner.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'attachments-dialog',
  templateUrl: './attachments-dialog.component.html',
  styleUrls: ['./attachments-dialog.component.scss']
})
export class AttachmentsDialogComponent implements OnInit { 
  
    attachments: Attachment[] = [];
    path: string;
    type: string;
    includeDomain: boolean;

     constructor(@Inject(MAT_DIALOG_DATA) private data: any,
        public dialogRef: MatDialogRef<AttachmentsDialogComponent>,
        private spinner: SpinnerService,
        private snackbar: MatSnackBar,
        private translator:TranslateService,
        private s3: S3UploadService
    ) { }

    ngOnInit() {
        this.attachments = this.data.attachments;
        // To know for which feature attachments should upload to s3..!!
        this.path = this.data.path; 
        
        this.includeDomain = this.data.includeDomain;
    }

    uploadFile(fileEvent) {
        if (!fileEvent.target.files && !fileEvent.target.files[0]) return;

        this.spinner.changeStatus(true, true);
        const file = fileEvent.target.files[0];

        if (file.type.includes('svg')) {
            this.spinner.changeStatus(false, false);
            this.translator.get(['Please upload only JPEG/PNG/JPG']).subscribe(translatedText=>{
            this.snackbar.open(translatedText['Please upload only JPEG/PNG/JPG'], '', {
                duration: 3000, panelClass: ['snakbar-color']
            });
        })
        } else {
            var s3Path;
            var streamPath;

            if (this.path == 'productReview') {
                // This path is for uploading attachments for reviews
                s3Path = `data/reviewAndRating/farmer/attachments/${new Date().getTime()}.${file.type.split('/')[1]}`
                streamPath = "PRODUCT_REVIEWS"
            } else if (this.path == 'orders') {
                s3Path = `data/activity/share-a-thought/${new Date().getTime()}.${file.type.split('/')[1]}`
                streamPath = "stream"
            }
            else {
                // This path is for uploading attachments for Rfq's
                s3Path = `data/rfqAttachments/${new Date().getTime()}.${file.type.split('/')[1]}`;
                streamPath = 'RFQ'
            }
            const type = file.type.includes('image') ? AttachmentType.image : AttachmentType.document;
            
            this.s3.uploadImage(file, s3Path, streamPath)
                .subscribe(url => {
                    this.spinner.changeStatus(false, false);
                    if(!this.includeDomain) url = url.replace(environment.baseUrl, '');
                    this.attachments.push(new Attachment(url, type));
                });
        }
    }

    submit() {
        this.dialogRef.close(this.attachments);
    }

    /**
     * On close attachments should not get displayed
     */
    
    close () {
        this.attachments = [];
        this.dialogRef.close(this.attachments);
    }

}