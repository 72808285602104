<!-- To show the preview of all review attachments from review-tab in product full-view -->
<div fxLayout="column" class="review-attachments">
    <h2 mat-dialog-title>User Images ({{attachments?.length}})</h2>
    <mat-dialog-content>
        <div fxLayout="row wrap" fxLayoutGap="5px">
            <div *ngFor="let attachment of attachments; let index = index">
                <div class="img-outliner" fxLayoutAlign="center center" fxLayout>
                    <div class="attachment" [style.backgroundImage]="'url(' + (attachment?.url | scaleImage: 'lg') + ')'" fxFlex="125px"
                        fxFlex.xs="90px" (click)="openInImageViewer(index)"></div>
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <div class="dialog-actions" fxLayoutAlign="end">
        <button mat-button (click)="close()">Close</button>
    </div>
</div>
<photo-swipe *ngIf="gallery?.length" [images]="gallery" [index]="selectedIndex" (close)="closeImageDialog()"></photo-swipe>