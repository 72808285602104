import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { HttpWithAuthService } from '../project-common/services/http-interceptor.service';

@Injectable({
    providedIn: 'root'
})
export class QuotationService {

    APP_NAME = `APGREEN`;

    baseUrl = `${environment.nodeServer}/v2/bizstore/quotations`;

    constructor(private http: HttpWithAuthService) { }

    searchBaseProduct(request: { keyword: string, offset?: any, limit?: any }): Observable<any> {
        return this.http.get(`${environment.baseUrl}/rest/v1/search/Vproduct/bizstore?keyword=${request.keyword}&limit=${request.limit || 20}&now=true&offset=${request.offset || 0}`).pipe(
            map(response => {
                const result: any = response;
                return result;
            })
        )
    }

    getRelaventSellers(baseProduct) {
        const url = `${environment.baseUrl}/v2/bizstore/quotations/${baseProduct}/sellers`;
        return this.http.get(url);
    }

    getl2Products(profileKey, offset = 0, limit = 100) {
        let url = `${environment.baseUrl}/v2/listing/products/l2?rfq=true&offset=${offset}&limit=${limit}&storeType=BIZ_STORE&profileKey=${profileKey}`;
        return this.http.get(url).pipe(map(data => {
            return data;
        }));
    }

    submitRequirement(request) {
        return this.http.post(`${environment.restBaseUrlV2}/store/${this.APP_NAME}/rfq/create`, request).pipe(
            map(response => {
                const result: any = response;
                return result;
            })
        );
    }

    submitAssistRequirement(request) {
        return this.http.post(`${this.baseUrl}/assist`, request).pipe(
            map(response => {
                const result: any = response;
                return result;
            })
        );
    }

    submitQuotation(requirementId: string, request) {
        return this.http.put(`${environment.restBaseUrlV2}/store/${this.APP_NAME}/rfq/${requirementId}/reply`, request).pipe(
            map(response => {
                const result: any = response;
                return result;
            })
        );
    }

    getSentList(inboxType: string, offset = 0, limit = 20) {
        const url = `${environment.nodeServer}/v2/store/${this.APP_NAME}/rfq/sent?offset=${offset}&limit=${limit}&inboxType=${inboxType}`;
        return this.http.get(url).pipe(
            map(response => {
                const result: any = response;
                return result;
            })
        )
    }

    getInboxList(inboxType: string, offset = 0, limit = 20) {
        const url = `${environment.nodeServer}/v2/store/${this.APP_NAME}/rfq/inbox?offset=${offset}&limit=${limit}&inboxType=${inboxType}`;
        return this.http.get(url).pipe(
            map(response => {
                const result: any = response;
                return result;
            })
        )
    }

    getLatestRfqs(offset = 0, limit = 20) {
        const url = `${this.baseUrl}/suggested?offset=${offset}&limit=${limit}`;
        return this.http.get(url).pipe(
            map(response => {
                const result: any = response;
                return result;
            })
        )
    }

    getRequirementDetails(requirementId: string, isAssistant?: boolean) {
        const url = `${environment.nodeServer}/v2/store/${this.APP_NAME}/rfq/${requirementId}/details${isAssistant ? '?isAssisting=true' : ''}`;

        return this.http.get(url).pipe(
            map(response => {
                const result: any = response;
                return result;
            })
        )
    }

    getQuotationDetails(requirementId: string, quotationId: string, isAssistant?: boolean) {
        const url = `${environment.nodeServer}/v2/store/${this.APP_NAME}/rfq/${requirementId}/${quotationId}${isAssistant ? '?isAssisting=true' : ''}`;

        return this.http.get(url).pipe(
            map(response => {
                const result: any = response;
                return result;
            })
        )
    }

    sendSms(requestObj: any) {
        const url = `${this.baseUrl}/${requestObj.requirementId}/${requestObj.quotationId}/sendSMS`;

        return this.http.post(url, requestObj).pipe(
            map(response => {
                const result: any = response;
                return result;
            })
        )
    }

    getExistingProducts(baseProductId: string, offset: number, limit: number, profileKey?: string) {
        const url = `${this.baseUrl}/${baseProductId}/saleableItems?profileKey=${profileKey}&offset=${offset}&limit=${limit}`;
        return this.http.get(url).pipe(
            map(response => {
                const result: any = response;
                return result;
            })
        )
    }

    attachProductToRequirement({ requirementId, quotationId, payload }: { requirementId: string, quotationId: string, payload: any }) {
        const url = `${this.baseUrl}/${requirementId}/${quotationId}/addProduct`;

        return (
            this.http.put(url, payload)
                .pipe(map(response => response))
        );
    }

    closeQuotation(request) {
        const { sender, assistedBy, requirementId, quotationId, isAssisting } = request;
        const url = `${environment.nodeServer}/v2/store/${this.APP_NAME}/rfq/${requirementId}/${quotationId}/close${isAssisting ? '?isAssisting=true' : ''}`;

        return (
            this.http.put(url, isAssisting ? { sender, assistedBy } : sender)
                .pipe(map(response => response))
        );
    }

    closeRequirement(request) {
        const { sender, assistedBy, requirementId, isAssisting } = request;
        const url = `${environment.nodeServer}/v2/store/${this.APP_NAME}/rfq/${requirementId}/close${isAssisting ? '?isAssisting=true' : ''}`;

        return (
            this.http.put(url, isAssisting ? { sender, assistedBy } : sender)
                .pipe(map(response => response))
        );
    }

    submitTerms(requirementId: string, quotationId: string, requestPayload: any) {
        const url = `${environment.nodeServer}/v2/store/${this.APP_NAME}/rfq/${requirementId}/${quotationId}/terms`;
        return this.http.put(url, requestPayload).pipe(
            map(response => {
                const result: any = response;
                return result;
            })
        )
    }

    getRfqsById(profileKey, limit = 10, offset = 0): Observable<any> {
        const url = `${this.baseUrl}/requirements/${profileKey}?limit=${limit}&offset=${offset}`;

        return this.http.get(url).pipe(map(res => res));
    }

    /**
     * To get assistants for farm store
     * 
     * This will have list of Kalgudi employees in arrays depending on their role
     * 
     * @returns { Observable<any> } json object having multiple role based arrays
     */
    // getAssistants(): Observable<any> {
    //     let url = environment.baseUrl;
    //     url += `/data/storeJsons/biz_assistants`;
    //     return this.http.get(url).pipe(map(response => response));
    // }

   
    getAssistants(): Observable<any> {
        // const url = `${environment.nodeServer}/v2/bizstore/profiles/assistants`
        // return this.http.
        //     get(url).pipe(map(resposne => {
        //         const result: any = resposne.data;
        //         return result;
        //     }));
        let url = `${environment.nodeServer}/v2/store/${this.APP_NAME}/rfq/assistants`;
        return this.http.get(url).pipe(map(response => response.data));
    }
    

    getARFQTypes() {
        let url = `${environment.baseUrl}/rest/v1/bizstore/misc/arfqtemplates`;

        return this.http.get(url).pipe(map((res: any) => JSON.parse(res.data)));
    }

    /**
     * @param eventId connectx.kalgudi.com (Event id)
     */
    getEventDetails(eventId: string) {
        let url = `${environment.baseUrl}/v2/meetups/events/${eventId}`;
        return this.http.get(url);
    }

    getMyAssistedRfqs(reqObje, reqEmail) {
        // const url = `${this.baseUrl}/assisted/${reqObje.email}?role=${reqObje.role}&filter=${reqObje.userRole}&rfqState=${reqObje.state.toUpperCase()}&offset=${reqObje.offset}&limit=${reqObje.limit}`
        const url = `${this.baseUrl}/assisted/${reqEmail}`
        return this.http.get(url, reqObje).pipe(map(response => response));
    }
    // TagAssistantRfqs

    getRequirements(requestObject) {
        const url = `${this.baseUrl}/requirements`;
        return this.http.get(url, requestObject).pipe(map(data => {
            return data;
        }));
    }

    getTenders(requestObject) {
        const url = `${environment.baseUrl}/rest/v2/store/quotations/OUTPUTS/tenders`;
        return this.http.get(url, requestObject).pipe(map(data => {
            return data;
        }));
    }

    getTenderRequirements(tenderId, request) {
        const url = `${environment.baseUrl}/rest/v2/store/quotations/OUTPUTS/tender/${tenderId}`;
        return this.http.get(url, request).pipe(map(data => {
            return data;
        }));
    }


    deleteMessage(message, requirementId, quotationId) {
        const url = `${this.baseUrl}/${requirementId}/${quotationId}`;

        return this.http.put(url, message).pipe(map(response => response));
    }

    convertGenericRequirementIntoEnquiry(request) {
        const url = `${this.baseUrl}/convertToEnquiry`
        return this.http.post(url, request);
    }

    assistGenericRfq(request) {
        const url = `${this.baseUrl}/OUTPUTS/toAssist`;
        return this.http.post(url, request);
    }

    getQuotColor(state) {
        if (state == 'QUOT_SKU_READY') return '#b48504';
        else if (state == 'QUOT_SKU_PUBLISHED') return 'red';
        else if (state == 'QUOT_ORDERED' || state == 'QUOT_CLOSED' || state == 'RFQ_CLOSED') return 'green';
        else return '#8B4513';
    }

   /* getUnreadRfqMsgCount() {
        const url = `${this.baseUrl}/unreadRfqCount`;
        return this.http.get(url).pipe(map(data => {
            return data;
        }));
    }
    */

    submitMultiProductRequirement(request) {
        return this.http.post(`${environment.restBaseUrlV2}/store/${this.APP_NAME}/rfq/create`, request).pipe(
            map(response => {
                const result: any = response;
                return result;
            })
        );
    }

    updateLeadType(requirementId, leadType) {
        return this.http.put(`${environment.baseUrl}/rest/v2/store/quotations/OUTPUTS/${requirementId}/leadType/${leadType}`, {}).pipe(
            map(response => {
                const result: any = response;
                return result;
            })
        );
    }

    updatedAssistants(requirementId, assistantPayload) {
        
        return this.http.put(`${environment.baseUrl}/rest/v2/store/quotations/OUTPUTS/${requirementId}/updateAssistant`, assistantPayload).pipe(
            map(response => {
                const result: any = response;
                return result;
            })
        );
    }

    getSellerProducts(commaSeparatedProfileKeys: string) {
        const url = `${environment.baseUrl}/rest/v2/bizstore/quotations/sellerProducts?ids=${commaSeparatedProfileKeys}`;

        return this.http.get(url);
    }

    getSellerRfqs(commaSeparatedProfileKeys: string) {
        const url = `${environment.baseUrl}/rest/v2/bizstore/quotations/sellerRfqs?ids=${commaSeparatedProfileKeys}`;

        return this.http.get(url);
    }

    getBuyerRfqs(buyerProfileKey: string) {
        const url = `${environment.baseUrl}/rest/v2/bizstore/quotations/buyerRfqs?ids=${buyerProfileKey}`;
        return this.http.get(url);
    }

    submitMltipleQuotation(requirementId: string, request) {
        return this.http.put(`${this.baseUrl}/${requirementId}/reply/broadcast`, request).pipe(
            map(response => {
                const result: any = response;
                return result;
            })
        );
    }

    getRfqStats() {
        const url = `${environment.baseUrl}/v2/listing/rfqs/statistics`;

        return this.http.get(url);
    }

    prepareProduct(product) {
        let productObj = {
            productId: product.productId || product.VProductId,
            productName: product.productName || product.VProductName,
            baseCategory: product.baseCategory,
            VProductId: product.VProductId || product.productId,
            VProductName: product.VProductName || product.productName
        }
        return productObj;
    }

}       
