import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
@Component({
    selector: 'contact-cs-team-dialog',
    templateUrl: './contact-cs-team-dialog.component.html',
    styleUrls: ['./contact-cs-team-dialog.component.scss']
})
export class ContactCsTeamDialogComponent implements OnInit {

    phone: string;
    message: string;
    constructor(public dialogRef: MatDialogRef<ContactCsTeamDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data) { }

    ngOnInit() {
        this.phone = this.data.number;
        this.message = this.data.message;
    }

}
