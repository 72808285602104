import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var SpinnerService = /** @class */ (function () {
    function SpinnerService() {
        this.spinnerStatus = new BehaviorSubject({ enableSpinner: false });
        this.currentStatus = this.spinnerStatus.asObservable();
    }
    /**
     * Common method across application to enable/ disable spinner
     *
     * @param enableSpinner pass `true` to enable spinner, `false` otherwise
     * @param blocking `true` if spinner is blocking, `false` for non-blocking spinner.
     */
    SpinnerService.prototype.changeStatus = function (enableSpinner, blocking) {
        var _this = this;
        if (enableSpinner === void 0) { enableSpinner = false; }
        if (blocking === void 0) { blocking = true; }
        // Make sure there is difference between spinner enable and disable
        // If there is very less difference the WebContainer throws exception
        setTimeout(function () {
            _this.spinnerStatus.next({
                enableSpinner: enableSpinner,
                blocking: blocking
            });
        }, 100);
    };
    SpinnerService.prototype.start = function () {
        this.changeStatus(true, true);
    };
    SpinnerService.prototype.stop = function () {
        this.changeStatus(false, false);
    };
    SpinnerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SpinnerService_Factory() { return new SpinnerService(); }, token: SpinnerService, providedIn: "root" });
    return SpinnerService;
}());
export { SpinnerService };
