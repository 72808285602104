import { OnInit } from '@angular/core';
import { UtilityService } from '../../services/utility.service';
var StoreNotificationsComponent = /** @class */ (function () {
    function StoreNotificationsComponent(util /* , private notification: FcmService */) {
        this.util = util;
    }
    StoreNotificationsComponent.prototype.ngOnInit = function () {
        this.util.isMobileDevice() && window.scroll(0, 0);
    };
    Object.defineProperty(StoreNotificationsComponent.prototype, "notifications", {
        get: function () {
            var notifications = [];
            /* Object.values(this.notification.notifications.notifications).forEach( notification => {
              notifications.push(notification);
            }); */
            return notifications;
        },
        enumerable: true,
        configurable: true
    });
    StoreNotificationsComponent.prototype.read = function (isReadAll, notification) {
        var payload = {
            readAllFlag: isReadAll || false,
            notificationIds: notification ? [notification.id] : []
        };
        /* this.notification.readStoreNotification(payload).subscribe(res => {
          // console.log('res =',res);
        }); */
    };
    return StoreNotificationsComponent;
}());
export { StoreNotificationsComponent };
