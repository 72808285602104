import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilityService } from '../project-common/services/utility.service';
import { FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { SpinnerService } from '../project-common/services/spinner.service';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { UserProfile } from '../project-common/models/user.model';
import { TranslateService } from '@ngx-translate/core';
import { UserSubscriberService } from '../project-common/services/user-subscriber.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {


  // @Input() footerColor: boolean;
  footerData: any;
  footerKey: any;
  email = new FormControl('', [Validators.required, Validators.email]);
  userProfile: UserProfile;
  countryDetails: any;

  constructor(
    // private notification: NotificationService,
    // private s3: S3UploadService,
    public router: Router,
    private utility: UtilityService,
    private subscriberService: UserSubscriberService,
    private snackbar: MatSnackBar,
    private translator: TranslateService,
    private spinner: SpinnerService,
    private storage: LocalStorage
  ) {

  }

  ngOnInit() {
    // this.footerColor = false;
    this.footerData = {};
    /* this.s3.getJsonDataFromS3('/data/footerlinks/footer_link_sections.json').subscribe(data =>{
      if(data){
        this.footerData = data.data;
        this.footerKey = Object.keys(this.footerData);
      }
    }, error => {
      this.footerData = {};
    }); */
    this.storage.getItem('userdata').subscribe(userData => {
      this.userProfile = userData;
    })
    this.storage.getItem('countryDetails').subscribe(country => {
      this.countryDetails = country;
    })
  }

  openHelp(key) {
    if (this.utility.isMobileDevice()) {
      this.router.navigate([
        'help', key,
      ]);
    } else {
      let url = window.location.origin + this.utility.getPathName(window.location.pathname) + 'help/' + key;
      window.open(url, '_blank');
    }
  }

  get isMobileDevice() {
    return this.utility.isMobileDevice();
  }

  subscribeUs() {
    const first = `Please enter a valid e-mail address`;
    const last = `OK`;
    if(this.email.invalid) {
      return this.translator.get([first, last]).subscribe(translatedText => {
        this.snackbar.open(`${translatedText[first]}` , `${translatedText[last]}` ,{
        duration: 4000, panelClass: ['snakbar-color']
      });
    });
    }
    
    this.spinner.start();
    this.subscriberService.subscribeToNewsLetters(this.email.value).subscribe(res => {
      this.spinner.stop();
      if(res.code == 201) {
        this.email.reset();
        return this.snackbar.open(res.info, 'OK', {
          duration: 6000, panelClass: ['snakbar-color']
        });  
      }
    }, error => {
      this.spinner.stop();
      this.snackbar.open(error.info, '', { duration: 6000, panelClass: ['snakbar-color'] });
    });
  }

  get isAdminPage() {
    return this.router.url.includes('/support');
  }

}
