import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { HttpWithAuthService } from './http-interceptor.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./http-interceptor.service";
var S3UploadService = /** @class */ (function () {
    function S3UploadService(http, httpWithAuth) {
        this.http = http;
        this.httpWithAuth = httpWithAuth;
    }
    S3UploadService.prototype.getAttachmentPolicy = function (streamCategory, contentType) {
        if (streamCategory === void 0) { streamCategory = 'business'; }
        if (contentType === void 0) { contentType = 'image/'; }
        var url = environment.baseUrl + "/rest/v1/signpolicy?category=" + streamCategory + "&contentType=" + contentType;
        return this.httpWithAuth.get(url).pipe(map(function (response) {
            var data = response;
            if (data.code === 200) {
                return JSON.parse(data.data);
            }
            else {
                throw Error('Unable to get S3 policy for image upload');
            }
        }));
    };
    /**
     *  Use this service to upload the image file to S3 and get S3 Url of the uploaded file.
     *  Internally it call for attachment policy and also it compresses the image file if needed.
     *
     * @param file Image file object
     * @param fileName Aws file name with location(relative)
     * @param stream Depending on module of kalgudi, we have to send stream Ex: business, profilepic
     */
    S3UploadService.prototype.uploadImage = function (file, fileName, stream) {
        var _this = this;
        return new Observable(function (observer) {
            _this.getAttachmentPolicy(stream, 'image/').subscribe(function (response) {
                var s3 = response;
                _this.compressImage(file).subscribe(function (compressedImage) {
                    var request = new FormData();
                    request.append('key', fileName);
                    request.append('AWSAccessKeyId', s3.accesskey);
                    request.append('acl', 'public-read');
                    request.append('policy', s3.policy);
                    request.append('signature', s3.signature);
                    request.append('Content-Type', file.type !== '' ? file.type : 'application/octet-stream');
                    request.append('file', compressedImage);
                    _this.http.post(environment.bucketUrl, request).subscribe(function (s3Response) {
                        observer.next(environment.baseUrl + '/' + fileName);
                        observer.complete();
                    });
                }, function (error) {
                    console.error(error);
                    throw new Error('Failed to upload the image');
                });
            }, function (error) {
                console.error(error);
                throw new Error('Failed to upload the image');
            });
        });
    };
    S3UploadService.prototype.compressImage = function (file) {
        return new Observable(function (observer) {
            var img = document.createElement('img');
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function (e) {
                img.src = e.target.result;
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);
                var MAX_WIDTH = 960;
                var MAX_HEIGHT = 960;
                img.onload = function () {
                    var width = img.width;
                    var height = img.height;
                    if (width > 0 || height > 0) {
                        if (width > height) {
                            if (width > MAX_WIDTH) {
                                height *= MAX_WIDTH / width;
                                width = MAX_WIDTH;
                            }
                        }
                        else {
                            if (height > MAX_HEIGHT) {
                                width *= MAX_HEIGHT / height;
                                height = MAX_HEIGHT;
                            }
                        }
                        canvas.width = width;
                        canvas.height = height;
                        var ctx2 = canvas.getContext('2d');
                        ctx2.drawImage(img, 0, 0, width, height);
                        var dataurl = canvas.toDataURL('image/jpeg', 0.82);
                        var byteString = '';
                        if (dataurl.split(',')[0].indexOf('base64') >= 0) {
                            try {
                                byteString = atob(dataurl.split(',')[1]);
                            }
                            catch (e) {
                                console.log(e);
                            }
                        }
                        else {
                            byteString = unescape(dataurl.split(',')[1]);
                        }
                        var mimeString = dataurl.split(',')[0].split(':')[1].split(';')[0];
                        var ia = new Uint8Array(byteString.length);
                        for (var i = 0; i < byteString.length; i++) {
                            ia[i] = byteString.charCodeAt(i);
                        }
                        var tempBlob = new Blob([ia], { type: 'image/jpeg' });
                        // tempBlob.name = file.name;
                        observer.next(tempBlob);
                        observer.complete();
                    }
                    else {
                        observer.next(file);
                        observer.complete();
                    }
                };
            };
        });
    };
    S3UploadService.prototype.getHtmlFromS3 = function (s3url) {
        return this.http.get(s3url, { responseType: 'text' }).pipe(map(function (response) {
            return response;
        }, function (error) {
            console.error(error);
            throw new Error('Failed to download html');
        }));
    };
    S3UploadService.prototype.getJsonDataFromS3 = function (filePath, sourcePath) {
        var url = sourcePath === 'sellerActivity' ? filePath : "" + environment.baseUrl + filePath;
        return this.http.get(url).pipe(map(function (response) {
            return response;
        }, function (error) {
            console.error(error);
            throw new Error('Failed to download Json');
        }));
    };
    S3UploadService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function S3UploadService_Factory() { return new S3UploadService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.HttpWithAuthService)); }, token: S3UploadService, providedIn: "root" });
    return S3UploadService;
}());
export { S3UploadService };
