import { MatSnackBar } from '@angular/material';
import { Injectable,Output } from '@angular/core';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { ToasterService } from './toaster.service';
import { HttpWithAuthService } from './http-interceptor.service';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { EventEmitter } from 'events';
import { Wishlist } from '../models/wishlist.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class WishListService {

  savedProduct : any;
  wishlist = new Wishlist({});

  @Output() valueChange = new EventEmitter();

  constructor(private localStorage: LocalStorage,
    private toaster: ToasterService,
    private translator: TranslateService,
    private httpWithAuth: HttpWithAuthService,
    private snackbar: MatSnackBar ) { }


  init(){
    if(!JSON.parse(window.localStorage['YUdGelRHOW5aMlZrU1c0PQ=='] || 'false')) return;

    this.get();
  }
  
  /**
   * To get the user wishList
   */
  private get () {
    // const endPoint = `${environment.baseUrl}/rest/v1/catalog/wishlist`;
    const endPoint = `${environment.nodeServer}/rest/v2/farmstore/favorite/products`;
    
    this.httpWithAuth.get(endPoint).pipe(map(response => response ))
    .subscribe((res: any) => this.wishlist = new Wishlist(JSON.parse(res.data)));
  }

  /**
   * To get the user wishList
   */
  public getUserWishList (): Observable<any> {
    // const endPoint = `${environment.baseUrl}/rest/v1/catalog/wishlist`;
    const endPoint = `${environment.nodeServer}/rest/v2/bizstore/favorite/products`;
    return this.httpWithAuth.get(endPoint).pipe(map(response => {
      const result: any = response;
      return result;
    }));
  }

  /**
   * To delete a product from wish list
   */
  deleteProductFromWishList (productLevel3Id): Observable<any> {
    // const endPoint = `${environment.baseUrl}/rest/v2/bizstore/favorite/products/${productLevel3Id}`;
    const endPoint = `${environment.nodeServer}/rest/v1/catalog/wishlist/${productLevel3Id}`;
    return this.httpWithAuth.delete(endPoint).pipe(map(response => {
      const result: any = response;
      if(result.code === 200 || result.code === 201){
          this.addWishListTOIndexDb(result.data);
          this.translator.get([`Item removed from wishlist`]).subscribe(translatedText=>{
          this.snackbar.open(translatedText[`Item removed from wishlist`], '', {
            duration: 1000, panelClass: ['snakbar-color']
          });
        })
      }
      return result;
    }));
  }

  /**
   * To add the product into user wishList
   *
   * @param productLevel2Id
   * @param productLevel3Id
   */
  public addProductToWishList(productLevel2Id, productLevel3Id): Observable<any> {
    // const endPoint = `${environment.baseUrl}/rest/v1/catalog/wishlist/${productLevel2Id}/${productLevel3Id}`;
    const endPoint = `${environment.nodeServer}/rest/v2/bizstore/favorite/products/${productLevel2Id}/${productLevel3Id}`;

    return this.httpWithAuth.put(endPoint, {}).pipe(map(response => {
      const result: any = response;
      if(result.code === 200 || result.code === 201){
        this.addWishListTOIndexDb(result.data);
        this.translator.get([`Item added to wishlist`]).subscribe(translatedText=>{
        this.snackbar.open(translatedText[`Item added to wishlist`], '', {
          duration: 1000, panelClass: ['snakbar-color']
        });
      })
      }
      return result;
    }));
  }

  /**
   * To add user wish list in indexDB
   *
   */
  public addWishListTOIndexDb(userWishList){
    this.localStorage.setItemSubscribe('userWishList', userWishList);
  }

  /**
   * To add the maker in favouriteMakers list
   *
   * v1/catalog/favourites/makers/M00001v0PRFREG2018110118428529UNH001
   * @param makerProfileKey
   */
  public updateFavouriteMakers(makerProfileKey): Observable<any> {
    return this.httpWithAuth.put(`${environment.nodeServer}/rest/v2/bizstore/favorite/makers/${makerProfileKey}`,{}).pipe(map(response => {
      const result: any = response;
      if(result.code === 200 || result.code === 202){
        this.addFavouriteMakersInIndexDb(result.data);
      }
      return result;
    }));
  }

  /**
   * To get the favouriteMakers
   * @param basicDetailsFlag //boolean value : true means service will return list of basicProfiledata other wise list of profileKey
   *
   */
  public getFavouriteMakers (basicDetailsFlag): Observable<any> {
    return this.httpWithAuth.get(`${environment.nodeServer}/rest/v2/bizstore/favorite/makers`).pipe(map(response => {
      const result: any = response;
      return result;
    }));
  }

  /**
   * To delete one maker from favouriteMakers
   * @param makerProfileKey //profileKey of maker thats needto add in maker list
   */
  public deleteFavouriteMaker (makerProfileKey): Observable<any> {
    return this.httpWithAuth.delete(`${environment.nodeServer}/rest/v2/bizstore/favorite/makers/${makerProfileKey}`).pipe(map(response => {
      const result: any = response;
      if(result.code === 202){
        this.addFavouriteMakersInIndexDb(result.data);
      }
      return result;
    }));
  }

  /**
   * To add favouriteMakers in indexDB
   *
   */
  public addFavouriteMakersInIndexDb(favouriteMakers){
    // console.log(12465);
    this.localStorage.setItemSubscribe('FAVOURITE_MAKERS', favouriteMakers);
  }

  /**
   * @returns user's favourite maker list
   * Will take list from indexDB if availabe other wise call the service
   */
  public getMakerList () {
    return new Promise((resolve, reject) => {
      this.localStorage.getItem('FAVOURITE_MAKERS').subscribe(list =>{
        // console.log('List '+ list);
        if(list == null){
          this.getFavouriteMakers(false).subscribe(data =>{
            // console.log('data '+data);
            if(data.code == 200){
              this.addFavouriteMakersInIndexDb(data.data);
              resolve(JSON.parse(data.data));
            }else{
              reject(data);
            }
          });
        }else{
          resolve(JSON.parse(list));
        }
      })
    })
  }

  /**
   * Will take logedIn user's "saver for later" product list from service
   */
  getSavedProducts(): Observable<any> {
    return this.httpWithAuth.get(`${environment.baseUrl}/rest/v1/catalog/cart/saveditem`).pipe(map(response => {
      const result: any = response;
      if(result.code == 200){
        this.setSaveProductInLocalDb(JSON.parse(result.data));
      }
      return result;
    }));
  }

  /**
   * Save product for later @put service
   */
  saveProductForLater(payload): Observable<any>{
    return this.httpWithAuth.put(`${environment.baseUrl}/rest/v1/catalog/cart/saveditem`,payload).pipe(map(response => {
      const result: any = response;
      return result;
    }));
  }

  /**
   * Will store product list in indexDB
   * @param data product list in json formate
   */
  setSaveProductInLocalDb (data){
    this.localStorage.setItemSubscribe('SAVED_PRODUCTS', (data));
  }

  /**
   * Will remove saved product list from inedxDb
   */
  removeSavedProductsFromLocal (){
    this.localStorage.removeItem('SAVED_PRODUCTS');
  }

  /**
   * will @returns save product list from indexDB
   */
  getSavedProductsFromLocalDb () {
    return new Promise((resolve, reject) => {
      this.localStorage.getItem('SAVED_PRODUCTS').subscribe(savedProduct =>{
        if(savedProduct != null){
          resolve((savedProduct));
        }else{
          let product = {
            products: [],
            productIds: []
          };
          resolve(product);
        }
      })
    })
  }

  dispatchUpdate(flag: string = 'true') {
    setTimeout(() => {
      this.valueChange.emit(flag);
    }, 300);
  }

  /**
   * Will remove the product from save list if available
   * @param product
   */
  removeProductFromSavedList(product) {
    this.getSavedProductsFromLocalDb().then((products: any) =>{
      for (const i in products.productIds) {
        if (products.productIds [i] === product.productLevel3Id) {
          products.products.splice(i, 1);
          products.productIds.splice(i, 1);
          this.saveProductForLater(products).subscribe(data =>{});
          this.setSaveProductInLocalDb(products);
          this.dispatchUpdate('true');
          break;
        }
      }
    });
  }

  /**
   * Will add the product into save list if not available
   * @param product
   */
  addProductIntoSavedList(product) {
    this.getSavedProductsFromLocalDb().then((products: any) =>{
      for ( const i in products.productIds) {
        if (products.productIds[i] === product.productLevel3Id) {
          this.translator.get([`Selected product is already available.`,]).subscribe(translatedText=>{
          this.snackbar.open(translatedText[`Selected product is already available.`], '', {
            duration: 2000, panelClass: ['snakbar-color']
          });
        })
          return;
        }
      }
      product.cartUTS = JSON.stringify(new Date());
      products.productIds.push(product.productLevel3Id);
      products.products.push(product);
      this.saveProductForLater(products).subscribe(data =>{});
      this.setSaveProductInLocalDb(products);
      this.dispatchUpdate('true');
    });
  }
}
