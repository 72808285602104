import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { trigger, transition, style, animate, state } from '@angular/animations';
import { fade } from '../../../../animations';
import { StorefrontService } from '../../storefront.service';
import { Observable } from 'rxjs';
import { NotificationService } from 'src/app/project-common/services/notification.service';
import { CategoryService } from 'src/app/project-common/services/category.service';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'mobile-sidenav',
  templateUrl: './mobile-sidenav.component.html',
  styleUrls: ['./mobile-sidenav.component.scss'],
  animations: [
    fade,
    trigger('side-nav', [

      state('closed', style({ transform: 'translateX(-105%)' })),
      state('opened', style({ transform: 'translateX(0px)' })),

      transition('closed <=> opened', animate('0.2s ease-in-out'))

    ])
  ]
})
export class MobileSidenavComponent implements OnInit {

  categories$: Observable<any[]>;


  @Input() userData: any;
  @Input() readonly showSideNav: boolean;
  @Input() isUserLoggedIn: boolean;
  @Output() logout = new EventEmitter();
  @Output() toggle = new EventEmitter();

  selectedLanguage: string;

  constructor(private storefrontService: StorefrontService,
    private notification: NotificationService,
    private categoryService: CategoryService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.categories$ = this.categoryService.categories$;
    this.selectedLanguage = localStorage['lang'];
  }

  switchLanguage(languageCode) {
    this.selectedLanguage = languageCode;
    this.notification.notifyForLanguageChange(languageCode);
  }

  searchForCategoryMobile(request) {
    if (request.isBaseCategory) {
      this.router.navigateByUrl('search/' + request.keyword);
    } else {
      const navigationExtras: NavigationExtras = {
        queryParams: request
      };
      navigationExtras.queryParams.limit = 20;
      navigationExtras.queryParams.view = 'grid';
      this.router.navigate(['/search', request.keyword], navigationExtras);
    }

    this.toggle.emit();
  }

}
