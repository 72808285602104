
export class SubscribeUsPayload {
    callback?: boolean;
    getAppLink: boolean;
    email?: string;
    enquiryType: SUBSCRIPTION_TYPE;
    name?: string;
    phone?: string;
    page: string;
    pinCode?: string;
    products?: any[];
    productId?: string;
    productName?: string;
    subscriptionFrequency?: string;
    countryCodeInfo: CountryDetails;
    sessionInfo?: SessionInformation;

    constructor(enquiryType: SUBSCRIPTION_TYPE, payload: any) {
        this.enquiryType = enquiryType;
        this.email = payload.emailId || payload.email || '';
        this.phone = payload.phone || '';
        this.name = payload.name || '';
        this.callback = payload.phone ? true : false;
        this.getAppLink = payload.phone ? true : false;
        this.page = payload.page || '';
        this.pinCode = payload.pinCode || '';
        this.productId = payload.productId || '';
        this.productName = payload.productName || '';
        this.products = payload.products || [];
        this.subscriptionFrequency = '';
        this.countryCodeInfo = payload.countryCodeInfo;
        this.sessionInfo = payload.sessionInfo;
    }
}

export interface SessionInformation {
    loginId?: string;
    mobileNo?: string;
    emailId?: string;
    firstName: string;
    profileKey: string;
}

export interface CountryDetails {
    countryId: string;
    countryName: string;
    countryShortName: string;
    countryShortNameTwo: string;
    MobileNoLength: string;
    MobileTelecomcode: string;
}

declare type SUBSCRIPTION_TYPE = 'apGreens Pincode' | 'apGreen subscription' | 'product subscription';