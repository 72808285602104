<div class="app-foot" *ngIf="!router.url.includes('dashboard/')">
    <div *ngIf="!isAdminPage" class="foot-sec _2" id="footer-sec-2">
        <div class="app-container">
            <div class="pt-50" fxLayout="row" fxLayoutGap="0px" fxLayoutGap.xs="15px" fxLayout.xs="column">
                <div class="pr-50 font-15" fxFlex="30%">
                    <img [src]="'assets/apgreen/logo-500.png' | scaleImage:'format'" alt="andhragreens.com" width="200">
                    <!-- <div fxLayout fxLayoutGap="15px">
                        <div fxLayout="column" fxLayoutGap="5px" fxLayoutAlign="center center">
                            <img width="70" src="assets/tfresh/images/telanganalogo.webp" alt="">
                            <span>TS - Horticulture</span>
                        </div>
                    </div> -->
                    <p class="mt-25" translate>Andhragreens is your one-stop-shop for regional, authentic and direct-from-producer products. We bring you a wide range of unadultered regional products, responsibly produced and procured from all over Andhra Pradesh. We are specialized
                        in higher quality, healthier, regional fruits and vegetables, rich in flavor and nutritional value.</p>
                </div>
                <div fxFlex="70%" fxLayout="column">
                    <!-- <span class="t-footer-title" translate>Subscribe for latest offers</span>
                    <div class="subscribe-us" fxLayout>
                        <input fxFlex type="email" [formControl]="email" placeholder="{{ 'Enter your email' | translate }}">
                        <button (click)="subscribeUs()" translate>Subscribe</button>
                    </div> -->
                    <div class="contents" fxLayout.gt-md="row wrap" fxLayout.lt-sm="column" fxFlex.xs="100%">
                        <div class="content-container" fxFlex="30%" fxFlex.xs="100%">
                            <div class="content-data" fxLayout="column" fxLayoutGap="10px">
                                <div class="content-title" translate>STORES</div>
                                <div class="content-details">
                                    <ul class="m0">
                                        <li class="cursor-pointer">
                                            <a [routerLink]="['/about-us']" [target]="isMobileDevice ? '_self' : '_blank'" translate>andhragreens.com</a>
                                        </li>
                                        <li class="cursor-pointer">
                                            <a href="https://ebharathi.org/home" [target]="isMobileDevice ? '_self' : '_blank'" translate>ebharathi.org</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="content-container" fxFlex="30%" fxFlex.xs="100%">
                            <div class="content-data" fxLayout="column" fxLayoutGap="10px">
                                <div class="content-title" translate>Shop</div>
                                <div class="content-details">
                                    <ul class="m0">
                                        <li class="cursor-pointer">
                                            <a [routerLink]="['/help/Policies_Service-terms']" [target]="isMobileDevice ? '_self' : '_blank'" translate>Service
                                                terms</a>
                                        </li>
                                        <li class="cursor-pointer">
                                            <a [routerLink]="['/help/Shop_Returns']" [target]="isMobileDevice ? '_self' : '_blank'" translate>Returns</a>
                                        </li>
                                        <!-- <li class="cursor-pointer">
                                            <a [routerLink]="['/help/Shop_Settlements']"
                                            [target]="isMobileDevice ? '_self' : '_blank'" translate>Settlements</a>
                                        </li> -->
                                        <li class="cursor-pointer">
                                            <a [routerLink]="['/help/Policies_Privacy']" [target]="isMobileDevice ? '_self' : '_blank'" translate>Privacy
                                                policy</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="content-container" fxFlex="40%" fxFlex.xs="100%" fxLayout="column" fxLayoutGap="20px">
                            <div class="content-data" fxLayout="column" fxLayoutGap="10px">
                                <div class="content-title" translate>Contact us</div>
                                <div class="content-details contact-us">
                                    <div class="missed-call mb-10">
                                        <div class="number" fxLayout fxLayoutGap="12px" fxLayoutAlign="start center">
                                            <i class="fa fa-phone-square" aria-hidden="true"></i>
                                            <span>(+91) 6309995777 </span>
                                        </div>
                                    </div>
                                    <div class="missed-call">
                                        <span class="number" fxLayout fxLayoutGap="12px" fxLayoutAlign="start center">
                                            <i class="fa fa-envelope" aria-hidden="true"></i>
                                            <a href="mailto:andhragreens@kalgudi.com">andhragreens@kalgudi.com</a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="content-data" fxLayout="column" fxLayoutGap="10px">
                                <div class="content-title" translate>Follow us on</div>
                                <div class="content-details">
                                    <div class="social-links" fxLayout="row" fxLayoutGap="20px">

                                        <a class="social-link facebook" [target]="isMobileDevice ? '_self' : '_blank'" href="https://www.facebook.com/Andhragreens"><i
                                                class="fa fa-facebook"></i></a>
                                        <a class="social-link twitter" [target]="isMobileDevice ? '_self' : '_blank'" href="https://twitter.com/andhragreens"><i class="fa fa-twitter"></i></a>
                                        <a class="social-link linkedin" [target]="isMobileDevice ? '_self' : '_blank'" href="https://www.linkedin.com/company/andhragreens"><i
                                                class="fa fa-linkedin"></i></a>
                                        <!-- <a class="social-link blogger">
                                            <img src="assets/icons/blogger.png" alt="">
                                        </a> -->
                                        <a class="social-link instagram" [target]="isMobileDevice ? '_self' : '_blank'" href="https://www.instagram.com/andhragreens"><i
                                                class="fa fa-instagram"></i></a>

                                    </div>
                                    <!-- <div class="content-title" translate>Download now and get started</div> -->
                                    <!-- <div fxLayoutAlign="start" class="mb-10" translate>Download now and get started
                                    </div>
                                    <a href="https://play.google.com/store/apps/details?id=com.vasudhaika.andhragreens&hl=en" target="_blank"  fxLayoutAlign="start">
                                        <img width="150px" src="assets/icons/google-play-badge.svg" alt="">
                                    </a> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="foot-sec _3" id="footer-sec-3">
        <div class="app-container">
            <div class="contents" fxLayout fxLayoutAlign="space-between center" fxLayoutAlign.xs="space-between start" fxLayout.xs="column" fxLayoutGap="none" fxLayoutGap.xs="15px">

                <a target="_blank" href="https://kalgudi.com" fxLayoutAlign="center center" fxLayoutGap="8px">
                    <span class="light-text font-13" translate>Powered by</span>
                    <img [src]="'assets/images/kl-dark-green_22x20.png' | scaleImage:'format'" height="20px" width="20px" alt="">
                    <span class="font-16 fw-500" translate>Kalgudi</span>
                </a>
                <a target="_blank" fxLayoutGap="8px" class="support">
                    <span class="light-text font-13" translate>Supported by</span>
                    <img [src]="'assets/apgreen/emblem.png' | scaleImage:'format'" height="20px" width="20px" alt="">
                    <span class="font-15 fw-500" translate>Department of Horticulture, Andhra Pradesh</span>
                </a>

                <div class="copy-right" translate>Copyright © 2020, Andhragreens. All Rights Reserved</div>

            </div>
        </div>
    </div>
</div>