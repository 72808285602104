import { ToastaConfig, ToastaService } from 'ngx-toasta';
import * as i0 from "@angular/core";
import * as i1 from "ngx-toasta";
var ToasterService = /** @class */ (function () {
    function ToasterService(toastConfig, toastService) {
        this.toastConfig = toastConfig;
        this.toastService = toastService;
        toastConfig.theme = 'material';
        toastConfig.position = 'bottom-left';
    }
    ToasterService.prototype.showMessage = function (title, message, type, timeout, onToastShow, onToastClose) {
        if (type === void 0) { type = 'info'; }
        var toastOptions = {
            title: title,
            msg: message,
            showClose: true,
            timeout: timeout || 5000,
            theme: 'material',
            onAdd: onToastShow,
            onRemove: onToastClose,
        };
        switch (type) {
            case 'info':
                this.toastService.info(toastOptions);
                break;
            case 'success':
                this.toastService.success(toastOptions);
                break;
            case 'wait':
                this.toastService.wait(toastOptions);
                break;
            case 'error':
                this.toastService.error(toastOptions);
                break;
            case 'warning':
                this.toastService.warning(toastOptions);
                break;
        }
    };
    ToasterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ToasterService_Factory() { return new ToasterService(i0.ɵɵinject(i1.ToastaConfig), i0.ɵɵinject(i1.ToastaService)); }, token: ToasterService, providedIn: "root" });
    return ToasterService;
}());
export { ToasterService };
