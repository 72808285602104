import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { SearchService } from '../../services/search.service';
import { StorefrontService } from 'src/app/storefront/storefront.service';

@Component({
    selector: 'searchbar',
    templateUrl: './searchbar.component.html',
    styleUrls: ['./searchbar.component.scss']
})
export class SearchbarComponent implements OnInit {
    @Output() change = new EventEmitter();
    @Input('showSearch') showSearch: boolean;

    searchText: any;
    view: string;
    limit: number;
    products: any[];
    request: any;
    selectedText: any;
    timeoutId: any;
    searchingItem: any;
    myControl = new FormControl();
    isTrue: boolean = true;
    recentSearches: string[];

    selectedCategory: { id?: string, value?: string } = { id: '', value: '' };

    suggestedCategory: string[];

    constructor(
        private searchService: SearchService,
        private storeFrontService: StorefrontService
    ) { }

    ngOnInit() {

        this.request = {
            offset: 0,
            limit: 10
        };

        this.initSuggestedCategory();
        const searchtextData = localStorage.getItem("recentSearches");
        const recentSearches = JSON.parse(searchtextData);
        if (recentSearches && recentSearches.length > 0) {
            this.recentSearches = recentSearches.reverse();
        } else {
            this.recentSearches = recentSearches;
        }
    }

    autoComplete(text, event) {
        if (event.keyCode == 37 || event.keyCode == 38 || event.keyCode == 39 || event.keyCode == 40) {
            return;
        };
        if (text && text.length >= 3 && text != this.selectedText) {
            clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(() => {
                this.getSuggestions(text);
            }, 500);
        } else {
            clearTimeout(this.timeoutId);
        }
    }

    getSuggestions(text) {
        this.searchService.getSuggessions(text, this.request, this.selectedCategory.id).subscribe((res) => {
            if (typeof (res.data) == "string") {
                res.data = JSON.parse(res.data);
            }
            res.data.forEach(suggestion => {
                const title: string = suggestion.doc.productLevel2Title;
                const exp = new RegExp(text.toLowerCase(), 'g');
                const result = title.toLowerCase().replace(exp, "<span class='bold'>" + text + "</span>");
                suggestion.doc.productLevel2Title = result;
            });
            this.products = res.data;
        })

    }

    check(item) {
        const exp1 = new RegExp("<span class='bold'>", 'g');
        const exp2 = new RegExp("</span>", 'g');
        item.productLevel2Title = item.productLevel2Title.replace(exp1, '').replace(exp2, '');
        this.searchText = item.productLevel2Title;
        this.selectedText = item.productLevel2Title;
        this.search(this.searchText, item.baseCategory.id);
        // this.change.emit(this.searchText);
        this.showSearch = false;
    }

    keyEnter() {
        const exp1 = new RegExp("<span class='bold'>", 'g');
        const exp2 = new RegExp("</span>", 'g');
        let category = '';
        if (this.searchText.productLevel2Title) {
            category = this.searchText.baseCategory.id;
            this.searchText = this.searchText.productLevel2Title.replace(exp1, '').replace(exp2, '');
        }
        this.search(this.searchText, category);
        // this.change.emit(this.searchText);
        this.isTrue = false;
        this.showSearch = false;
    }
    keydown() {
        this.isTrue = true;
    }

    get categories$(): Observable<{ id?: string, value?: string }[]> {
        return this.storeFrontService.categories$;
    }

    search(keyword: string, category?: string) {
        const search = {
            keyword: keyword,
            category: category || this.selectedCategory.id || ''
        }
        this.change.emit(search);
        const searchText = JSON.parse(localStorage.getItem('recentSearches') || '[]');
        if (!searchText.includes(this.searchText)) {
            if (searchText.length == 6) {
                searchText.splice(0, 1);
            }
            searchText.push(this.searchText.toLowerCase());
            localStorage.setItem('recentSearches', JSON.stringify(searchText));
        }
    }

    private initSuggestedCategory() {
        this.suggestedCategory = [
            'Girijan Honey',
            'Pickles',
            'Karam',
            'Coffee',
            'Herbal Soaps',
            'Turmeric',
            'Pulses'
        ]
    }
}