/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notifier.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../notify/notify.component.ngfactory";
import * as i3 from "../notify/notify.component";
import * as i4 from "@angular/common";
import * as i5 from "./notifier.component";
import * as i6 from "../../services/app-notifier.service";
var styles_NotifierComponent = [i0.styles];
var RenderType_NotifierComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotifierComponent, data: { "animation": [{ type: 7, name: "notify-anim", definitions: [{ type: 0, name: "void", styles: { type: 6, styles: { opacity: 0, transform: "translateY(-25px)" }, offset: null }, options: undefined }, { type: 1, expr: "* <=> void", animation: [{ type: 4, styles: null, timings: "200ms ease-in-out" }], options: null }], options: {} }] } });
export { RenderType_NotifierComponent as RenderType_NotifierComponent };
function View_NotifierComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "notify", [], [[24, "@notify-anim", 0]], null, null, i2.View_NotifyComponent_0, i2.RenderType_NotifyComponent)), i1.ɵdid(2, 114688, null, 0, i3.NotifyComponent, [], { notification: [0, "notification"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 1, 0, currVal_0); }); }
export function View_NotifierComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "notify-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_NotifierComponent_1)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.notifications$)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_NotifierComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "notifier", [], null, null, null, View_NotifierComponent_0, RenderType_NotifierComponent)), i1.ɵdid(1, 114688, null, 0, i5.NotifierComponent, [i6.AppNotifierService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotifierComponentNgFactory = i1.ɵccf("notifier", i5.NotifierComponent, View_NotifierComponent_Host_0, {}, {}, []);
export { NotifierComponentNgFactory as NotifierComponentNgFactory };
