import * as i0 from "@angular/core";
var SmartsuppService = /** @class */ (function () {
    function SmartsuppService() {
    }
    /**
     * To open smart-support chat window
     */
    SmartsuppService.prototype.openChatWindow = function () {
        window.smartsupp('chat:open');
    };
    /**
     * To minimize smart-support chat window
     */
    SmartsuppService.prototype.closeChatWindow = function () {
        window.smartsupp('chat:close');
    };
    /**
     * To hide smart-support app from view
     */
    SmartsuppService.prototype.hideSmartSupport = function () {
        try {
            var chatWindow = document.getElementById('chat-application');
            chatWindow.style.visibility = 'hidden';
        }
        catch (e) { }
    };
    /**
     * To show smart-support in view
     */
    SmartsuppService.prototype.showSmartSupport = function () {
        try {
            var chatWindow = document.getElementById('chat-application');
            chatWindow.style.visibility = 'visible';
        }
        catch (e) { }
    };
    /**
     * To set chat window color
     *
     * @param colorHashCode in #code format
     */
    SmartsuppService.prototype.setThemeColor = function (colorHashCode) {
        if (colorHashCode === void 0) { colorHashCode = '#33da58'; }
        window.smartsupp('theme:colors', {
            widget: colorHashCode,
            primary: colorHashCode
        });
    };
    /**
     * To initialize the smartsupp window
     *
     * Also it is required put below code in index.html to register smartsupp in application
     *
     * ------------------------------
     *
     *    <script type="text/javascript">
     *      var _smartsupp = {}
     *      var smartsupp;
     *    </script>
     *
     * ---------------------------------
     */
    SmartsuppService.prototype.registerSmartSupport = function () {
        _smartsupp = {
            key: 'fb35f3da74fb5f8cbbd3b0bf8ecf4e6d04a93845'
        };
        var chatBoxRightDistance = 15; // window.innerWidth > 599 ? 48 : 15;
        _smartsupp.widget = 'button';
        _smartsupp.offsetX = chatBoxRightDistance;
        _smartsupp.triggerable = true;
        window.smartsupp || (function (d) {
            var scripts, node, app = window.smartsupp = function () { app._.push(arguments); };
            app._ = [];
            scripts = d.getElementsByTagName('script')[0];
            node = d.createElement('script');
            node.type = 'text/javascript';
            node.charset = 'utf-8';
            node.async = true;
            node.src = '//www.smartsuppchat.com/loader.js?';
            scripts.parentNode.insertBefore(node, scripts);
        })(document);
        this.setThemeColor();
    };
    SmartsuppService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SmartsuppService_Factory() { return new SmartsuppService(); }, token: SmartsuppService, providedIn: "root" });
    return SmartsuppService;
}());
export { SmartsuppService };
