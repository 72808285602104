<header>

    <!-- Web header -->
    <ng-container *fxIf="'gt-xs'">
        <div class="header--1" fxHide.xs>

            <div class="app-container">
                <div fxLayoutAlign="space-between center">
                    <ul>
                        <li>
                            <a>
                                <i class="fa fa-envelope mr-5"></i><span>andhragreens@kalgudi.com</span>
                            </a>
                        </li>
                        <li>
                            <a>
                                <ng-container *ngIf="selectedLanguage !=='te'">Free shipping on eligible orders over {{ 999 | currency:'INR' }}</ng-container>
                                <ng-container *ngIf="selectedLanguage ==='te'">999 కంటే ఎక్కువ అర్హత గల ఆర్డర్‌లపై ఉచిత షిప్పింగ్
                                </ng-container>

                            </a>
                        </li>
                    </ul>

                    <ul>

                        <li class="k-dropdown" fxLayoutAlign="center center">

                            <a>
                                <span> {{availableLanguage[selectedLanguage]}} </span>
                                <i class="fa fa-caret-down ml-5" aria-hidden="true"></i>
                            </a>

                            <div class="k-dropdown-container">
                                <div class="k-drop-item" (click)="switchLanguage('en')">English</div>
                                <div class="k-drop-item" (click)="switchLanguage('te')">తెలుగు</div>
                            </div>
                        </li>

                        <li>
                            <a routerLink="/about-us" translate>
                                About us
                            </a>
                        </li>
                        <li>
                            <a routerLink="/account/my-orders" translate>
                                Track order
                            </a>
                        </li>

                        <ng-container *ngIf="isUserLoggedIn; then loggedIn; else loggedOut"></ng-container>
                        <ng-template #loggedOut>
                            <li>
                                <a routerLink="/auth/login" translate>
                                    <i class="fa fa-user-circle mr-5" aria-hidden="true"></i>
                                    <span translate>Sign In</span>
                                </a>
                            </li>
                        </ng-template>
                        <ng-template #loggedIn>
                            <li class="k-dropdown" fxLayoutAlign="center center">

                                <a [title]="loggedInUserName" routerLink="/account/profile">
                                    <span class="username"> {{ loggedInUserName }} </span>
                                    <i class="fa fa-caret-down ml-5" aria-hidden="true"></i>
                                </a>

                                <div class="k-dropdown-container">
                                    <div class="k-drop-item">
                                        <a [routerLink]="['/account/profile']" translate>My account</a>
                                    </div>

                                    <div class="k-drop-item" *ngIf="isKalgudiTeam">
                                        <a href="/admin" target="_blank" translate>Dashboard</a>
                                    </div>

                                    <div class="k-drop-item" *ngIf="isKalgudiTeam">
                                        <span fxLayout fxLayoutAlign="space-between">
                                          <span class="one-line-text" translate>Quick links</span>
                                        <i class="fa fa-angle-right" aria-hidden="true" fxFlexAlign="center"></i>
                                        </span>
                                        <div class="sub-dropdown">
                                            <div class="items-container">
                                                <a class="k-drop-item" href="/admin/#/app/requirements/grid" target="_blank" translate>RFQs</a>
                                                <a class="k-drop-item" href="/admin/#/app/orders" target="_blank" translate>Orders</a>
                                                <a *ngIf="canAccessSupportPage" class="k-drop-item" routerLink="/support/orders" target="_blank">Fulfillment</a>

                                            </div>
                                        </div>
                                    </div>


                                    <div class="k-drop-item" (click)="logout()" translate>Logout</div>
                                </div>
                            </li>
                        </ng-template>



                    </ul>
                </div>
            </div>


            <div *ngIf="false" [class.app-container]="!isAdminPage" [class.px-16]="isAdminPage">
                <div class="position-relative" fxLayoutAlign="space-between center">
                    <ng-container *ngIf="!isAdminPage">
                        <a [routerLink]="[ '/' ]">
                            <img height="40" [src]="'assets/apgreen/logo-500.png' | scaleImage:'format'" alt="Logo">
                        </a>
                        <div class="font-15" fxLayoutAlign="start center">
                            <span fxLayoutAlign="center center">
                                <span class="contact-no">
                                    <span class="fw-600">{{'For order' | translate}}: </span>
                            <span>(+91) 6309995777</span></span>
                            </span>
                        </div>
                    </ng-container>

                    <a [routerLink]="[ '/' ]" *ngIf="isAdminPage">
                        <img [src]="'assets/apgreen/logo-500.png' | scaleImage:'format' " width="200" alt="Logo">
                    </a>

                    <a class="store-admin-tag" *ngIf="isAdminPage">AP Store Admin</a>

                    <div class="font-14" fxLayoutAlign="start center">

                        <div class="k-dropdown">
                            <span>{{availableLanguage[selectedLanguage]}}</span>
                            <i class="fa fa-angle-down ml-3"></i>
                            <div class="k-dropdown-container">
                                <div class="k-drop-item" (click)="switchLanguage('en')">English</div>
                                <div class="k-drop-item" (click)="switchLanguage('te')">తెలుగు</div>
                            </div>
                        </div>

                        <div class="separator"></div>



                        <a routerLink="/about-us" translate>About us</a>
                        <div class="separator"></div>

                        <a routerLink="/account/my-orders" translate>Track Order</a>
                        <div class="separator"></div>

                        <!-- SignIn or Profile details  -->
                        <ng-container *ngIf="isUserLoggedIn; then loggedIn; else loggedOut"></ng-container>
                        <ng-template #loggedOut>
                            <a [routerLink]="['/auth/login']" translate>Sign In</a>
                        </ng-template>
                        <ng-template #loggedIn>
                            <div class="k-dropdown" fxLayoutAlign="center center">
                                <a class="username" [title]="loggedInUserName" routerLink="/account/profile">
                                    <span> {{ loggedInUserName }}</span>
                                </a>
                                <i class="fa fa-angle-down ml-3"></i>
                                <div class="k-dropdown-container">

                                    <div *ngIf="isKalgudiTeam">
                                        <div class="k-drop-item">
                                            <a [routerLink]="['/admin']" target="_blank" translate>Dashboard</a>
                                        </div>
                                    </div>
                                    <div *ngIf="canAccessSupportPage">
                                        <div class="k-drop-item">
                                            <a *ngIf="canAccessSupportPage" routerLink="/support/orders">Fulfillment</a>
                                        </div>
                                    </div>
                                    <div class="k-drop-item" (click)="logout()" translate>Logout</div>
                                </div>
                            </div>
                        </ng-template>
                        <!-- / SignIn or Profile details  -->

                    </div>
                </div>
            </div>
        </div>

        <div class="header--2" fxHide.xs *ngIf="!isAdminPage">
            <div class="app-container">
                <div fxLayoutAlign="space-between center">
                    <div fxLayoutAlign="start center" fxLayoutGap="30px">
                        <a [routerLink]="[ '/' ]" fxFlex="270px">
                            <img height="50" [src]="'assets/apgreen/logo-500.png' | scaleImage:'format'" alt="Logo">
                        </a>
                    </div>

                    <div fxLayoutAlign="start center">

                        <div class="icons" fxLayout="row" fxLayoutGap="20px">
                            <p class="m-0 mr-20 mt-2 fw-500" translate>Download APP</p>
                            <a [target]="'_blank'" href="https://play.google.com/store/apps/details?id=com.vasudhaika.andhragreens&hl=en">
                                <img src="assets/icons/google-play-store.svg" loading="lazy" width="20">
                            </a>
                            <a>
                                <img [src]="'assets/icons/apple-logo.png' | scaleImage:'format'" loading="lazy" width="25">
                            </a>
                        </div>
                    </div>

                    <cart-info class="d-flex" [cart]="cart" [cartQuantity]="productQuantityInCart" (deleteProduct)="deleteProduct($event)"></cart-info>
                </div>
            </div>
        </div>

        <div #headerSec [style.minHeight]="'67px'" fxHide.xs>
            <!-- Height here should be equal to height of header--3 section -->
            <div class="header--3 py-8" [class.collapsed]="(headerCollapseChange$ | async)" [class.full-view]="isProductFullView" *ngIf="!isAdminPage">
                <div class="app-container">
                    <div fxLayoutAlign="space-between center">

                        <div fxLayoutAlign="start center" fxLayoutGap="30px">
                            <div class="menu" fxLayout="row wrap">

                                <shop-by-category class="d-flex"></shop-by-category>

                                <!-- <shop-by-region class="d-flex"></shop-by-region> -->

                                <nested-menu class="d-flex"></nested-menu>

                                <ng-container *ngIf="false">
                                    <ng-container *ngFor="let category of categories$ | async">
                                        <a class="menu-item" [routerLink]="['/search', category?.value]" [queryParams]="{category: category?.id }" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" translate>{{ category?.value }}</a>
                                    </ng-container>
                                </ng-container>

                            </div>

                            <searchbar fxFlex="380px" [showSearch]="showSearch" (change)=search($event)></searchbar>
                        </div>

                        <div fxLayoutAlign="center center">
                            <div class="cc-no fw-600 mr-15" fxLayoutAlign="center center">
                                <i class="fa fa-phone mr-5 font-25" aria-hidden="true"></i>
                                <span class="font-20 fw-700 number">(+91) 6309995777</span>
                            </div>


                            <div class="del-loc-select-wrapper" [hidden]="(headerCollapseChange$ | async)">
                                <div class="del-loc-select" fxLayoutAlign="space-between center" fxLayoutGap="10px" (click)="showDeliveryLocationSelection = !showDeliveryLocationSelection">
                                    <i class="fa fa-map-marker"></i>
                                    <ng-container *ngTemplateOutlet="deliveryLocationSelectedTemplate"></ng-container>
                                    <i class="fa fa-angle-down"></i>
                                </div>

                                <div class="loc-select" [class.show]="showDeliveryLocationSelection">
                                    <div class="loc-select-container">
                                        <div class="scrollable">
                                            <delivery-pincode-selector [isUserLoggedIn]="isUserLoggedIn" (onChange)="showDeliveryLocationSelection = false">
                                            </delivery-pincode-selector>
                                        </div>
                                    </div>
                                </div>

                                <ng-container *ngTemplateOutlet="deliveryLocationChangedDialog"></ng-container>
                            </div>

                            <cart-info fxLayout [cart]="cart" [cartQuantity]="productQuantityInCart" (deleteProduct)="deleteProduct($event)" [hidden]="!(headerCollapseChange$ | async)">
                            </cart-info>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <!-- / Web header -->

    <!-- Mobile header -->
    <ng-container *fxIf="'xs'">
        <div class="m-header" [class.collapsed]="(mobileHeaderCollapse$ | async)">
            <div class="m-header--1" fxLayoutAlign="start center">
                <mat-icon class="menu" fxLayoutAlign="center center" (click)="showSideNav = true">menu</mat-icon>

                <a class="ml-5" [routerLink]="[ '/' ]" fxLayoutAlign="start center">
                    <img width="35" [src]=" 'assets/apgreen/ag-logo2500.png' | scaleImage: 'format' " alt="Logo">

                    <ng-container *ngIf="!homePage">
                        <span class="font-18 fw-600 ml-10">Andhragreens</span>
                    </ng-container>

                </a>

                <div class="del-location ml-15" *ngIf="homePage" (click)="showDeliveryLocationSelection = !showDeliveryLocationSelection">
                    <ng-container *ngTemplateOutlet="deliveryLocationSelectedTemplate"></ng-container>
                    <i class="fa fa-map-marker ml-10"></i>
                </div>

                <div fxFlex></div>

                <a (click)="showSearch = true">
                    <div class="p-10 search-label-icon" (click)="showSearch = false">
                        <i class="fa fa-search"></i>
                    </div>
                </a>

                <cart-info [cart]="cart" [cartQuantity]="productQuantityInCart"></cart-info>
            </div>

            <div class="m-header--2 text-white">

                <div class="searchbar-container" (click)="showSearch = true">
                    <div class="searchbar" (click)="showSearch = false">
                        <i class="fa fa-search"></i>
                        <span>Search across our exclusive range...</span>
                    </div>
                </div>

            </div>

            <ng-container *ngTemplateOutlet="deliveryLocationChangedDialog"></ng-container>

            <div *ngIf="showSearch">
                <searchbar [showSearch]="showSearch" (change)=search($event) fxFlex></searchbar>
            </div>
        </div>

        <div class="del-location-selector" [hidden]="!showDeliveryLocationSelection">

            <div class="del-location-selection-header" fxLayoutAlign="start center">
                <div class="p-10 pl-15" fxLayout (click)="showDeliveryLocationSelection = false">
                    <mat-icon>arrow_back</mat-icon>
                </div>
                <div fxFlex></div>
                <span class="font-20 mr-30">Choose Delivery Location</span>
                <div fxFlex></div>
            </div>

            <delivery-pincode-selector [isUserLoggedIn]="isUserLoggedIn" (onChange)="showDeliveryLocationSelection = false">
            </delivery-pincode-selector>

        </div>

        <!-- Mobile sidenav -->
        <mobile-sidenav [showSideNav]="showSideNav" [userData]="userData" [isUserLoggedIn]="isUserLoggedIn" (logout)="logout()" (toggle)="showSideNav = !showSideNav">
        </mobile-sidenav>
    </ng-container>
    <!-- / Mobile header -->

</header>



<ng-template #deliveryLocationSelectedTemplate>
    <ng-container *ngIf="(deliveryLocationSelected$ | async) as deliveryLocationSelected; else noDeliveryLocationSelected">
        <span>{{ deliveryLocationSelected?.postalCode ?  (deliveryLocationSelected?.city+' - '+deliveryLocationSelected?.postalCode)  : deliveryLocationSelected}}</span>
    </ng-container>
    <ng-template #noDeliveryLocationSelected>
        <span>Choose location</span>
    </ng-template>
</ng-template>

<ng-template #deliveryLocationChangedDialog>
    <ng-container *ngIf="shippingMsgDialog | async">
        <div @notifyAnimation class="loc-selected-dialog">
            <div class="loc-selected-dialog-container">
                <span>
                    {{ shippingMessage }}
                </span>
                <mat-icon (click)="shippingMsgDialog.next(false)">close</mat-icon>
            </div>
        </div>
    </ng-container>
</ng-template>