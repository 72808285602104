import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { DeliveryAddress } from '../../models/shipping-form.model';
import { ProfileService } from 'src/app/profile/profile.service';
import { DeliveryAddressService } from '../../services/delivery-address.service';
import { tap, map } from 'rxjs/operators';
import { UtilityService } from '../../services/utility.service';

@Component({
  selector: 'delivery-pincode-selector',
  templateUrl: './delivery-pincode-selector.component.html',
  styleUrls: ['./delivery-pincode-selector.component.scss']
})
export class DeliveryPincodeSelectorComponent implements OnInit {

  @Input() isUserLoggedIn: boolean;

  deliveryPincodeInput = new FormControl('', [Validators.minLength(6)]);
  addresses$: Observable<DeliveryAddress[]>;
  deliveryLocationSelected$: Observable<DeliveryAddress | string>;

  @Output() onChange = new EventEmitter();

  constructor(
    private profileService: ProfileService,
    private deliveryAddressService: DeliveryAddressService,
    private util: UtilityService
  ) { }

  ngOnInit() {
    this.addresses$ = this.profileService.getSavedAddresses().pipe(map(res => JSON.parse(res.data)));

    this.deliveryLocationSelected$ = this.deliveryAddressService.deliveryLocationSelected$
      .pipe(
        tap((address: DeliveryAddress) => this.deliveryPincodeInput.setValue(address.postalCode || address))
      );
  }

  setDeliveryAddress(address: DeliveryAddress | string) {
    this.deliveryAddressService.setDeliveryAddress(address);

    this.onChange.emit();
  }

  setPincode() {
    if (!this.deliveryPincodeInput.valid) return;

    this.setDeliveryAddress(this.deliveryPincodeInput.value);
  }

  public isNumber(event: KeyboardEvent) {
    return this.util.numberOnly(event);
  }
}
