import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var DeliveryAddressService = /** @class */ (function () {
    function DeliveryAddressService() {
        this.deliveryLocationLocalStorageKey = 'deliveryLocationSelected';
        this.pincodeLocalStorageKey = 'pin';
        this.deliveryLocationSelectedSubject = new BehaviorSubject('');
        this.deliveryLocationSelected$ = this.deliveryLocationSelectedSubject.asObservable();
        var deliveryLocationSelected = '';
        try {
            deliveryLocationSelected = (JSON.parse(localStorage.getItem(this.deliveryLocationLocalStorageKey) || null)
                || localStorage[this.pincodeLocalStorageKey]
                || '');
        }
        catch (e) {
            console.warn("Failed to parse existing pin");
        }
        this.deliveryLocationSelectedSubject.next(deliveryLocationSelected);
    }
    DeliveryAddressService.prototype.setDeliveryAddress = function (address) {
        this.deliveryLocationSelectedSubject.next(address);
        var delAddress = (typeof address === 'string') ? address : JSON.stringify(address);
        localStorage[this.deliveryLocationLocalStorageKey] = delAddress;
        if (address)
            localStorage[this.pincodeLocalStorageKey] = address.postalCode || address;
    };
    DeliveryAddressService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DeliveryAddressService_Factory() { return new DeliveryAddressService(); }, token: DeliveryAddressService, providedIn: "root" });
    return DeliveryAddressService;
}());
export { DeliveryAddressService };
