<ng-container>
    <!-- <div
    fxHide.xs
    [hidden]="hideRequirement"
    class="requirement"
    [routerLink]="['/quotation', 'send']"
  >
  <div class="icon">
    <fa name="leaf"></fa>
  </div>
  <span>Requirement</span>
  </div> -->

    <!-- <div class="side_link prescription">
    <span class="side_link_text" routerLink="/order-by-prescription"
      >Prescription</span
    >
    <div class="icon">
      <fa name="address-book"></fa>
    </div>
  </div> -->
</ng-container>
<div class="side-links" fxLayout="column" fxLayoutGap="20px" fxLayoutGap.xs="10px" fxLayoutAlign="flex-end" id="backToTop" *ngIf="!isHome">
    <div class="goTop side_link back-to-top" [class.visible]="showBackToTopBtn" (click)="scrollTop()">
       <!-- <span class="side_link_text" translate>Back to top</span>-->
        <div class="icon" fxLayoutAlign="center center">
            <fa name="angle-up"></fa>
        </div>
    </div>

    <!-- <div
    class="chat side_link"
    title="Live chat"
    data-api="smartsupp"
    data-operation="open"
    id="custome-smartsupp-icon"
    fxHide
    fxShow.xs
  >
    <span class="side_link_text">Chat</span>
    <div class="icon">
      <fa name="comments"></fa>
    </div>
  </div> -->
</div>