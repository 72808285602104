import { UtilityService } from './utility.service';
import { WishListService } from './wish-list.service';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { Injectable, Output } from '@angular/core';
import { EventEmitter } from 'events';
import { ToasterService } from './toaster.service';
import { MatSnackBar } from '@angular/material';
import { HttpWithAuthService } from './http-interceptor.service';
import { environment } from '../../../environments/environment';
import { map, finalize } from 'rxjs/operators';
import { GoogleAnalyticsService } from './google-analytics.service';
import { Storefront } from '../properties/constants';
import { Router } from '@angular/router';
import { StorefrontService } from 'src/app/storefront/storefront.service';
import { SpinnerService } from './spinner.service';
import { CartProduct, Cart } from '../models/cart-product.model';
import { constants } from 'os';
import { TranslateService } from '@ngx-translate/core';
import { AppNotify, AppNotifierService } from './app-notifier.service';
import { ScaleImagePipe } from '../pipes/scale-image.pipe';


@Injectable({
  providedIn: 'root'
})
export class CartService {

  cart: Cart;

  showToast = false;

  isLoggedIn: boolean;

  private cartValueChangeSubject = new Subject<any>();
  cartValueChanges = this.cartValueChangeSubject.asObservable();

  public cart$: BehaviorSubject<Cart>;

  constructor(
    private localStorage: LocalStorage,
    private toaster: ToasterService,
    private snackbar: MatSnackBar,
    private http: HttpWithAuthService,
    private util: UtilityService,
    private wishListService: WishListService,
    private gaTrack: GoogleAnalyticsService,
    private router: Router,
    private store: StorefrontService,
    private translator: TranslateService,
    private notifier: AppNotifierService,
    private spinner: SpinnerService
  ) {

    this.cart = {
      productIds: [],
      products: []
    };
    this.cart$ = new BehaviorSubject<Cart>(this.cart);

    this.isLoggedIn = JSON.parse(localStorage['YUdGelRHOW5aMlZrU1c0PQ=='] || 'false');

    /* this.addProductToCartById(`03fg`,`03gi`); */
  }


  /**
   * To subscribe to latest cart data
   */
  public cartUpdates(): Observable<Cart> {
    return this.cart$.asObservable();
  }

  dispatchCartUpdate() {
    this.cart$.next(this.cart);
    setTimeout(() => {
      this.cartValueChangeSubject.next();

      this.getCartData().subscribe(cartData => {
        cartData ? this.cart = cartData : this.setCartData();
      });
    }, 300);
  }

  getCartData(): Observable<Cart> {
    return this.localStorage.getItem('cart');
  }

  setCartData(cartData?: Cart) {
    this.localStorage.setItemSubscribe('cart', cartData || this.cart);
  }

  removeCartData() {
    this.localStorage.setItemSubscribe('cart', this.cart);
  }

  addProductToCart(product, quantity?, showSnackbar = false) {

    /* if (product.mrp <= 0) {
      this.snackbar.open(`Selected product price is invalid`, '', {
        duration: 2000,
      });
      return false;
    } */

    this.gaTrack.updateAddToCartToAnalytics(product);

    if (this.isForBulkOrder(product)) {
      this.processForBulkOrder(product);
      return false;
    }
    this.getCartData().subscribe(cartData => {
      let isItemAlreadyInCart = false;
      let shouldShowSuccessMessage = true;
      for (const i in cartData.productIds) {
        if (cartData.productIds[i] === product.productLevel3Id) {
          /* this.translator.get([`Selected product is already there in cart.`]).subscribe(translatedText => {
            this.snackbar.open(translatedText[`Selected product is already there in cart.`], '', {
              duration: 2000, panelClass: ['snakbar-color']
            });
          })
          this.showToast = false;
          return; */
          isItemAlreadyInCart = true;
          const totalQuantity = parseInt(cartData.products[i].productQuantity + '') + parseInt(quantity);
          if (totalQuantity < 11) {
            cartData.products[i].productQuantity = totalQuantity;
          } else {
            shouldShowSuccessMessage = false;
            cartData.products[i].productQuantity = 10;
            this.translator.get('Maximum 10 units can be ordered  ').subscribe(text => {
              this.snackbar.open(text, 'OK', {
                duration: 8000
              });
            })
          }
        }
      }
      if (cartData.productIds.length < 50) {
        if (!isItemAlreadyInCart) {
          product.cartUTS = new Date().toISOString();
          cartData.productIds.push(product.productLevel3Id);
          cartData.products.push(product);
        }
        this.cart = cartData;
        /* console.log(JSON.stringify(cartData)); */
        let items = {
          productIds: [product.productLevel3Id],
          products: [product]
        }
        if (!this.isLoggedIn) {
          this.setCartData(cartData);
          this.dispatchCartUpdate();
        }
        this.putItemsInCart(items);
        if (shouldShowSuccessMessage) {
          this.showSucessfullMessage(product, showSnackbar);
        }
      } else {
        this.translator.get(['Not allowed', 'Maximum 50 products can be ordered with one transaction', 'info']).subscribe(translatedText => {
          this.toaster.showMessage(translatedText['Not allowed'], translatedText['Maximum 50 products can be ordered with one transaction'], translatedText['info']);
        })
      }
    });
    return true;
  }

  private isForBulkOrder(product) {
    let isForBulkOrder = false;
    this.cart.products.forEach(item => {
      if (item.isBulkOrder) {
        isForBulkOrder = true;
      }
    });
    return isForBulkOrder || product.isBulkOrder;
  }

  private processForBulkOrder(product) {

    if (!product.isBulkOrder) {
      this.translator.get(['Normal products can not be ordered along with bulk orders', 'OK']).subscribe(translatedText => {
        this.snackbar.open(translatedText['Normal products can not be ordered along with bulk orders'], translatedText['OK'], {
          duration: 8000, panelClass: ['snakbar-color']
        })
      });
      return;
    }
    this.getCartData().subscribe(cart => {
      if (!cart.products.length) {
        this.saveProductForBulkOrderToCart(product);
      } else {
        this.processAvailableProductsOnCart(cart, product);
      }
    });
  }

  private saveProductForBulkOrderToCart(product) {
    let cart;
    if (this.cart.products.length && this.cart.products[0].isBulkOrder) {
      cart = this.cart;
      cart.products.push(product);
      cart.productIds.push(product.productLevel3Id);
    } else {
      cart = {
        products: [product],
        productIds: [product.productLevel3Id]
      }
    }
    this.cart = cart;
    if (!this.isLoggedIn) {
      this.setCartData(cart);
      this.dispatchCartUpdate();
    }
    this.putItemsInCart(cart);
  }

  private processAvailableProductsOnCart(cart, product) {
    let isForBulkOrder = true;
    cart.products.forEach(item => {
      if (item.productLevel3Id === product.productLevel3Id) {
        this.translator.get(['Selected product is already added to cart']).subscribe(translatedText => {
          this.snackbar.open(translatedText['Selected product is already added to cart'], 'OK', {
            duration: 6000, panelClass: ['snakbar-color']
          })
        });
        isForBulkOrder = false;
        return;
      } else if (item.isBulkOrder) {
        // this.saveProductForBulkOrderToCart(product);
        isForBulkOrder = true;
        return;
      }
      // this.persistTheCart({'productId': item.productLevel3Id, 'destination': 'SAVED_ITEM'});
      this.translator.get(['Other products from cart have been saved for later', 'OK']).subscribe(translatedText => {
        this.snackbar.open(translatedText['Other products from cart have been saved for later'], translatedText['OK'], {
          duration: 6000, panelClass: ['snakbar-color']
        });
      })
    });
    if (isForBulkOrder && product.isBulkOrder) {
      this.saveProductForBulkOrderToCart(product);
    }
  }

  private showSucessfullMessage(product = null, showSnackbar = false) {
    if (this.util.isMobileDevice() || showSnackbar) {
      this.translator.get([`Sucessfully added to cart`]).subscribe(translatedText => {
        this.snackbar.open(translatedText[`Sucessfully added to cart`], '', {
          duration: 1000, panelClass: ['snakbar-color']
        });
      })
      return;
    }

    if (product) {
      const notification: AppNotify = {
        title: '1 Item Added to Cart!',
        linkTitle: 'View cart',
        imageUrl: ScaleImagePipe.transform(product.productImage, 'sm'),
        message: product.productLevel3Title,
        linkRoute: ['/checkout/cart']
      }
      this.notifier.pushNotification(notification);
    }

    this.showToast = true;
    setTimeout(() => {
      this.showToast = false;
    }, 6000);
  }

  removeProductFromCart(product: CartProduct, ignoreAnalyticsUpdate?: boolean) {
    if (!ignoreAnalyticsUpdate)
      this.gaTrack.updateRemoveFromCartToAnalytics(product);
    if (JSON.parse(localStorage['YUdGelRHOW5aMlZrU1c0PQ=='] || 'false')) {
      this.removeItemsFromCart([product.productLevel3Id]);
      // this.persistTheCart({'productId':product.productLevel3Id,'source':'CART_ITEM'});
    } else {
      const index = this.cart.productIds.indexOf(product.productLevel3Id)
      if (index > -1) {
        this.cart.products.splice(index, 1);
        this.cart.productIds.splice(index, 1);
        this.setCartData(this.cart);
        this.dispatchCartUpdate();
      }
    }
  }

  batchRemoveFromCart(productIds): Observable<any> {
    const request = {
      productIds: productIds
    }
    return this.http.put(`${environment.nodeServer}/rest/v2/bizstore/cart/batch?store=${Storefront.APP_NAME}`, request).pipe(map(resposne => {
      const result: any = resposne;
      if (result.code === 200) {
        this.cart = result.data.cartItems;
        this.setCartData(this.cart);
        this.dispatchCartUpdate();
      }
      return result;
    }));
  }

  updateQuantity(product, quantity) {
    if (this.cart.productIds.length) {
      for (const i in this.cart.productIds) {
        if (this.cart.productIds[i] === product.productLevel3Id) {
          product.productQuantity = quantity;
          product.store = Storefront.APP_NAME;
          this.cart.products[i] = product;
          this.setCartData(this.cart);
          // this.persistTheCart(this.cart);
          let items = {
            productIds: [this.cart.productIds[i]],
            products: [product]
          }
          this.putItemsInCart(items);
          this.dispatchCartUpdate();
          break;
        }
      }
    }
  }

  increaseQuantity(productLevel3Id) {
    if (this.cart.productIds.length) {
      for (const i in this.cart.productIds) {
        if (this.cart.productIds[i] === productLevel3Id) {
          const product = this.cart.products[i];
          product.productQuantity++;
          product.store = Storefront.APP_NAME;
          this.cart.products[i] = product;
          this.setCartData(this.cart);
          // this.persistTheCart(this.cart);
          let items = {
            productIds: [this.cart.productIds[i]],
            products: [product]
          }
          this.putItemsInCart(items);
          this.dispatchCartUpdate();
          break;
        }
      }
    }
  }

  decreaseQuantity(product) {
    if (this.cart.productIds.length) {
      for (let i = 0; i < this.cart.productIds.length; i++) {
        if (this.cart.productIds[i] === product.productLevel3Id) {
          product.productQuantity--;
          product.store = Storefront.APP_NAME;
          let items = {
            productIds: [this.cart.productIds[i]],
            products: [product]
          }
          if (product.productQuantity >= 1) {
            if (!this.isLoggedIn) {
              this.cart.products[i] = product;
              this.setCartData(this.cart);
            }
            this.putItemsInCart(items);
          } else {
            if (!this.isLoggedIn) {
              this.cart.products.splice(i, 1);
              this.cart.productIds.splice(i, 1);
              this.setCartData(this.cart);
              this.dispatchCartUpdate();
            }
            this.removeItemsFromCart([this.cart.productIds[i]]);
          }
          // this.persistTheCart(this.cart);
          this.dispatchCartUpdate();
          break;
        }
      }
    }
  }

  getPersistedCartData() {
    if (this.cart.productIds.length) {
      this.putCartAfterLogin(this.cart);
    } else {
      this.getCartItem().subscribe(response => {
        if (response.code == 200 || response.code == 204) {
          const items = response.data || {};
          this.setCartData(items.cartItems);
          this.dispatchCartUpdate();
          this.wishListService.setSaveProductInLocalDb(items.savedItems);
        }
      });
    }
  }

  prepareReqCartObj() {
    var reqObj;
    if (this.cart.productIds.length) {
      reqObj = {
        "data": this.cart,
      }
      return reqObj;
    }
    return reqObj;
  }



  persistTheCart(cart?: any) {
    if (JSON.parse(localStorage['YUdGelRHOW5aMlZrU1c0PQ=='] || 'false')) {
      const url = `${environment.nodeServer}/rest/v2/bizstore/cart?store=${Storefront.APP_NAME}`;
      this.spinner.changeStatus(true, false);
      this.http.put(url, cart || { 'data': this.cart, 'destination': 'CART_ITEM' })
        .pipe(finalize(() => this.spinner.stop()))
        .subscribe(data => {
          const response: any = data;
          if (response.code === 200 && response.data) {
            const items = (response.data);
            this.setCartData(items.cartItems);
            this.dispatchCartUpdate();
            this.wishListService.setSaveProductInLocalDb(items.savedItems);
            this.wishListService.dispatchUpdate('true');
          }
        });
    }
  }

  getProcessedLevel3Product(level3Product, level2Product) {

    if (!level3Product.pricePerUnit) {
      return;
    }
    const seller = level2Product.resellerDetails || level2Product.manufacturerDetails;
    level3Product.sellerId = seller.profileKey;
    level3Product.sellerName = seller.firstName;
    level3Product.sellerProfileUrl = '';
    level3Product.store = Storefront.APP_NAME;
    level3Product.productCategory = level2Product.baseCategory.id;
    if (level3Product.handlingCost) {
      level3Product.handlingCost = parseFloat(level3Product.handlingCost);
    }
    if (level3Product.packagingCost) {
      level3Product.packagingCost = parseFloat(level3Product.packagingCost);
    }
    if (level3Product.shippingCost) {
      level3Product.shippingCost = parseFloat(level3Product.shippingCost);
    }

    try {
      // level3Product.pricePerUnit = level3Product.productLevel2Id === '332e' ? '500' : level3Product.pricePerUnit;
      level3Product.pricePerUnit = parseFloat(level3Product.pricePerUnit);
    } catch (e) {
      /* console.log('Invalid price'); */
      return;
    }

    delete level3Product.LUT;
    delete level3Product.attachments;
    delete level3Product.color;
    delete level3Product.createdTS;
    delete level3Product.s3Path;
    delete level3Product.shape;
    delete level3Product.size;
    delete level3Product.availableSmartElement;
    delete level3Product.displayName;
    delete level3Product.weight;
    return level3Product;
  }

  /**
   * Get the cart items of logedIn user
   */
  getCartItem(): Observable<any> {
    const url = `${environment.nodeServer}/rest/v2/bizstore/cart?store=${Storefront.APP_NAME}`;
    return this.http.get(url).pipe(map(response => {
      return response;
    }, error => {
      console.error(error);
      throw new Error('Unable to get cart items');
    }));
  }

  putCartAfterLogin(cartData) {
    let req = {
      data: cartData
    }
    this.persistTheCart(req);
  }

  /**
   * Put items in cart
   * @param cartData obj in form { products : [], productIds : [] }
   */
  putItemsInCart(cartData) {
    let req = {
      data: cartData,
      destination: 'CART_ITEM'
    }
    this.persistTheCart(req);
  }

  /**
   * Put items in savedList
   * @param cartData obj in form { products : [], productIds : [] }
   */
  putItemsInSavedList(cartData) {
    let req = {
      data: cartData,
      destination: 'SAVED_ITEM'
    }
    this.persistTheCart(req);
  }

  /**
   * Move items in cart from saved list
   * @param productIds Array of productIds
   */
  moveItemsIncart(productIds) {
    let req = {
      productIds: productIds,
      destination: 'CART_ITEM'
    }
    this.persistTheCart(req);
  }

  /**
   * Move items in saved list from cart
   * @param productIds Array of productIds
   */
  moveItemsInSavedList(productIds) {
    let req = {
      productIds: productIds,
      destination: 'SAVED_ITEM'
    }
    this.persistTheCart(req);
  }

  /**
   * Remove items from cart
   * @param productIds Array of productIds
   */
  removeItemsFromCart(productIds) {
    let req = {
      productIds: productIds,
      source: 'CART_ITEM'
    }
    this.persistTheCart(req);
  }

  /**
   * Remove items from savedList
   * @param productIds Array of productIds
   */
  removeItemsFromSavedList(productIds) {
    let req = {
      productIds: productIds,
      source: 'SAVED_ITEM'
    }
    this.persistTheCart(req);
  }

  hasValidSmartElementAndPrice(product) {
    if (!product.pricePerUnit) {
      return false
    }
    const p = product.smartElements;
    if (!p.colour && !p.weight && !p.sizeByName && !p.sizeByNumber && !p.packagingType && !p.itemType &&
      !p.shape && !p.patterAndDesign && !this.hasSomeDimension(p.dimension) && !this.hasSomeDimension(p.packagingDimension) && !p.materialAndFabric && !p.itemCount) {
      return false;
    }
    return true;
  }

  hasSomeDimension(dimension: any = {}) {
    return dimension.height || dimension.length || dimension.width;
  }

  /**
   * Add items in cart from custom packs page
   * AND delete existing item in cart
   * Don't try with bulk SKU
   * @param {any} products processed l3 data for cart
   */
  addItemInCartFromCustomPackPage(products, isBuyNow) {

    if (products.length >= Storefront.MAX_CART_LIMIT) {
      this.snackbar.open(`Max cart limit is only ${Storefront.MAX_CART_LIMIT}`, '', {
        duration: 2000, panelClass: ['snakbar-color']
      });
      this.showToast = false;
      return;
    }

    /**
     * Need to override the existing cart.
     */
    let cartData = {
      productIds: [],
      products: []
    }

    products.forEach(p => {
      if (p.productQuantity > 0) {
        p.cartUTS = JSON.stringify(new Date());
        cartData.productIds.push(p.productLevel3Id);
        cartData.products.push(p);
      }
    });

    this.cart = cartData;

    if (!this.isLoggedIn) {
      this.setCartData(cartData);
      this.dispatchCartUpdate();
    }
    /**
     * Move the existing item in saved list
     */
    this.putCartAfterLogin(this.cart);
    this.showSucessfullMessage();
    if (isBuyNow) {
      this.router.navigate(['/checkout', 'cart']);
    }
    /* 
    this.getCartData().subscribe(cartData => {

      if ((products.length + cartData.productIds) > Storefront.MAX_CART_LIMIT) {
        this.snackbar.open(`${cartData.productIds.length} items already available in cart, you are trying to add ${products.length} more items in cart,max limit is ${Storefront.MAX_CART_LIMIT}`, '', {
          duration: 2000,
        });
        this.showToast = false;
        return;
      }
    }); */
  }

  /**
   * To add product in cart by taking l2Id and l3Id
   * @param l2Id 
   * @param l3Id 
   */
  addProductToCartById(l2Id, l3Id) {
    this.getCartData().subscribe(cartData => {
      if (cartData && cartData.productIds && cartData.productIds.includes(l3Id)) {
        this.increaseQuantity(l3Id)

        const index = cartData.productIds.indexOf(l3Id);
        this.showSucessfullMessage(cartData.products[index]);

        // this.snackbar.open(`Selected product is already there in cart.`, '', {
        //   duration: 2000, panelClass: ['snakbar-color']
        // });
        // this.showToast = false;
        // return;
      } else {
        this.store.getFullViewData(l2Id).subscribe(level2Data => {
          level2Data.level3ProductsList.forEach(level3Product => {
            if (level3Product.productLevel3Id === l3Id) {
              let attachments = level3Product.attachments && level3Product.attachments.attachments && level3Product.attachments.attachments.length ? level3Product.attachments.attachments : [{ url: 'assets/icons/No_Image.jpg', context: '', msgType: 'IMAGE' }];
              const moveToCartProduct = this.getProcessedLevel3Product(level3Product, level2Data);
              moveToCartProduct.productQuantity = '1';
              moveToCartProduct.productImage = attachments[0].url;
              const isMovedToCart = this.addProductToCart(moveToCartProduct, '1', false);
            }
          });
        });
      }
    });
  }
}