/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i2 from "@angular/material/progress-bar";
import * as i3 from "@angular/platform-browser/animations";
import * as i4 from "@angular/common";
import * as i5 from "./route-progress-bar.component";
import * as i6 from "@angular/router";
var styles_RouteProgressBarComponent = ["mat-progress-bar[_ngcontent-%COMP%] {\n      position: fixed;\n      z-index: 10;\n      top: 0;\n    }"];
var RenderType_RouteProgressBarComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_RouteProgressBarComponent, data: {} });
export { RenderType_RouteProgressBarComponent as RenderType_RouteProgressBarComponent };
function View_RouteProgressBarComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mat-progress-bar"], ["color", "warn"], ["mode", "query"], ["role", "progressbar"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i1.View_MatProgressBar_0, i1.RenderType_MatProgressBar)), i0.ɵdid(1, 4374528, null, 0, i2.MatProgressBar, [i0.ElementRef, i0.NgZone, [2, i3.ANIMATION_MODULE_TYPE], [2, i2.MAT_PROGRESS_BAR_LOCATION]], { color: [0, "color"], mode: [1, "mode"] }, null)], function (_ck, _v) { var currVal_3 = "warn"; var currVal_4 = "query"; _ck(_v, 1, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = (((i0.ɵnov(_v, 1).mode === "indeterminate") || (i0.ɵnov(_v, 1).mode === "query")) ? null : i0.ɵnov(_v, 1).value); var currVal_1 = i0.ɵnov(_v, 1).mode; var currVal_2 = i0.ɵnov(_v, 1)._isNoopAnimation; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_RouteProgressBarComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_RouteProgressBarComponent_1)), i0.ɵdid(1, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.routeLoading$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_RouteProgressBarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-route-progress-bar", [], null, null, null, View_RouteProgressBarComponent_0, RenderType_RouteProgressBarComponent)), i0.ɵdid(1, 114688, null, 0, i5.RouteProgressBarComponent, [i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RouteProgressBarComponentNgFactory = i0.ɵccf("app-route-progress-bar", i5.RouteProgressBarComponent, View_RouteProgressBarComponent_Host_0, {}, {}, []);
export { RouteProgressBarComponentNgFactory as RouteProgressBarComponentNgFactory };
