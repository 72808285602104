import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpWithAuthService } from '../project-common/services/http-interceptor.service';
import * as i0 from "@angular/core";
import * as i1 from "../project-common/services/http-interceptor.service";
var QuotationService = /** @class */ (function () {
    function QuotationService(http) {
        this.http = http;
        this.APP_NAME = "APGREEN";
        this.baseUrl = environment.nodeServer + "/v2/bizstore/quotations";
    }
    QuotationService.prototype.searchBaseProduct = function (request) {
        return this.http.get(environment.baseUrl + "/rest/v1/search/Vproduct/bizstore?keyword=" + request.keyword + "&limit=" + (request.limit || 20) + "&now=true&offset=" + (request.offset || 0)).pipe(map(function (response) {
            var result = response;
            return result;
        }));
    };
    QuotationService.prototype.getRelaventSellers = function (baseProduct) {
        var url = environment.baseUrl + "/v2/bizstore/quotations/" + baseProduct + "/sellers";
        return this.http.get(url);
    };
    QuotationService.prototype.getl2Products = function (profileKey, offset, limit) {
        if (offset === void 0) { offset = 0; }
        if (limit === void 0) { limit = 100; }
        var url = environment.baseUrl + "/v2/listing/products/l2?rfq=true&offset=" + offset + "&limit=" + limit + "&storeType=BIZ_STORE&profileKey=" + profileKey;
        return this.http.get(url).pipe(map(function (data) {
            return data;
        }));
    };
    QuotationService.prototype.submitRequirement = function (request) {
        return this.http.post(environment.restBaseUrlV2 + "/store/" + this.APP_NAME + "/rfq/create", request).pipe(map(function (response) {
            var result = response;
            return result;
        }));
    };
    QuotationService.prototype.submitAssistRequirement = function (request) {
        return this.http.post(this.baseUrl + "/assist", request).pipe(map(function (response) {
            var result = response;
            return result;
        }));
    };
    QuotationService.prototype.submitQuotation = function (requirementId, request) {
        return this.http.put(environment.restBaseUrlV2 + "/store/" + this.APP_NAME + "/rfq/" + requirementId + "/reply", request).pipe(map(function (response) {
            var result = response;
            return result;
        }));
    };
    QuotationService.prototype.getSentList = function (inboxType, offset, limit) {
        if (offset === void 0) { offset = 0; }
        if (limit === void 0) { limit = 20; }
        var url = environment.nodeServer + "/v2/store/" + this.APP_NAME + "/rfq/sent?offset=" + offset + "&limit=" + limit + "&inboxType=" + inboxType;
        return this.http.get(url).pipe(map(function (response) {
            var result = response;
            return result;
        }));
    };
    QuotationService.prototype.getInboxList = function (inboxType, offset, limit) {
        if (offset === void 0) { offset = 0; }
        if (limit === void 0) { limit = 20; }
        var url = environment.nodeServer + "/v2/store/" + this.APP_NAME + "/rfq/inbox?offset=" + offset + "&limit=" + limit + "&inboxType=" + inboxType;
        return this.http.get(url).pipe(map(function (response) {
            var result = response;
            return result;
        }));
    };
    QuotationService.prototype.getLatestRfqs = function (offset, limit) {
        if (offset === void 0) { offset = 0; }
        if (limit === void 0) { limit = 20; }
        var url = this.baseUrl + "/suggested?offset=" + offset + "&limit=" + limit;
        return this.http.get(url).pipe(map(function (response) {
            var result = response;
            return result;
        }));
    };
    QuotationService.prototype.getRequirementDetails = function (requirementId, isAssistant) {
        var url = environment.nodeServer + "/v2/store/" + this.APP_NAME + "/rfq/" + requirementId + "/details" + (isAssistant ? '?isAssisting=true' : '');
        return this.http.get(url).pipe(map(function (response) {
            var result = response;
            return result;
        }));
    };
    QuotationService.prototype.getQuotationDetails = function (requirementId, quotationId, isAssistant) {
        var url = environment.nodeServer + "/v2/store/" + this.APP_NAME + "/rfq/" + requirementId + "/" + quotationId + (isAssistant ? '?isAssisting=true' : '');
        return this.http.get(url).pipe(map(function (response) {
            var result = response;
            return result;
        }));
    };
    QuotationService.prototype.sendSms = function (requestObj) {
        var url = this.baseUrl + "/" + requestObj.requirementId + "/" + requestObj.quotationId + "/sendSMS";
        return this.http.post(url, requestObj).pipe(map(function (response) {
            var result = response;
            return result;
        }));
    };
    QuotationService.prototype.getExistingProducts = function (baseProductId, offset, limit, profileKey) {
        var url = this.baseUrl + "/" + baseProductId + "/saleableItems?profileKey=" + profileKey + "&offset=" + offset + "&limit=" + limit;
        return this.http.get(url).pipe(map(function (response) {
            var result = response;
            return result;
        }));
    };
    QuotationService.prototype.attachProductToRequirement = function (_a) {
        var requirementId = _a.requirementId, quotationId = _a.quotationId, payload = _a.payload;
        var url = this.baseUrl + "/" + requirementId + "/" + quotationId + "/addProduct";
        return (this.http.put(url, payload)
            .pipe(map(function (response) { return response; })));
    };
    QuotationService.prototype.closeQuotation = function (request) {
        var sender = request.sender, assistedBy = request.assistedBy, requirementId = request.requirementId, quotationId = request.quotationId, isAssisting = request.isAssisting;
        var url = environment.nodeServer + "/v2/store/" + this.APP_NAME + "/rfq/" + requirementId + "/" + quotationId + "/close" + (isAssisting ? '?isAssisting=true' : '');
        return (this.http.put(url, isAssisting ? { sender: sender, assistedBy: assistedBy } : sender)
            .pipe(map(function (response) { return response; })));
    };
    QuotationService.prototype.closeRequirement = function (request) {
        var sender = request.sender, assistedBy = request.assistedBy, requirementId = request.requirementId, isAssisting = request.isAssisting;
        var url = environment.nodeServer + "/v2/store/" + this.APP_NAME + "/rfq/" + requirementId + "/close" + (isAssisting ? '?isAssisting=true' : '');
        return (this.http.put(url, isAssisting ? { sender: sender, assistedBy: assistedBy } : sender)
            .pipe(map(function (response) { return response; })));
    };
    QuotationService.prototype.submitTerms = function (requirementId, quotationId, requestPayload) {
        var url = environment.nodeServer + "/v2/store/" + this.APP_NAME + "/rfq/" + requirementId + "/" + quotationId + "/terms";
        return this.http.put(url, requestPayload).pipe(map(function (response) {
            var result = response;
            return result;
        }));
    };
    QuotationService.prototype.getRfqsById = function (profileKey, limit, offset) {
        if (limit === void 0) { limit = 10; }
        if (offset === void 0) { offset = 0; }
        var url = this.baseUrl + "/requirements/" + profileKey + "?limit=" + limit + "&offset=" + offset;
        return this.http.get(url).pipe(map(function (res) { return res; }));
    };
    /**
     * To get assistants for farm store
     *
     * This will have list of Kalgudi employees in arrays depending on their role
     *
     * @returns { Observable<any> } json object having multiple role based arrays
     */
    // getAssistants(): Observable<any> {
    //     let url = environment.baseUrl;
    //     url += `/data/storeJsons/biz_assistants`;
    //     return this.http.get(url).pipe(map(response => response));
    // }
    QuotationService.prototype.getAssistants = function () {
        // const url = `${environment.nodeServer}/v2/bizstore/profiles/assistants`
        // return this.http.
        //     get(url).pipe(map(resposne => {
        //         const result: any = resposne.data;
        //         return result;
        //     }));
        var url = environment.nodeServer + "/v2/store/" + this.APP_NAME + "/rfq/assistants";
        return this.http.get(url).pipe(map(function (response) { return response.data; }));
    };
    QuotationService.prototype.getARFQTypes = function () {
        var url = environment.baseUrl + "/rest/v1/bizstore/misc/arfqtemplates";
        return this.http.get(url).pipe(map(function (res) { return JSON.parse(res.data); }));
    };
    /**
     * @param eventId connectx.kalgudi.com (Event id)
     */
    QuotationService.prototype.getEventDetails = function (eventId) {
        var url = environment.baseUrl + "/v2/meetups/events/" + eventId;
        return this.http.get(url);
    };
    QuotationService.prototype.getMyAssistedRfqs = function (reqObje, reqEmail) {
        // const url = `${this.baseUrl}/assisted/${reqObje.email}?role=${reqObje.role}&filter=${reqObje.userRole}&rfqState=${reqObje.state.toUpperCase()}&offset=${reqObje.offset}&limit=${reqObje.limit}`
        var url = this.baseUrl + "/assisted/" + reqEmail;
        return this.http.get(url, reqObje).pipe(map(function (response) { return response; }));
    };
    // TagAssistantRfqs
    QuotationService.prototype.getRequirements = function (requestObject) {
        var url = this.baseUrl + "/requirements";
        return this.http.get(url, requestObject).pipe(map(function (data) {
            return data;
        }));
    };
    QuotationService.prototype.getTenders = function (requestObject) {
        var url = environment.baseUrl + "/rest/v2/store/quotations/OUTPUTS/tenders";
        return this.http.get(url, requestObject).pipe(map(function (data) {
            return data;
        }));
    };
    QuotationService.prototype.getTenderRequirements = function (tenderId, request) {
        var url = environment.baseUrl + "/rest/v2/store/quotations/OUTPUTS/tender/" + tenderId;
        return this.http.get(url, request).pipe(map(function (data) {
            return data;
        }));
    };
    QuotationService.prototype.deleteMessage = function (message, requirementId, quotationId) {
        var url = this.baseUrl + "/" + requirementId + "/" + quotationId;
        return this.http.put(url, message).pipe(map(function (response) { return response; }));
    };
    QuotationService.prototype.convertGenericRequirementIntoEnquiry = function (request) {
        var url = this.baseUrl + "/convertToEnquiry";
        return this.http.post(url, request);
    };
    QuotationService.prototype.assistGenericRfq = function (request) {
        var url = this.baseUrl + "/OUTPUTS/toAssist";
        return this.http.post(url, request);
    };
    QuotationService.prototype.getQuotColor = function (state) {
        if (state == 'QUOT_SKU_READY')
            return '#b48504';
        else if (state == 'QUOT_SKU_PUBLISHED')
            return 'red';
        else if (state == 'QUOT_ORDERED' || state == 'QUOT_CLOSED' || state == 'RFQ_CLOSED')
            return 'green';
        else
            return '#8B4513';
    };
    /* getUnreadRfqMsgCount() {
         const url = `${this.baseUrl}/unreadRfqCount`;
         return this.http.get(url).pipe(map(data => {
             return data;
         }));
     }
     */
    QuotationService.prototype.submitMultiProductRequirement = function (request) {
        return this.http.post(environment.restBaseUrlV2 + "/store/" + this.APP_NAME + "/rfq/create", request).pipe(map(function (response) {
            var result = response;
            return result;
        }));
    };
    QuotationService.prototype.updateLeadType = function (requirementId, leadType) {
        return this.http.put(environment.baseUrl + "/rest/v2/store/quotations/OUTPUTS/" + requirementId + "/leadType/" + leadType, {}).pipe(map(function (response) {
            var result = response;
            return result;
        }));
    };
    QuotationService.prototype.updatedAssistants = function (requirementId, assistantPayload) {
        return this.http.put(environment.baseUrl + "/rest/v2/store/quotations/OUTPUTS/" + requirementId + "/updateAssistant", assistantPayload).pipe(map(function (response) {
            var result = response;
            return result;
        }));
    };
    QuotationService.prototype.getSellerProducts = function (commaSeparatedProfileKeys) {
        var url = environment.baseUrl + "/rest/v2/bizstore/quotations/sellerProducts?ids=" + commaSeparatedProfileKeys;
        return this.http.get(url);
    };
    QuotationService.prototype.getSellerRfqs = function (commaSeparatedProfileKeys) {
        var url = environment.baseUrl + "/rest/v2/bizstore/quotations/sellerRfqs?ids=" + commaSeparatedProfileKeys;
        return this.http.get(url);
    };
    QuotationService.prototype.getBuyerRfqs = function (buyerProfileKey) {
        var url = environment.baseUrl + "/rest/v2/bizstore/quotations/buyerRfqs?ids=" + buyerProfileKey;
        return this.http.get(url);
    };
    QuotationService.prototype.submitMltipleQuotation = function (requirementId, request) {
        return this.http.put(this.baseUrl + "/" + requirementId + "/reply/broadcast", request).pipe(map(function (response) {
            var result = response;
            return result;
        }));
    };
    QuotationService.prototype.getRfqStats = function () {
        var url = environment.baseUrl + "/v2/listing/rfqs/statistics";
        return this.http.get(url);
    };
    QuotationService.prototype.prepareProduct = function (product) {
        var productObj = {
            productId: product.productId || product.VProductId,
            productName: product.productName || product.VProductName,
            baseCategory: product.baseCategory,
            VProductId: product.VProductId || product.productId,
            VProductName: product.VProductName || product.productName
        };
        return productObj;
    };
    QuotationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function QuotationService_Factory() { return new QuotationService(i0.ɵɵinject(i1.HttpWithAuthService)); }, token: QuotationService, providedIn: "root" });
    return QuotationService;
}());
export { QuotationService };
