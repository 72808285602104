import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
var ScriptLoaderService = /** @class */ (function () {
    function ScriptLoaderService() {
        this.scripts = [];
    }
    ScriptLoaderService.prototype.loadScript = function (script) {
        var _this = this;
        return new Observable(function (observer) {
            var existingScript = _this.scripts.find(function (s) { return s.src == script; });
            // Complete if already loaded
            if (existingScript && existingScript.loaded) {
                observer.next(existingScript);
                observer.complete();
            }
            else {
                // Load the script
                var scriptElement = document.createElement("script");
                scriptElement.type = "text/javascript";
                scriptElement.async = true;
                scriptElement.src = script;
                scriptElement.onload = function () {
                    _this.scripts.push({ src: script, loaded: true });
                    observer.next(script);
                    observer.complete();
                };
                scriptElement.onerror = function (error) {
                    _this.scripts.push({ src: script, loaded: false });
                    observer.error("Couldn't load script " + script);
                };
                document.getElementsByTagName('body')[0].appendChild(scriptElement);
            }
        });
    };
    ScriptLoaderService.prototype.loadStyle = function (script) {
        var _this = this;
        return new Observable(function (observer) {
            var existingScript = _this.scripts.find(function (s) { return s.src == script; });
            // Complete if already loaded
            if (existingScript && existingScript.loaded) {
                observer.next(existingScript);
                observer.complete();
            }
            else {
                // Load the script
                var scriptElement = document.createElement("link");
                scriptElement.type = 'text/css';
                scriptElement.rel = 'stylesheet';
                scriptElement.href = script;
                scriptElement.onload = function () {
                    _this.scripts.push({ src: script, loaded: true });
                    observer.next(script);
                    observer.complete();
                };
                scriptElement.onerror = function (error) {
                    _this.scripts.push({ src: script, loaded: false });
                    observer.error("Couldn't load script " + script);
                };
                document.getElementsByTagName('body')[0].appendChild(scriptElement);
            }
        });
    };
    ScriptLoaderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ScriptLoaderService_Factory() { return new ScriptLoaderService(); }, token: ScriptLoaderService, providedIn: "root" });
    return ScriptLoaderService;
}());
export { ScriptLoaderService };
