import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Headers } from '@angular/http';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SpinnerService } from 'src/app/project-common/services/spinner.service';
import { environment } from 'src/environments/environment';

import { NotificationService } from './notification.service';
import { HttpClient } from '@angular/common/http';
import { ServiceResponse } from '../models/network-request.model';

@Injectable()
export class HttpWithAuthService {

  countryCode: string;
  userName: string;
  password: string;
  userData: any;
  isLoggedIn: boolean;
  redirectUrl: string;
  userAuthHeader: any;
  isHeadersSetManually: boolean;

  constructor(private http: HttpClient, protected localStorage: LocalStorage, private notification: NotificationService,
    @Inject(PLATFORM_ID) platformId: string, private snackbar: MatSnackBar, private router: Router,
    private spinner: SpinnerService) {
    this.userAuthHeader = new Headers();
    this.userAuthHeader.append('Content-Type', 'application/json');
    this.userAuthHeader.append('version', environment.version);
    this.userAuthHeader.append('source', environment.source);
    // console.log("isBroswer: ", isPlatformBrowser(platformId));

    // For authGaurd
    this.isLoggedIn = JSON.parse(window.localStorage['YUdGelRHOW5aMlZrU1c0PQ=='] || 'false');
    if (this.isLoggedIn && window.localStorage['dXNlcm5hbWU='] && window.localStorage['cGFzc3dvcmQ=']) {
      this.setAssistedHeader();
      this.userAuthHeader.append('userName', atob(window.localStorage['dXNlcm5hbWU=']));
      this.userAuthHeader.append('password', atob(window.localStorage['cGFzc3dvcmQ=']));
      this.setAuthHeaders().subscribe ( res => {
        this.getUserData();
      });
    }
    this.notification.userDataChanges.subscribe(() =>  {
      this.getUserData();
      this.setAssistedHeader();
    });
  }

  setAuthHeaders (): Observable<any> {
    return new Observable( observer => {
      this.getUserName().subscribe( userName => {
        this.localStorage.getItem('userdata').subscribe( userData => {
          if (!userData) {
            userData = {
              mobileNo : ''
            };
          }
          this.userAuthHeader.set('userName', userData.mobileNo || userName);
          localStorage['dXNlcm5hbWU='] = btoa(userData.mobileNo || userName);
          this.userName = userData.mobileNo || userName;
          observer.next(userData.mobileNo || userName);
          if (this.password) {
            observer.complete();
          }
        });
      });
      this.getUserPassword().subscribe( password => {
        this.userAuthHeader.set('password', password);
        this.password = password;
        localStorage['cGFzc3dvcmQ='] = btoa(password);
        observer.next(password);
        if (this.userName) {
          observer.complete();
        }
      });
    });
  }

  async setImediateHeaders (userName: string, password: string) {
    localStorage.setItem('dXNlcm5hbWU=', btoa(userName));
    localStorage.setItem('cGFzc3dvcmQ=', btoa(password));
    this.userAuthHeader.set('userName', userName);
    this.userAuthHeader.set('password', password);
  }

  setAssistedHeader () {
    this.userAuthHeader.set('isAssisting', window.localStorage.getItem('Y2FuQXNzaXN0') || 'false');
  } 

  getUserName (): Observable<any> {
    return new Observable( observer => {
      this.localStorage.getItem('username').subscribe( userName => {
        this.userName = userName;
        observer.next(userName);
        observer.complete();
      });
    });
  }

  getUserPassword (): Observable<any> {
    return new Observable( observer => {
      this.localStorage.getItem('password').subscribe( password => {
        this.password = password;
        observer.next(password);
        observer.complete();
      });
    });
  }

  getUserData () {
    this.localStorage.getItem('userdata').subscribe( userData => {
      this.userData = userData;
      this.isLoggedIn = !!userData;
      this.userData && this.userAuthHeader.set('userName', this.userData.mobileNo);
      !this.userData && this.userAuthHeader.set('userName', '');
      localStorage['YUdGelRHOW5aMlZrU1c0PQ=='] = this.isLoggedIn;
    });
  }

  get (url,params?) {
    
    return this.http.get(url, {
      headers: this.userAuthHeader,
      params: params
    }).pipe(map (response => {
      let result: ServiceResponse;
      try {
        result = response as any;
        this.processResponse(result);
      } catch (e) {
        console.error('Unable to parse\n', e);
      }
      return result;
    }), catchError (error => {
      const result: any = error;
      this.processResponse(result);
      throw error;
    }));
  }

  post (url, data) {
    return this.http.post(url, data, {
      headers: this.userAuthHeader
    }).pipe(map (response => {
      let result: ServiceResponse;
      try {
        result = response as any;
        this.processResponse(result);
      } catch (e) {
        console.error('Unable to parse\n', e);
      }
      return result;
    }), catchError (error => {
      const result: any = error;
      this.processResponse(result);
      throw error;
    }));
  }

  put (url, data) {
    return this.http.put(url, data, {
      headers: this.userAuthHeader
    }).pipe(map (response => {
      let result: ServiceResponse;
      try {
        result = response as any;
        this.processResponse(result);
      } catch (e) {
        console.error('Unable to parse\n', e);
      }
      return result;
    }), catchError (error => {
      const result: any = error;
      this.processResponse(result);
      throw error;
    }));
  }

  delete (url) {
    return this.http.delete(url, {
      headers: this.userAuthHeader
    }).pipe(map (response => {
      let result: ServiceResponse;
      try {
        result = response as any;
        this.processResponse(result);
      } catch (e) {
        console.error('Unable to parse\n', e);
      }
      return result;
    }), catchError (error => {
      const result: any = error;
      this.processResponse(result);
      throw error;
    }));
  }

  /**
   * To handle error responses from service
   * @param response Service response
   */
    processResponse (response) {
        let errorCode =  response.error ? response.error.code : null;
      
        if (errorCode && errorCode > 399) {
            this.spinner.changeStatus(false, false);
            let errorInfo = response.info || (response.error &&  response.error.info);
            this.checkForAuthentication(errorCode);

            const info = errorInfo || `Something went wrong, please try again ${errorCode}`;
            this.snackbar.open(info, 'OK', {
                duration: 7000, panelClass: ['snakbar-color']
            });
        }
    }

  checkForAuthentication(code) {
    if (code == 401 || code == 502) {
      this.navigateToLoginPage();
      return true;
    }
    return false;
  }

  navigateToLoginPage () {
    delete localStorage['YUdGelRHOW5aMlZrU1c0PQ=='];
    delete localStorage['cGFzc3dvcmQ='];
    delete localStorage['dXNlcm5hbWU='];
    delete localStorage[btoa('profileKey')];
    delete localStorage['assistant'];
      // removing 'tk' , 'c', 'pf' from localStorage to remove login information.

      localStorage.removeItem('pf');

      localStorage.removeItem('tk');
  
      localStorage.removeItem('c');
    this.localStorage.removeItem('userdata').subscribe(response => {
      this.localStorage.removeItemSubscribe('username');
      this.localStorage.removeItemSubscribe('password');
      this.localStorage.removeItemSubscribe('userWishList');
      this.localStorage.removeItemSubscribe('FAVOURITE_MAKERS');
      this.localStorage.removeItemSubscribe('SAVED_PRODUCTS');
      const cart = {
        productIds : [],
        products   : []
      };
      this.localStorage.setItemSubscribe('cart', cart);
      this.notification.notifyForUserDataChange();
      this.router.navigateByUrl('auth/login');

    });
  }
}
