
    <div class="category-container">
        <div (click)="isExpanded = !isExpanded" class="category" fxLayoutAlign="space-between center">
            <div class="name" translate>{{category.name | lowercase}}</div>
            <i class="fa" [class.fa-angle-right]="!isExpanded" [class.fa-angle-down]="isExpanded"></i>
        </div>
        <div [@expandCollapse]="isExpanded ? 'expanded' : 'collapsed'">
            <div class="sub-category-container" *ngFor="let sub of category.subCategory">
                <div class="category" fxLayoutAlign="space-between center" *ngIf="!sub.subCategory">
                    <div class="name" translate>{{ sub.name | lowercase }}</div>
                </div>
                <app-mobile-sub-cat [sub-cat]="sub" (category)="selectCategory($event)" *ngIf="sub.subCategory"></app-mobile-sub-cat>
            </div>
            <div class="sub-category-container" (click)="selectCategory(category.name)">
                <div class="category" fxLayoutAlign="space-between center">
                    <div class="name" translate><span translate>All</span> {{category.name | lowercase  }}</div>
                </div>
            </div>

        </div>
    </div>
    