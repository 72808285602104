<a class="d-menu">
  <div class="menu-item" fxLayoutAlign="space-between center" (click)="toggleMenu()">
    <span class="mr-5" fxLayoutAlign="start center">
      <mat-icon>list</mat-icon>
      <span class="ml-10" translate>All categories</span>
    </span>
    <i class="fa fa-caret-{{ menuOpened === 'closed' ? 'down' : 'up'}}" aria-hidden="true"></i>
  </div>


  <ng-container *ngIf="menuOpened">
    <div class="d-menu-container {{ menuOpened }}">

      <div fxLayout class="d-categories-wrapper">


        <ul class="d-categories" fxFlex="270px">
          <ng-container *ngIf=" (categories$ | async) as categories">


            <ng-container *ngFor="let category of categories">

              <li>
                <div class="d-cat-title-wrapper">
                  <a class="d-cat-title" [routerLink]="['/search', category?.name]"
                    [queryParams]="{category: category?.id }">
                    <img src="assets/categories-menu-svg/{{category?.id}}.svg" width="20" alt="">
                    <span class="ml-15" translate>{{ category?.name | lowercase }}</span>
                    <i *ngIf="category?.subCategory?.length || category?.dealItems?.length" class="fa fa-angle-right"
                      aria-hidden="true"></i>
                  </a>

                </div>
                <ng-container *ngIf="category?.subCategory?.length || category?.dealItems?.length">
                  <div class="d-cat-view">
                      <ng-container class="level1-wrapper" *ngIf="category?.subCategory">
                        <ul class="level1">
                            <li  *ngFor="let category2 of category?.subCategory">
                              <div class="level1-wrapper" *ngIf="category?.subCategory">
                                <a class="d-cat-title" fxFlex fxLayoutAlign="space-between"
                                    [routerLink]="['/search', category2.name ]" 
                                    [queryParams]="{ category: category.id, limit: 20, view: 'grid', l2Id: category2.id }">
                                    {{ category2.name | translate }}<i *ngIf="category2.category" class="fa fa-angle-right"  aria-hidden="true"></i>
                                </a>
                              </div>
                                <div class="level2-wrapper" *ngIf="category2?.subCategory?.length">
                                    <ul class="level2">
                                        <li *ngFor="let category3 of category2?.subCategory">
                                            <a fxFlex  class="d-cat-title" fxLayoutAlign="space-between"
                                                [routerLink]="['/search', category3.name ]" 
                                                [queryParams]="{ category: category.id, limit: 20, view: 'grid', l2Id: category2.id , l3Id: category3.id }">
                                                {{ category3.name | translate }}<i *ngIf="category3.category" class="fa fa-angle-right"  aria-hidden="true"></i>
                                            </a>
                                            <div class="level3-wrapper" *ngIf="category3?.subCategory">
                                                <ul class="level3">
                                                    <li *ngFor="let category4 of category3?.subCategory">
                                                        <a fxFlex class="d-cat-title" fxLayoutAlign="space-between"
                                                        [routerLink]="['/search', category4.name ]" 
                                                        [queryParams]="{ category: category.id, limit: 20, view: 'grid', l2Id: category2.id , l3Id: category3.id, l4Id: category4.id }">
                                                            {{ category4.name | translate }}<i *ngIf="category4.category" class="fa fa-angle-right"  aria-hidden="true"></i>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                      </ng-container>
      

                      <!-- <ng-container *ngFor="let item of category?.subCategory | slice:0:4">
                        <div class="p-10" fxFlex="220px">
                          <a class="d-sec-title" [routerLink]="['/search', item?.name]"
                            [queryParams]="{category: category?.id, l2Id: item?.id }">
                            {{ item?.name }}
                          </a>
                          <ul class="d-sec-list">
                            <li *ngFor="let subCategoryItem of item?.subCategory"
                              [routerLink]="['/search', subCategoryItem?.name]"
                              [queryParams]="{category: category?.id, l2Id: item?.id,  l3Id: subCategoryItem?.id }">
                              {{ subCategoryItem?.name }}</li>
                          </ul>
                        </div>
                      </ng-container> -->

                      <!-- <div class="p-10" fxFlex="40%" *ngIf="category?.dealItems?.length">
                        <div class="d-sec-title">
                          Hot Deal
                        </div>
                        <div class="d-deals">
      
                          <ng-container *ngFor="let dealItem of category?.dealItems">
                            <div class="d-deal">
                              <img height="80" [src]="dealItem?.itemImageUrl" alt="">
          
                              <div class="d-deal-details">
                                <div class="fw-600 mb-10">{{ dealItem?.itemName }}</div>
                                <div class="font-20 fw-600 price">{{ dealItem?.price | currency: 'INR' }}</div>
          
                                <div class="mt-10" fxLayoutAlign="end start">
                                  <button class="add-cart" fxLayoutAlign="center center">
                                    <i class="fa fa-shopping-bag font-12 mr-5"></i>
                                    <span>Add</span>
                                  </button>
                                </div>
          
                              </div>
                            </div>
                          </ng-container>
                        </div>
                      </div> -->

                  </div>
                </ng-container>

              </li>

            </ng-container>

            <!-- <li>
              <div class="d-cat-title-wrapper">
                <a fxLayoutAlign="center center" class="d-cat-title"
                  (click)="show = (show === categories.length) ? 8 :  categories.length">
                  <span translate>Show {{ show === categories.length ? 'less' : 'more' }}</span>
                  <i class="ml-10 fa fa-angle-{{(show === categories.length) ? 'up' : 'down'}}"></i>
                </a>
              </div>
            </li> -->
          </ng-container>




          <!-- <li>
            <div class="d-cat-title-wrapper">
              <a class="d-cat-title" routerLink="/all-categories">
                <img src="" width="20" alt="">
                <span class="ml-10">View all</span>
                <i class="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </li> -->

        </ul>
      </div>
    </div>
  </ng-container>


</a>