/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./side-links.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "@angular/cdk/bidi";
import * as i5 from "../../../../../node_modules/angular-font-awesome/dist/angular-font-awesome.ngfactory";
import * as i6 from "angular-font-awesome";
import * as i7 from "@angular/common";
import * as i8 from "./side-links.component";
import * as i9 from "@angular/router";
var styles_SideLinksComponent = [i0.styles];
var RenderType_SideLinksComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SideLinksComponent, data: {} });
export { RenderType_SideLinksComponent as RenderType_SideLinksComponent };
function View_SideLinksComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "side-links"], ["fxLayout", "column"], ["fxLayoutAlign", "flex-end"], ["fxLayoutGap", "20px"], ["fxLayoutGap.xs", "10px"], ["id", "backToTop"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 1720320, null, 0, i2.DefaultLayoutGapDirective, [i1.ElementRef, i1.NgZone, i4.Directionality, i3.StyleUtils, [2, i2.LayoutGapStyleBuilder], i3.MediaMarshaller], { fxLayoutGap: [0, "fxLayoutGap"], "fxLayoutGap.xs": [1, "fxLayoutGap.xs"] }, null), i1.ɵdid(3, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "goTop side_link back-to-top"]], [[2, "visible", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.scrollTop() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "icon"], ["fxLayoutAlign", "center center"]], null, null, null, null, null)), i1.ɵdid(6, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "fa", [["name", "angle-up"]], null, null, null, i5.View_AngularFontAwesomeComponent_0, i5.RenderType_AngularFontAwesomeComponent)), i1.ɵdid(8, 114688, null, 0, i6.AngularFontAwesomeComponent, [], { name: [0, "name"] }, null)], function (_ck, _v) { var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "20px"; var currVal_2 = "10px"; _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = "flex-end"; _ck(_v, 3, 0, currVal_3); var currVal_5 = "center center"; _ck(_v, 6, 0, currVal_5); var currVal_6 = "angle-up"; _ck(_v, 8, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.showBackToTopBtn; _ck(_v, 4, 0, currVal_4); }); }
export function View_SideLinksComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideLinksComponent_1)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isHome; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SideLinksComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "side-links", [], null, null, null, View_SideLinksComponent_0, RenderType_SideLinksComponent)), i1.ɵdid(1, 245760, null, 0, i8.SideLinksComponent, [i9.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SideLinksComponentNgFactory = i1.ɵccf("side-links", i8.SideLinksComponent, View_SideLinksComponent_Host_0, {}, {}, []);
export { SideLinksComponentNgFactory as SideLinksComponentNgFactory };
