/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./photo-swipe.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./photo-swipe.component";
var styles_PhotoSwipeComponent = [i0.styles];
var RenderType_PhotoSwipeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PhotoSwipeComponent, data: {} });
export { RenderType_PhotoSwipeComponent as RenderType_PhotoSwipeComponent };
export function View_PhotoSwipeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "div", [["aria-hidden", "true"], ["class", "pswp"], ["role", "dialog"], ["tabindex", "-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "pswp__bg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 20, "div", [["class", "pswp__scroll-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "pswp__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "pswp__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "pswp__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "pswp__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 15, "div", [["class", "pswp__ui pswp__ui--hidden"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 8, "div", [["class", "pswp__top-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "div", [["class", "pswp__counter"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "button", [["class", "pswp__button pswp__button--close"], ["title", "Close (Esc)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closePopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "button", [["class", "pswp__button pswp__button--fs"], ["title", "Toggle fullscreen"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "button", [["class", "pswp__button pswp__button--zoom"], ["title", "Zoom in/out"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 3, "div", [["class", "pswp__preloader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 2, "div", [["class", "pswp__preloader__icn"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "div", [["class", "pswp__preloader__cut"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 0, "div", [["class", "pswp__preloader__donut"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "div", [["class", "pswp__share-modal pswp__share-modal--hidden pswp__single-tap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 0, "div", [["class", "pswp__share-tooltip"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 0, "button", [["class", "pswp__button pswp__button--arrow--left"], ["title", "Previous (arrow left)"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 0, "button", [["class", "pswp__button pswp__button--arrow--right"], ["title", "Next (arrow right)"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, "div", [["class", "pswp__caption"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 0, "div", [["class", "pswp__caption__center"]], null, null, null, null, null))], null, null); }
export function View_PhotoSwipeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "photo-swipe", [], null, null, null, View_PhotoSwipeComponent_0, RenderType_PhotoSwipeComponent)), i1.ɵdid(1, 770048, null, 0, i2.PhotoSwipeComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PhotoSwipeComponentNgFactory = i1.ɵccf("photo-swipe", i2.PhotoSwipeComponent, View_PhotoSwipeComponent_Host_0, { images: "images", index: "index" }, { close: "close" }, []);
export { PhotoSwipeComponentNgFactory as PhotoSwipeComponentNgFactory };
