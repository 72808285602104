<div class="contact-cs">
    <div fxLayout fxLayoutGap="4px" fxHide.xs>
        <span>{{message}}: </span>
        <span class="phone-number">{{phone}}</span>
    </div>
    <div fxLayout fxLayoutGap="4px">
        <span fxShow.xs fxHide> 
            <span>{{message}}: <a href="tel: {{phone}}" class="phone-number">{{phone}}</a></span>
        </span>
    </div>
</div>