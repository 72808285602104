<div class="dialog">
  <h2 mat-dialog-title>Upload files here</h2>
  <mat-dialog-content
    class="mat-typography"
    fxLayout="row wrap"
    fxLayoutAlign="start stretch"
    fxLayoutGap="15px"
  >
    <div class="preview-wrapper" fxLayout="row wrap" fxLayoutGap.xs="0px">
      <div class="tile" *ngFor="let file of attachments; let index = index">
        <div
          class="preview-attachment"
          *ngIf="file?.type === 'DOCUMENT'"
          fxLayout
          fxFlex="230px"
        >
          <div
            fxLayout="column"
            fxLayoutAlign="space-between"
            class="attach-img"
          >
            <iframe
              [src]="file.url | scaleImage | safe"
              width="140px"
              height="140px"
              scrolling="no"
              [ngClass]="{ pdf: file?.type === 'DOCUMENT' }"
              type="application/pdf"
            ></iframe>
            <mat-card-content class="mb-0">
              <textarea
                matInput
                placeholder="Say something in context of attached file"
              ></textarea>
            </mat-card-content>
          </div>
          <fa name="times-circle" (click)="attachments.splice(index, 1)"></fa>
        </div>
        <div
          class="preview-attachment"
          *ngIf="file?.type === 'IMAGE'"
          fxLayout
          fxFlex="230px"
        >
          <div
            fxLayout="column"
            fxLayoutAlign="space-between"
            class="attach-img"
          >
            <div class="img-i" fxLayoutAlign="center center">
              <img [src]="file?.url | scaleImage" [title]="file.context" />
            </div>
            <mat-card-content class="mb-0">
              <textarea
                matInput
                [(ngModel)]="file.context"
                placeholder="Say something in context of attached file"
              ></textarea>
            </mat-card-content>
          </div>
          <fa name="times-circle" (click)="attachments.splice(index, 1)"></fa>
        </div>
      </div>
      <div
        class="uploadFile"
        [ngClass]="{ 'ml-0': attachments?.length == 0 }"
        fxLayout="row wrap"
      >
        <div
          class="select-file"
          fxLayout="column"
          fxLayoutAlign="center center"
          fxLayoutGap="12px"
          matTooltip="Upload image"
        >
          <input
            type="file"
            class="m0"
            title=""
            (change)="uploadFile($event)"
            accept="image/*"
          />
          <!--,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document-->
          <fa name="plus"></fa>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <div class="dialog-actions" fxLayoutAlign="end center" fxLayoutGap="15px">
    <button mat-button (click)="close()">Cancel</button>
    <button mat-raised-button (click)="submit()" cdkFocusInitial translate>
      Done
    </button>
  </div>
</div>