import { SpinnerService } from 'src/app/project-common/services/spinner.service';
import { MatSnackBar } from '@angular/material';
import * as i0 from "@angular/core";
import * as i1 from "../project-common/services/spinner.service";
import * as i2 from "@angular/material/snack-bar";
var CoreNotificationConfigService = /** @class */ (function () {
    function CoreNotificationConfigService(spinner, snackbar) {
        this.spinner = spinner;
        this.snackbar = snackbar;
    }
    CoreNotificationConfigService.prototype.showMessage = function (message, action, duration) {
        if (action === void 0) { action = ''; }
        this.snackbar.open(message, action, {
            duration: duration || 5000
        });
    };
    CoreNotificationConfigService.prototype.showSpinner = function (blocking) {
        this.spinner.changeStatus(true, true);
    };
    CoreNotificationConfigService.prototype.hideSpinner = function () {
        this.spinner.changeStatus(false);
    };
    CoreNotificationConfigService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CoreNotificationConfigService_Factory() { return new CoreNotificationConfigService(i0.ɵɵinject(i1.SpinnerService), i0.ɵɵinject(i2.MatSnackBar)); }, token: CoreNotificationConfigService, providedIn: "root" });
    return CoreNotificationConfigService;
}());
export { CoreNotificationConfigService };
