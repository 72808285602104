/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./nested-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "@ngx-translate/core";
import * as i7 from "./nested-menu.component";
import * as i8 from "../../../storefront/storefront.service";
var styles_NestedMenuComponent = [i0.styles];
var RenderType_NestedMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NestedMenuComponent, data: {} });
export { RenderType_NestedMenuComponent as RenderType_NestedMenuComponent };
function View_NestedMenuComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-chevron-right"]], null, null, null, null, null))], null, null); }
function View_NestedMenuComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-chevron-right"]], null, null, null, null, null))], null, null); }
function View_NestedMenuComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-chevron-right"]], null, null, null, null, null))], null, null); }
function View_NestedMenuComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-chevron-right"]], null, null, null, null, null))], null, null); }
function View_NestedMenuComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "a", [["fxFlex", ""]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.DefaultFlexDirective, [i1.ElementRef, i3.StyleUtils, i3.LAYOUT_CONFIG, i2.FlexStyleBuilder, i3.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), i1.ɵdid(3, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null), i1.ɵpod(4, { category: 0, l2Id: 1, l3Id: 2, l4Id: 3, limit: 4, view: 5 }), i1.ɵpad(5, 2), (_l()(), i1.ɵted(6, null, [" ", ""])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NestedMenuComponent_13)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_2 = ""; _ck(_v, 2, 0, currVal_2); var currVal_3 = _ck(_v, 4, 0, _v.parent.parent.parent.parent.parent.parent.context.$implicit.id, _v.parent.parent.parent.parent.context.$implicit.id, _v.parent.parent.context.$implicit.id, _v.context.$implicit.id, 20, "grid"); var currVal_4 = _ck(_v, 5, 0, "/search", _v.context.$implicit.value); _ck(_v, 3, 0, currVal_3, currVal_4); var currVal_6 = _v.context.$implicit.subCategories; _ck(_v, 9, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_5 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_v.context.$implicit.value)); _ck(_v, 6, 0, currVal_5); }); }
function View_NestedMenuComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "level3-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ul", [["class", "level3"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NestedMenuComponent_12)), i1.ɵdid(3, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.subCategories); _ck(_v, 3, 0, currVal_0); }, null); }
function View_NestedMenuComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "li", [], null, [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = ((_v.context.$implicit.isCategoriesVisible = true) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = ((_v.context.$implicit.isCategoriesVisible = false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "a", [["fxFlex", ""]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.DefaultFlexDirective, [i1.ElementRef, i3.StyleUtils, i3.LAYOUT_CONFIG, i2.FlexStyleBuilder, i3.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), i1.ɵdid(3, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null), i1.ɵpod(4, { category: 0, l2Id: 1, l3Id: 2, limit: 3, view: 4 }), i1.ɵpad(5, 2), (_l()(), i1.ɵted(6, null, [" ", ""])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NestedMenuComponent_10)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NestedMenuComponent_11)), i1.ɵdid(11, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_2 = ""; _ck(_v, 2, 0, currVal_2); var currVal_3 = _ck(_v, 4, 0, _v.parent.parent.parent.parent.context.$implicit.id, _v.parent.parent.context.$implicit.id, _v.context.$implicit.id, 20, "grid"); var currVal_4 = _ck(_v, 5, 0, "/search", _v.context.$implicit.value); _ck(_v, 3, 0, currVal_3, currVal_4); var currVal_6 = _v.context.$implicit.subCategories; _ck(_v, 9, 0, currVal_6); var currVal_7 = (((_v.context.$implicit == null) ? null : _v.context.$implicit.subCategories) && _v.context.$implicit.isCategoriesVisible); _ck(_v, 11, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_5 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_v.context.$implicit.value)); _ck(_v, 6, 0, currVal_5); }); }
function View_NestedMenuComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "level2-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ul", [["class", "level2"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NestedMenuComponent_9)), i1.ɵdid(3, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.subCategories); _ck(_v, 3, 0, currVal_0); }, null); }
function View_NestedMenuComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "li", [], null, [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = ((_v.context.$implicit.isCategoriesVisible = true) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = ((_v.context.$implicit.isCategoriesVisible = false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "a", [["fxFlex", ""]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.DefaultFlexDirective, [i1.ElementRef, i3.StyleUtils, i3.LAYOUT_CONFIG, i2.FlexStyleBuilder, i3.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), i1.ɵdid(3, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null), i1.ɵpod(4, { category: 0, l2Id: 1, limit: 2, view: 3 }), i1.ɵpad(5, 2), (_l()(), i1.ɵted(6, null, [" ", ""])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NestedMenuComponent_7)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NestedMenuComponent_8)), i1.ɵdid(11, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_2 = ""; _ck(_v, 2, 0, currVal_2); var currVal_3 = _ck(_v, 4, 0, _v.parent.parent.context.$implicit.id, _v.context.$implicit.id, 20, "grid"); var currVal_4 = _ck(_v, 5, 0, "/search", _v.context.$implicit.value); _ck(_v, 3, 0, currVal_3, currVal_4); var currVal_6 = _v.context.$implicit.subCategories; _ck(_v, 9, 0, currVal_6); var currVal_7 = (((_v.context.$implicit == null) ? null : _v.context.$implicit.subCategories) && _v.context.$implicit.isCategoriesVisible); _ck(_v, 11, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_5 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_v.context.$implicit.value)); _ck(_v, 6, 0, currVal_5); }); }
function View_NestedMenuComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "level1-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ul", [["class", "level1"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NestedMenuComponent_6)), i1.ɵdid(3, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.subCategories); _ck(_v, 3, 0, currVal_0); }, null); }
function View_NestedMenuComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "li", [], null, [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = ((_v.context.$implicit.isCategoriesVisible = true) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = ((_v.context.$implicit.isCategoriesVisible = false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "a", [["class", "capitalize"], ["fxFlex", ""]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.DefaultFlexDirective, [i1.ElementRef, i3.StyleUtils, i3.LAYOUT_CONFIG, i2.FlexStyleBuilder, i3.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), i1.ɵdid(3, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null), i1.ɵpod(4, { category: 0 }), i1.ɵpad(5, 2), (_l()(), i1.ɵted(6, null, [" ", " "])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NestedMenuComponent_4)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NestedMenuComponent_5)), i1.ɵdid(11, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_2 = ""; _ck(_v, 2, 0, currVal_2); var currVal_3 = _ck(_v, 4, 0, _v.context.$implicit.id); var currVal_4 = _ck(_v, 5, 0, "/search", _v.context.$implicit.value); _ck(_v, 3, 0, currVal_3, currVal_4); var currVal_6 = _v.context.$implicit.subCategories; _ck(_v, 9, 0, currVal_6); var currVal_7 = (((_v.context.$implicit == null) ? null : _v.context.$implicit.subCategories) && _v.context.$implicit.isCategoriesVisible); _ck(_v, 11, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_5 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_v.context.$implicit.value)); _ck(_v, 6, 0, currVal_5); }); }
function View_NestedMenuComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "menu-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ul", [["class", "menu"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NestedMenuComponent_3)), i1.ɵdid(3, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.allCategories; _ck(_v, 3, 0, currVal_0); }, null); }
function View_NestedMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "a", [["class", "menu-item d-menu"]], null, [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = ((_co.isCategoriesVisible = true) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = ((_co.isCategoriesVisible = false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "mr-5"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Shop by region"])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-angle-down"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NestedMenuComponent_2)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.allCategories && _co.isCategoriesVisible); _ck(_v, 6, 0, currVal_0); }, null); }
export function View_NestedMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_NestedMenuComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.allCategories && ((_co.allCategories == null) ? null : _co.allCategories.length)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_NestedMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "nested-menu", [], null, null, null, View_NestedMenuComponent_0, RenderType_NestedMenuComponent)), i1.ɵdid(1, 114688, null, 0, i7.NestedMenuComponent, [i8.StorefrontService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NestedMenuComponentNgFactory = i1.ɵccf("nested-menu", i7.NestedMenuComponent, View_NestedMenuComponent_Host_0, {}, {}, []);
export { NestedMenuComponentNgFactory as NestedMenuComponentNgFactory };
