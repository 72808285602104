import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { StorefrontService } from 'src/app/storefront/storefront.service';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { SpinnerService } from '../../services/spinner.service';
import { finalize } from 'rxjs/operators';
var PreBookComponent = /** @class */ (function () {
    function PreBookComponent(fb, storage, storeService, snackbar, router, spinner) {
        this.fb = fb;
        this.storage = storage;
        this.storeService = storeService;
        this.snackbar = snackbar;
        this.router = router;
        this.spinner = spinner;
        this.products = {
            banganpalli: {
                "productLevel3Title": "Bangenpalli / Beneshan",
                "pricePerUnit": 450,
                "productLevel1Id": "31t4",
                "productLevel2Id": "31t5",
                "productLevel3Id": "31t6",
                "productQuantity": 1,
                "sku": "5 kg"
            },
            lime: {
                "productLevel3Title": "Sweet Lime (Mosambi)",
                "pricePerUnit": 400,
                "productLevel1Id": "3290",
                "productLevel2Id": "3291",
                "productLevel3Id": "3292",
                "productQuantity": 1,
                "sku": "10 Kg"
            },
            himayat: {
                "productLevel3Title": "Himayat Mango",
                "pricePerUnit": 1250,
                "productLevel1Id": "32el",
                "productLevel2Id": "32em",
                "productLevel3Id": "32en",
                "productQuantity": 1,
                "sku": "5 kg"
            }
        };
    }
    PreBookComponent.prototype.ngOnInit = function () {
        this.preBookForm = this.fb.group({
            banganpalliQty: [0],
            limeQty: [0],
            himayatQty: [0],
            userDetails: this.fb.group({
                firstName: ["", [Validators.required]],
                email: ["", [Validators.email]],
                mobile: ["", [
                        Validators.minLength(10),
                        Validators.maxLength(10),
                        Validators.pattern(/^[6-9]\d{9}$/)
                    ]],
                profileKey: [""]
            })
        });
        this.prefillFields();
    };
    PreBookComponent.prototype.prefillFields = function () {
        var _this = this;
        this.storage.getItem('userdata')
            .subscribe(function (userdata) {
            if (!userdata)
                return;
            var _a = userdata.emailId, emailId = _a === void 0 ? "" : _a, _b = userdata.mobileNo, mobileNo = _b === void 0 ? "" : _b, profileKey = userdata.profileKey, firstName = userdata.firstName;
            var emailControl = _this.preBookForm.get('userDetails.email');
            var mobileControl = _this.preBookForm.get('userDetails.mobile');
            var profileKeyControl = _this.preBookForm.get('userDetails.profileKey');
            var firstNameControl = _this.preBookForm.get('userDetails.firstName');
            firstNameControl.patchValue(firstName);
            profileKeyControl.patchValue(profileKey);
            if (emailId) {
                emailControl.patchValue(emailId);
                emailControl.disable();
                return;
            }
            if (mobileNo) {
                mobileNo = +mobileNo.replace('+91', '');
                mobileControl.patchValue(mobileNo);
                mobileControl.disable();
                return;
            }
        });
    };
    PreBookComponent.prototype.bookNow = function () {
        var _this = this;
        if (!this.isValid)
            return;
        var products = this.getSelectedProducts();
        var userDetails = this.formValue.userDetails;
        var payload = {
            products: products,
            totalAmount: this.totalValue,
            userDetails: userDetails
        };
        this.spinner.start();
        this.storeService.preBook(payload)
            .pipe(finalize(function () { return _this.spinner.stop(); }))
            .subscribe(function (res) {
            var snackbarConfig = { duration: 4000 };
            if (res.code !== 201)
                return _this.snackbar.open("Booking failed, try after some time.", "Ok", snackbarConfig);
            _this.snackbar.open("Thank you for submitting the booking. We will get back to you soon.", "Ok", snackbarConfig);
            _this.router.navigate(['/']);
        });
    };
    PreBookComponent.prototype.getSelectedProducts = function () {
        var finalProducts = [];
        var _a = this.formValue, banganpalliQty = _a.banganpalliQty, limeQty = _a.limeQty, himayatQty = _a.himayatQty;
        var _b = this.products, banganpalli = _b.banganpalli, lime = _b.lime, himayat = _b.himayat;
        if (+banganpalliQty) {
            banganpalli.productQuantity = +banganpalliQty;
            finalProducts.push(banganpalli);
        }
        if (+limeQty) {
            lime.productQuantity = +limeQty;
            finalProducts.push(lime);
        }
        if (+himayatQty) {
            himayat.productQuantity = +himayatQty;
            finalProducts.push(himayat);
        }
        return finalProducts;
    };
    Object.defineProperty(PreBookComponent.prototype, "isValid", {
        get: function () {
            if (!this.preBookForm || !this.preBookForm.value)
                return false;
            var mobileControl = this.preBookForm.get('userDetails.mobile');
            var emailControl = this.preBookForm.get('userDetails.email');
            return ((this.totalValue > 0)
                && this.preBookForm.valid
                && (mobileControl.valid || emailControl.valid)
                && (mobileControl.value || emailControl.value));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PreBookComponent.prototype, "formValue", {
        get: function () {
            return this.preBookForm.getRawValue();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PreBookComponent.prototype, "totalValue", {
        get: function () {
            if (!this.preBookForm || !this.preBookForm.value)
                return 0;
            var _a = this.preBookForm.value, banganpalliQty = _a.banganpalliQty, limeQty = _a.limeQty, himayatQty = _a.himayatQty;
            var _b = this.products, banganpalli = _b.banganpalli, lime = _b.lime, himayat = _b.himayat;
            return banganpalli.pricePerUnit * banganpalliQty +
                lime.pricePerUnit * limeQty +
                himayat.pricePerUnit * himayatQty;
        },
        enumerable: true,
        configurable: true
    });
    return PreBookComponent;
}());
export { PreBookComponent };
