import { map } from 'rxjs/operators';
import { HttpWithAuthService } from './http-interceptor.service';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { NotificationService } from './notification.service';
import { Router } from '@angular/router';
import { CartService } from './cart.service';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-pwa/local-storage";
import * as i2 from "./notification.service";
import * as i3 from "./http-interceptor.service";
import * as i4 from "@angular/router";
import * as i5 from "./cart.service";
// import { FcmService } from './fcm.service';
// declare var ga: any;
var DbUpdateService = /** @class */ (function () {
    function DbUpdateService(storage, notification, http, router, cart) {
        this.storage = storage;
        this.notification = notification;
        this.http = http;
        this.router = router;
        this.cart = cart;
    }
    DbUpdateService.prototype.updateUserData = function (userData, loginInfo) {
        var _this = this;
        // setting 'pf' in localStorage to share the login information b/w core app & store.
        localStorage.setItem('pf', JSON.stringify(userData));
        this.storage.setItemSubscribe('userdata', userData);
        if (environment.production) {
            var userId = userData.firstName + ' - ' + userData.mobileNo;
            // ga('set', 'userId', userId);
            // ga('send', 'event', 'authentication', userId);      // console.log(userId);  
        }
        if (loginInfo) {
            loginInfo.mobileNo = userData.mobileNo;
            this.updateHeaders(loginInfo);
        }
        setTimeout(function () {
            _this.notification.notifyForUserDataChange();
        }, 300);
    };
    DbUpdateService.prototype.updateHeaders = function (loginInfo) {
        this.storage.setItemSubscribe('username', loginInfo.mobileNo);
        localStorage['dXNlcm5hbWU='] = btoa(loginInfo.mobileNo);
        this.storage.setItemSubscribe('password', loginInfo.password);
        localStorage['cGFzc3dvcmQ='] = btoa(loginInfo.password);
        this.http.setImediateHeaders(loginInfo.mobileNo, loginInfo.password);
    };
    DbUpdateService.prototype.getUserName = function () {
        return this.storage.getItem('userdata').pipe(map(function (userData) {
            return userData.mobileNo;
        }));
    };
    DbUpdateService.prototype.getUserPassword = function () {
        return this.storage.getItem('password');
    };
    DbUpdateService.prototype.clearUserData = function (redirectionUrl) {
        var _this = this;
        delete localStorage['YUdGelRHOW5aMlZrU1c0PQ=='];
        delete localStorage['cGFzc3dvcmQ='];
        delete localStorage['dXNlcm5hbWU='];
        delete localStorage[btoa('profileKey')];
        delete localStorage['assistant'];
        // removing 'tk' , 'c', 'pf' from localStorage to remove login information.
        localStorage.removeItem('pf');
        localStorage.removeItem('tk');
        localStorage.removeItem('c');
        this.storage.removeItemSubscribe('userdata');
        this.storage.removeItemSubscribe('username');
        this.storage.removeItemSubscribe('password');
        this.storage.removeItemSubscribe('userWishList');
        this.storage.removeItemSubscribe('FAVOURITE_MAKERS');
        this.storage.removeItemSubscribe('SAVED_PRODUCTS');
        this.cart.persistTheCart();
        // this.fcm.resetNotification();
        setTimeout(function () {
            _this.cart.setCartData({
                productIds: [],
                products: []
            });
            _this.cart.dispatchCartUpdate();
            _this.notification.notifyForUserDataChange();
            _this.router.navigateByUrl(redirectionUrl || 'home');
            console.log("Redirected to: ", redirectionUrl || 'home');
        }, 300);
        setTimeout(function () {
            delete localStorage['previousUrl'];
        }, 1000);
    };
    DbUpdateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DbUpdateService_Factory() { return new DbUpdateService(i0.ɵɵinject(i1.LocalStorage), i0.ɵɵinject(i2.NotificationService), i0.ɵɵinject(i3.HttpWithAuthService), i0.ɵɵinject(i4.Router), i0.ɵɵinject(i5.CartService)); }, token: DbUpdateService, providedIn: "root" });
    return DbUpdateService;
}());
export { DbUpdateService };
