/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./logout-handler.component";
import * as i2 from "../../project-common/services/db-update.service";
var styles_LogoutHandlerComponent = [];
var RenderType_LogoutHandlerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LogoutHandlerComponent, data: {} });
export { RenderType_LogoutHandlerComponent as RenderType_LogoutHandlerComponent };
export function View_LogoutHandlerComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_LogoutHandlerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "logout-handler", [], null, null, null, View_LogoutHandlerComponent_0, RenderType_LogoutHandlerComponent)), i0.ɵdid(1, 114688, null, 0, i1.LogoutHandlerComponent, [i2.DbUpdateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LogoutHandlerComponentNgFactory = i0.ɵccf("logout-handler", i1.LogoutHandlerComponent, View_LogoutHandlerComponent_Host_0, {}, {}, []);
export { LogoutHandlerComponentNgFactory as LogoutHandlerComponentNgFactory };
