import { Observable } from 'rxjs';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { mergeMapTo, map } from 'rxjs/operators';
import { HttpWithAuthService } from './http-interceptor.service';
import { environment } from '../../../environments/environment';
import { NotificationService } from './notification.service';
import { UtilityService } from './utility.service';
import { Storefront } from '../properties/constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/messaging";
import * as i2 from "./http-interceptor.service";
import * as i3 from "./utility.service";
import * as i4 from "./notification.service";
var FcmService = /** @class */ (function () {
    function FcmService(afMessaging, httpWithAuth, utility, notify) {
        this.afMessaging = afMessaging;
        this.httpWithAuth = httpWithAuth;
        this.utility = utility;
        this.notify = notify;
        this.resetNotification();
    }
    /**
     * To reset the store notifiction object
     */
    FcmService.prototype.resetNotification = function () {
        this.notifications = {
            notifications: [],
            unreadCount: 0,
            totalCount: 0
        };
    };
    /**
     * It will process all the new FCM notification if app is in forground
     * @param notification Incomming FCM notification object
     */
    FcmService.prototype.processNewNotification = function (notification) {
        this.isMobileDevice = this.utility.isMobileDevice();
        // If logedIn then process
        if (JSON.parse(window.localStorage['YUdGelRHOW5aMlZrU1c0PQ=='] || false)) {
            var notificationData = notification.data;
            // If notification to update store notification
            if (notificationData && JSON.parse(notificationData.isStoreNotification || false)) {
                this.isMobileDevice ? this.notify.handleNewNotifications(true) : null;
                this.getStoreNotification();
            }
            // If notification for live chat
            if (notificationData && JSON.parse(notificationData.isLiveChat || false) && notificationData.deepLinkUrl) {
                notificationData.color = '#f88685',
                    this.notify.sendNotification(notificationData.title, notificationData.body, notificationData.icon, notificationData.deepLinkUrl, notificationData.color, 'liveChat');
            }
            // If notification for RFQ Enquiry
            if (notificationData && JSON.parse(notificationData.isEnquiryRFQ || false)) {
                notificationData.color = '#35ac1a',
                    this.notify.sendNotification(notificationData.title, notificationData.body, notificationData.icon, notificationData.deepLinkUrl, notificationData.color, 'rfqEnquiry');
            }
        }
    };
    /**
     * It will ask for permission to send notification and generate fcm token
     */
    FcmService.prototype.requestToken = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.afMessaging.requestPermission
                .pipe(mergeMapTo(_this.afMessaging.tokenChanges))
                .subscribe(function (token) { console.log('Permission granted! Save to the server!', token); resolve(token); }, function (error) { /* console.error(error); */ reject(error); });
        });
    };
    /**
     * This will get triggered on each new FCM notification if app is in forground.
     */
    FcmService.prototype.listenForMessage = function () {
        var _this_1 = this;
        return new Observable(function (observer) {
            _this_1.afMessaging.messages.subscribe(function (message) {
                console.log(message);
                observer.next(message);
                // observer.complete();
            });
        });
    };
    /**
     * Service to map user profileKey and fcm token
     * @param req required payload for put service
     */
    FcmService.prototype.updateDeviceDetails = function (req) {
        var reqPayload = {
            'os': navigator.platform,
            "LUT": new Date().toISOString(),
            'fcmId': req.refreshedToken,
            'appType': 'web',
            'profileKey': req.profileKey ? req.profileKey : '',
            'sourcePortal': Storefront.APP_NAME,
            'isMobileDevice': this.utility.isMobileDevice()
        };
        return this.httpWithAuth.put(environment.baseUrl + "/rest/v2/store/notification/registerdevice", reqPayload).pipe(map(function (response) {
            var result = response;
            return result;
        }, function (error) {
            console.log(error);
        }));
    };
    /**
     * To get user store notification
     */
    FcmService.prototype.getStoreNotification = function () {
        var _this_1 = this;
        var url = environment.baseUrl + "/v2/store/notification/BIZ";
        this.httpWithAuth.get(url)
            .subscribe(function (response) {
            if (response.data.notifications)
                _this_1.notifications = response.data;
        }, function (_a) {
            var error = _a.error;
            throw new Error(error.info);
        });
    };
    /**
     * To mark store notification as read
     * @param reqPayload required payload for read notification
     */
    FcmService.prototype.readStoreNotification = function (reqPayload) {
        var _this_1 = this;
        var url = environment.baseUrl + "/v2/store/notification/read/BIZ";
        return this.httpWithAuth.put(url, reqPayload).pipe(map(function (response) {
            if (response.data.notifications)
                _this_1.notifications = response.data;
            var result = response;
            return result;
        }, function (error) {
            console.log(error);
        }));
    };
    FcmService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FcmService_Factory() { return new FcmService(i0.ɵɵinject(i1.AngularFireMessaging), i0.ɵɵinject(i2.HttpWithAuthService), i0.ɵɵinject(i3.UtilityService), i0.ɵɵinject(i4.NotificationService)); }, token: FcmService, providedIn: "root" });
    return FcmService;
}());
export { FcmService };
