import { Router } from '@angular/router';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-pwa/local-storage";
import * as i2 from "../../authentication/authentication.service";
import * as i3 from "@angular/router";
var CampaignGuard = /** @class */ (function () {
    function CampaignGuard(localStorage, authService, router) {
        this.localStorage = localStorage;
        this.authService = authService;
        this.router = router;
    }
    CampaignGuard.prototype.canActivate = function (next, state) {
        var _this = this;
        if (!next.queryParams.p_key)
            return true;
        var profileKey = atob(next.queryParams.p_key);
        return this.localStorage.getItem('userdata')
            .pipe(map(function (userdata) {
            if (!userdata || (profileKey != userdata.profileKey)) {
                _this.authService.logout('/auth/login');
                return false;
            }
            return true;
        }));
    };
    CampaignGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CampaignGuard_Factory() { return new CampaignGuard(i0.ɵɵinject(i1.LocalStorage), i0.ɵɵinject(i2.AuthenticationService), i0.ɵɵinject(i3.Router)); }, token: CampaignGuard, providedIn: "root" });
    return CampaignGuard;
}());
export { CampaignGuard };
