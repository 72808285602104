import { UtilityService } from './utility.service';
import { WishListService } from './wish-list.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { ToasterService } from './toaster.service';
import { MatSnackBar } from '@angular/material';
import { HttpWithAuthService } from './http-interceptor.service';
import { environment } from '../../../environments/environment';
import { map, finalize } from 'rxjs/operators';
import { GoogleAnalyticsService } from './google-analytics.service';
import { Storefront } from '../properties/constants';
import { Router } from '@angular/router';
import { StorefrontService } from 'src/app/storefront/storefront.service';
import { SpinnerService } from './spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { AppNotifierService } from './app-notifier.service';
import { ScaleImagePipe } from '../pipes/scale-image.pipe';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-pwa/local-storage";
import * as i2 from "./toaster.service";
import * as i3 from "@angular/material/snack-bar";
import * as i4 from "./http-interceptor.service";
import * as i5 from "./utility.service";
import * as i6 from "./wish-list.service";
import * as i7 from "./google-analytics.service";
import * as i8 from "@angular/router";
import * as i9 from "../../storefront/storefront.service";
import * as i10 from "@ngx-translate/core";
import * as i11 from "./app-notifier.service";
import * as i12 from "./spinner.service";
var CartService = /** @class */ (function () {
    function CartService(localStorage, toaster, snackbar, http, util, wishListService, gaTrack, router, store, translator, notifier, spinner) {
        this.localStorage = localStorage;
        this.toaster = toaster;
        this.snackbar = snackbar;
        this.http = http;
        this.util = util;
        this.wishListService = wishListService;
        this.gaTrack = gaTrack;
        this.router = router;
        this.store = store;
        this.translator = translator;
        this.notifier = notifier;
        this.spinner = spinner;
        this.showToast = false;
        this.cartValueChangeSubject = new Subject();
        this.cartValueChanges = this.cartValueChangeSubject.asObservable();
        this.cart = {
            productIds: [],
            products: []
        };
        this.cart$ = new BehaviorSubject(this.cart);
        this.isLoggedIn = JSON.parse(localStorage['YUdGelRHOW5aMlZrU1c0PQ=='] || 'false');
        /* this.addProductToCartById(`03fg`,`03gi`); */
    }
    /**
     * To subscribe to latest cart data
     */
    CartService.prototype.cartUpdates = function () {
        return this.cart$.asObservable();
    };
    CartService.prototype.dispatchCartUpdate = function () {
        var _this = this;
        this.cart$.next(this.cart);
        setTimeout(function () {
            _this.cartValueChangeSubject.next();
            _this.getCartData().subscribe(function (cartData) {
                cartData ? _this.cart = cartData : _this.setCartData();
            });
        }, 300);
    };
    CartService.prototype.getCartData = function () {
        return this.localStorage.getItem('cart');
    };
    CartService.prototype.setCartData = function (cartData) {
        this.localStorage.setItemSubscribe('cart', cartData || this.cart);
    };
    CartService.prototype.removeCartData = function () {
        this.localStorage.setItemSubscribe('cart', this.cart);
    };
    CartService.prototype.addProductToCart = function (product, quantity, showSnackbar) {
        /* if (product.mrp <= 0) {
          this.snackbar.open(`Selected product price is invalid`, '', {
            duration: 2000,
          });
          return false;
        } */
        var _this = this;
        if (showSnackbar === void 0) { showSnackbar = false; }
        this.gaTrack.updateAddToCartToAnalytics(product);
        if (this.isForBulkOrder(product)) {
            this.processForBulkOrder(product);
            return false;
        }
        this.getCartData().subscribe(function (cartData) {
            var isItemAlreadyInCart = false;
            var shouldShowSuccessMessage = true;
            for (var i in cartData.productIds) {
                if (cartData.productIds[i] === product.productLevel3Id) {
                    /* this.translator.get([`Selected product is already there in cart.`]).subscribe(translatedText => {
                      this.snackbar.open(translatedText[`Selected product is already there in cart.`], '', {
                        duration: 2000, panelClass: ['snakbar-color']
                      });
                    })
                    this.showToast = false;
                    return; */
                    isItemAlreadyInCart = true;
                    var totalQuantity = parseInt(cartData.products[i].productQuantity + '') + parseInt(quantity);
                    if (totalQuantity < 11) {
                        cartData.products[i].productQuantity = totalQuantity;
                    }
                    else {
                        shouldShowSuccessMessage = false;
                        cartData.products[i].productQuantity = 10;
                        _this.translator.get('Maximum 10 units can be ordered  ').subscribe(function (text) {
                            _this.snackbar.open(text, 'OK', {
                                duration: 8000
                            });
                        });
                    }
                }
            }
            if (cartData.productIds.length < 50) {
                if (!isItemAlreadyInCart) {
                    product.cartUTS = new Date().toISOString();
                    cartData.productIds.push(product.productLevel3Id);
                    cartData.products.push(product);
                }
                _this.cart = cartData;
                /* console.log(JSON.stringify(cartData)); */
                var items = {
                    productIds: [product.productLevel3Id],
                    products: [product]
                };
                if (!_this.isLoggedIn) {
                    _this.setCartData(cartData);
                    _this.dispatchCartUpdate();
                }
                _this.putItemsInCart(items);
                if (shouldShowSuccessMessage) {
                    _this.showSucessfullMessage(product, showSnackbar);
                }
            }
            else {
                _this.translator.get(['Not allowed', 'Maximum 50 products can be ordered with one transaction', 'info']).subscribe(function (translatedText) {
                    _this.toaster.showMessage(translatedText['Not allowed'], translatedText['Maximum 50 products can be ordered with one transaction'], translatedText['info']);
                });
            }
        });
        return true;
    };
    CartService.prototype.isForBulkOrder = function (product) {
        var isForBulkOrder = false;
        this.cart.products.forEach(function (item) {
            if (item.isBulkOrder) {
                isForBulkOrder = true;
            }
        });
        return isForBulkOrder || product.isBulkOrder;
    };
    CartService.prototype.processForBulkOrder = function (product) {
        var _this = this;
        if (!product.isBulkOrder) {
            this.translator.get(['Normal products can not be ordered along with bulk orders', 'OK']).subscribe(function (translatedText) {
                _this.snackbar.open(translatedText['Normal products can not be ordered along with bulk orders'], translatedText['OK'], {
                    duration: 8000, panelClass: ['snakbar-color']
                });
            });
            return;
        }
        this.getCartData().subscribe(function (cart) {
            if (!cart.products.length) {
                _this.saveProductForBulkOrderToCart(product);
            }
            else {
                _this.processAvailableProductsOnCart(cart, product);
            }
        });
    };
    CartService.prototype.saveProductForBulkOrderToCart = function (product) {
        var cart;
        if (this.cart.products.length && this.cart.products[0].isBulkOrder) {
            cart = this.cart;
            cart.products.push(product);
            cart.productIds.push(product.productLevel3Id);
        }
        else {
            cart = {
                products: [product],
                productIds: [product.productLevel3Id]
            };
        }
        this.cart = cart;
        if (!this.isLoggedIn) {
            this.setCartData(cart);
            this.dispatchCartUpdate();
        }
        this.putItemsInCart(cart);
    };
    CartService.prototype.processAvailableProductsOnCart = function (cart, product) {
        var _this = this;
        var isForBulkOrder = true;
        cart.products.forEach(function (item) {
            if (item.productLevel3Id === product.productLevel3Id) {
                _this.translator.get(['Selected product is already added to cart']).subscribe(function (translatedText) {
                    _this.snackbar.open(translatedText['Selected product is already added to cart'], 'OK', {
                        duration: 6000, panelClass: ['snakbar-color']
                    });
                });
                isForBulkOrder = false;
                return;
            }
            else if (item.isBulkOrder) {
                // this.saveProductForBulkOrderToCart(product);
                isForBulkOrder = true;
                return;
            }
            // this.persistTheCart({'productId': item.productLevel3Id, 'destination': 'SAVED_ITEM'});
            _this.translator.get(['Other products from cart have been saved for later', 'OK']).subscribe(function (translatedText) {
                _this.snackbar.open(translatedText['Other products from cart have been saved for later'], translatedText['OK'], {
                    duration: 6000, panelClass: ['snakbar-color']
                });
            });
        });
        if (isForBulkOrder && product.isBulkOrder) {
            this.saveProductForBulkOrderToCart(product);
        }
    };
    CartService.prototype.showSucessfullMessage = function (product, showSnackbar) {
        var _this = this;
        if (product === void 0) { product = null; }
        if (showSnackbar === void 0) { showSnackbar = false; }
        if (this.util.isMobileDevice() || showSnackbar) {
            this.translator.get(["Sucessfully added to cart"]).subscribe(function (translatedText) {
                _this.snackbar.open(translatedText["Sucessfully added to cart"], '', {
                    duration: 1000, panelClass: ['snakbar-color']
                });
            });
            return;
        }
        if (product) {
            var notification = {
                title: '1 Item Added to Cart!',
                linkTitle: 'View cart',
                imageUrl: ScaleImagePipe.transform(product.productImage, 'sm'),
                message: product.productLevel3Title,
                linkRoute: ['/checkout/cart']
            };
            this.notifier.pushNotification(notification);
        }
        this.showToast = true;
        setTimeout(function () {
            _this.showToast = false;
        }, 6000);
    };
    CartService.prototype.removeProductFromCart = function (product, ignoreAnalyticsUpdate) {
        if (!ignoreAnalyticsUpdate)
            this.gaTrack.updateRemoveFromCartToAnalytics(product);
        if (JSON.parse(localStorage['YUdGelRHOW5aMlZrU1c0PQ=='] || 'false')) {
            this.removeItemsFromCart([product.productLevel3Id]);
            // this.persistTheCart({'productId':product.productLevel3Id,'source':'CART_ITEM'});
        }
        else {
            var index = this.cart.productIds.indexOf(product.productLevel3Id);
            if (index > -1) {
                this.cart.products.splice(index, 1);
                this.cart.productIds.splice(index, 1);
                this.setCartData(this.cart);
                this.dispatchCartUpdate();
            }
        }
    };
    CartService.prototype.batchRemoveFromCart = function (productIds) {
        var _this = this;
        var request = {
            productIds: productIds
        };
        return this.http.put(environment.nodeServer + "/rest/v2/bizstore/cart/batch?store=" + Storefront.APP_NAME, request).pipe(map(function (resposne) {
            var result = resposne;
            if (result.code === 200) {
                _this.cart = result.data.cartItems;
                _this.setCartData(_this.cart);
                _this.dispatchCartUpdate();
            }
            return result;
        }));
    };
    CartService.prototype.updateQuantity = function (product, quantity) {
        if (this.cart.productIds.length) {
            for (var i in this.cart.productIds) {
                if (this.cart.productIds[i] === product.productLevel3Id) {
                    product.productQuantity = quantity;
                    product.store = Storefront.APP_NAME;
                    this.cart.products[i] = product;
                    this.setCartData(this.cart);
                    // this.persistTheCart(this.cart);
                    var items = {
                        productIds: [this.cart.productIds[i]],
                        products: [product]
                    };
                    this.putItemsInCart(items);
                    this.dispatchCartUpdate();
                    break;
                }
            }
        }
    };
    CartService.prototype.increaseQuantity = function (productLevel3Id) {
        if (this.cart.productIds.length) {
            for (var i in this.cart.productIds) {
                if (this.cart.productIds[i] === productLevel3Id) {
                    var product = this.cart.products[i];
                    product.productQuantity++;
                    product.store = Storefront.APP_NAME;
                    this.cart.products[i] = product;
                    this.setCartData(this.cart);
                    // this.persistTheCart(this.cart);
                    var items = {
                        productIds: [this.cart.productIds[i]],
                        products: [product]
                    };
                    this.putItemsInCart(items);
                    this.dispatchCartUpdate();
                    break;
                }
            }
        }
    };
    CartService.prototype.decreaseQuantity = function (product) {
        if (this.cart.productIds.length) {
            for (var i = 0; i < this.cart.productIds.length; i++) {
                if (this.cart.productIds[i] === product.productLevel3Id) {
                    product.productQuantity--;
                    product.store = Storefront.APP_NAME;
                    var items = {
                        productIds: [this.cart.productIds[i]],
                        products: [product]
                    };
                    if (product.productQuantity >= 1) {
                        if (!this.isLoggedIn) {
                            this.cart.products[i] = product;
                            this.setCartData(this.cart);
                        }
                        this.putItemsInCart(items);
                    }
                    else {
                        if (!this.isLoggedIn) {
                            this.cart.products.splice(i, 1);
                            this.cart.productIds.splice(i, 1);
                            this.setCartData(this.cart);
                            this.dispatchCartUpdate();
                        }
                        this.removeItemsFromCart([this.cart.productIds[i]]);
                    }
                    // this.persistTheCart(this.cart);
                    this.dispatchCartUpdate();
                    break;
                }
            }
        }
    };
    CartService.prototype.getPersistedCartData = function () {
        var _this = this;
        if (this.cart.productIds.length) {
            this.putCartAfterLogin(this.cart);
        }
        else {
            this.getCartItem().subscribe(function (response) {
                if (response.code == 200 || response.code == 204) {
                    var items = response.data || {};
                    _this.setCartData(items.cartItems);
                    _this.dispatchCartUpdate();
                    _this.wishListService.setSaveProductInLocalDb(items.savedItems);
                }
            });
        }
    };
    CartService.prototype.prepareReqCartObj = function () {
        var reqObj;
        if (this.cart.productIds.length) {
            reqObj = {
                "data": this.cart,
            };
            return reqObj;
        }
        return reqObj;
    };
    CartService.prototype.persistTheCart = function (cart) {
        var _this = this;
        if (JSON.parse(localStorage['YUdGelRHOW5aMlZrU1c0PQ=='] || 'false')) {
            var url = environment.nodeServer + "/rest/v2/bizstore/cart?store=" + Storefront.APP_NAME;
            this.spinner.changeStatus(true, false);
            this.http.put(url, cart || { 'data': this.cart, 'destination': 'CART_ITEM' })
                .pipe(finalize(function () { return _this.spinner.stop(); }))
                .subscribe(function (data) {
                var response = data;
                if (response.code === 200 && response.data) {
                    var items = (response.data);
                    _this.setCartData(items.cartItems);
                    _this.dispatchCartUpdate();
                    _this.wishListService.setSaveProductInLocalDb(items.savedItems);
                    _this.wishListService.dispatchUpdate('true');
                }
            });
        }
    };
    CartService.prototype.getProcessedLevel3Product = function (level3Product, level2Product) {
        if (!level3Product.pricePerUnit) {
            return;
        }
        var seller = level2Product.resellerDetails || level2Product.manufacturerDetails;
        level3Product.sellerId = seller.profileKey;
        level3Product.sellerName = seller.firstName;
        level3Product.sellerProfileUrl = '';
        level3Product.store = Storefront.APP_NAME;
        level3Product.productCategory = level2Product.baseCategory.id;
        if (level3Product.handlingCost) {
            level3Product.handlingCost = parseFloat(level3Product.handlingCost);
        }
        if (level3Product.packagingCost) {
            level3Product.packagingCost = parseFloat(level3Product.packagingCost);
        }
        if (level3Product.shippingCost) {
            level3Product.shippingCost = parseFloat(level3Product.shippingCost);
        }
        try {
            // level3Product.pricePerUnit = level3Product.productLevel2Id === '332e' ? '500' : level3Product.pricePerUnit;
            level3Product.pricePerUnit = parseFloat(level3Product.pricePerUnit);
        }
        catch (e) {
            /* console.log('Invalid price'); */
            return;
        }
        delete level3Product.LUT;
        delete level3Product.attachments;
        delete level3Product.color;
        delete level3Product.createdTS;
        delete level3Product.s3Path;
        delete level3Product.shape;
        delete level3Product.size;
        delete level3Product.availableSmartElement;
        delete level3Product.displayName;
        delete level3Product.weight;
        return level3Product;
    };
    /**
     * Get the cart items of logedIn user
     */
    CartService.prototype.getCartItem = function () {
        var url = environment.nodeServer + "/rest/v2/bizstore/cart?store=" + Storefront.APP_NAME;
        return this.http.get(url).pipe(map(function (response) {
            return response;
        }, function (error) {
            console.error(error);
            throw new Error('Unable to get cart items');
        }));
    };
    CartService.prototype.putCartAfterLogin = function (cartData) {
        var req = {
            data: cartData
        };
        this.persistTheCart(req);
    };
    /**
     * Put items in cart
     * @param cartData obj in form { products : [], productIds : [] }
     */
    CartService.prototype.putItemsInCart = function (cartData) {
        var req = {
            data: cartData,
            destination: 'CART_ITEM'
        };
        this.persistTheCart(req);
    };
    /**
     * Put items in savedList
     * @param cartData obj in form { products : [], productIds : [] }
     */
    CartService.prototype.putItemsInSavedList = function (cartData) {
        var req = {
            data: cartData,
            destination: 'SAVED_ITEM'
        };
        this.persistTheCart(req);
    };
    /**
     * Move items in cart from saved list
     * @param productIds Array of productIds
     */
    CartService.prototype.moveItemsIncart = function (productIds) {
        var req = {
            productIds: productIds,
            destination: 'CART_ITEM'
        };
        this.persistTheCart(req);
    };
    /**
     * Move items in saved list from cart
     * @param productIds Array of productIds
     */
    CartService.prototype.moveItemsInSavedList = function (productIds) {
        var req = {
            productIds: productIds,
            destination: 'SAVED_ITEM'
        };
        this.persistTheCart(req);
    };
    /**
     * Remove items from cart
     * @param productIds Array of productIds
     */
    CartService.prototype.removeItemsFromCart = function (productIds) {
        var req = {
            productIds: productIds,
            source: 'CART_ITEM'
        };
        this.persistTheCart(req);
    };
    /**
     * Remove items from savedList
     * @param productIds Array of productIds
     */
    CartService.prototype.removeItemsFromSavedList = function (productIds) {
        var req = {
            productIds: productIds,
            source: 'SAVED_ITEM'
        };
        this.persistTheCart(req);
    };
    CartService.prototype.hasValidSmartElementAndPrice = function (product) {
        if (!product.pricePerUnit) {
            return false;
        }
        var p = product.smartElements;
        if (!p.colour && !p.weight && !p.sizeByName && !p.sizeByNumber && !p.packagingType && !p.itemType &&
            !p.shape && !p.patterAndDesign && !this.hasSomeDimension(p.dimension) && !this.hasSomeDimension(p.packagingDimension) && !p.materialAndFabric && !p.itemCount) {
            return false;
        }
        return true;
    };
    CartService.prototype.hasSomeDimension = function (dimension) {
        if (dimension === void 0) { dimension = {}; }
        return dimension.height || dimension.length || dimension.width;
    };
    /**
     * Add items in cart from custom packs page
     * AND delete existing item in cart
     * Don't try with bulk SKU
     * @param {any} products processed l3 data for cart
     */
    CartService.prototype.addItemInCartFromCustomPackPage = function (products, isBuyNow) {
        if (products.length >= Storefront.MAX_CART_LIMIT) {
            this.snackbar.open("Max cart limit is only " + Storefront.MAX_CART_LIMIT, '', {
                duration: 2000, panelClass: ['snakbar-color']
            });
            this.showToast = false;
            return;
        }
        /**
         * Need to override the existing cart.
         */
        var cartData = {
            productIds: [],
            products: []
        };
        products.forEach(function (p) {
            if (p.productQuantity > 0) {
                p.cartUTS = JSON.stringify(new Date());
                cartData.productIds.push(p.productLevel3Id);
                cartData.products.push(p);
            }
        });
        this.cart = cartData;
        if (!this.isLoggedIn) {
            this.setCartData(cartData);
            this.dispatchCartUpdate();
        }
        /**
         * Move the existing item in saved list
         */
        this.putCartAfterLogin(this.cart);
        this.showSucessfullMessage();
        if (isBuyNow) {
            this.router.navigate(['/checkout', 'cart']);
        }
        /*
        this.getCartData().subscribe(cartData => {
    
          if ((products.length + cartData.productIds) > Storefront.MAX_CART_LIMIT) {
            this.snackbar.open(`${cartData.productIds.length} items already available in cart, you are trying to add ${products.length} more items in cart,max limit is ${Storefront.MAX_CART_LIMIT}`, '', {
              duration: 2000,
            });
            this.showToast = false;
            return;
          }
        }); */
    };
    /**
     * To add product in cart by taking l2Id and l3Id
     * @param l2Id
     * @param l3Id
     */
    CartService.prototype.addProductToCartById = function (l2Id, l3Id) {
        var _this = this;
        this.getCartData().subscribe(function (cartData) {
            if (cartData && cartData.productIds && cartData.productIds.includes(l3Id)) {
                _this.increaseQuantity(l3Id);
                var index = cartData.productIds.indexOf(l3Id);
                _this.showSucessfullMessage(cartData.products[index]);
                // this.snackbar.open(`Selected product is already there in cart.`, '', {
                //   duration: 2000, panelClass: ['snakbar-color']
                // });
                // this.showToast = false;
                // return;
            }
            else {
                _this.store.getFullViewData(l2Id).subscribe(function (level2Data) {
                    level2Data.level3ProductsList.forEach(function (level3Product) {
                        if (level3Product.productLevel3Id === l3Id) {
                            var attachments = level3Product.attachments && level3Product.attachments.attachments && level3Product.attachments.attachments.length ? level3Product.attachments.attachments : [{ url: 'assets/icons/No_Image.jpg', context: '', msgType: 'IMAGE' }];
                            var moveToCartProduct = _this.getProcessedLevel3Product(level3Product, level2Data);
                            moveToCartProduct.productQuantity = '1';
                            moveToCartProduct.productImage = attachments[0].url;
                            var isMovedToCart = _this.addProductToCart(moveToCartProduct, '1', false);
                        }
                    });
                });
            }
        });
    };
    CartService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CartService_Factory() { return new CartService(i0.ɵɵinject(i1.LocalStorage), i0.ɵɵinject(i2.ToasterService), i0.ɵɵinject(i3.MatSnackBar), i0.ɵɵinject(i4.HttpWithAuthService), i0.ɵɵinject(i5.UtilityService), i0.ɵɵinject(i6.WishListService), i0.ɵɵinject(i7.GoogleAnalyticsService), i0.ɵɵinject(i8.Router), i0.ɵɵinject(i9.StorefrontService), i0.ɵɵinject(i10.TranslateService), i0.ɵɵinject(i11.AppNotifierService), i0.ɵɵinject(i12.SpinnerService)); }, token: CartService, providedIn: "root" });
    return CartService;
}());
export { CartService };
