/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pincode-request-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/cdk/bidi";
import * as i4 from "@angular/flex-layout/core";
import * as i5 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "@angular/material/dialog";
import * as i10 from "@angular/common";
import * as i11 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i12 from "@angular/material/icon";
import * as i13 from "./pincode-request-dialog.component";
import * as i14 from "@angular/forms";
import * as i15 from "../../services/spinner.service";
import * as i16 from "../../services/user-subscriber.service";
var styles_PincodeRequestDialogComponent = [i0.styles];
var RenderType_PincodeRequestDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PincodeRequestDialogComponent, data: {} });
export { RenderType_PincodeRequestDialogComponent as RenderType_PincodeRequestDialogComponent };
function View_PincodeRequestDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "p", [["class", "font-22 p-10 m-0"], ["fxLayoutAlign", "start center"], ["fxLayoutGap", "10px"]], null, null, null, null, null)), i1.ɵdid(1, 1720320, null, 0, i2.DefaultLayoutGapDirective, [i1.ElementRef, i1.NgZone, i3.Directionality, i4.StyleUtils, [2, i2.LayoutGapStyleBuilder], i4.MediaMarshaller], { fxLayoutGap: [0, "fxLayoutGap"] }, null), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i4.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i4.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-check-circle fa-2x text-success"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Yay! We are shipping to "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["\"", "\""]))], function (_ck, _v) { var currVal_0 = "10px"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "start center"; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = ((_co.data == null) ? null : _co.data.pincode); _ck(_v, 7, 0, currVal_2); }); }
function View_PincodeRequestDialogComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "p", [["class", "font-22"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Sorry! fruits and vegetables are unavailable for "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["\"", "\""])), (_l()(), i1.ɵted(-1, null, [" at this moment "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.data == null) ? null : _co.data.pincode); _ck(_v, 3, 0, currVal_0); }); }
function View_PincodeRequestDialogComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Explore the taste of Andhra pradesh across Spices, Pulses, Pickles, Herbal & Other Food Items !"])), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["fxLayoutAlign", "center center"]], null, null, null, null, null)), i1.ɵdid(4, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i4.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i4.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 3, "button", [["color", "warn"], ["mat-dialog-close", ""], ["mat-stroked-button", ""], ["type", "button"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).dialogRef.close(i1.ɵnov(_v, 7).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(6, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵdid(7, 606208, null, 0, i9.MatDialogClose, [[2, i9.MatDialogRef], i1.ElementRef, i9.MatDialog], { type: [0, "type"], dialogResult: [1, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["OK"]))], function (_ck, _v) { var currVal_0 = "center center"; _ck(_v, 4, 0, currVal_0); var currVal_5 = "warn"; _ck(_v, 6, 0, currVal_5); var currVal_6 = "button"; var currVal_7 = ""; _ck(_v, 7, 0, currVal_6, currVal_7); }, function (_ck, _v) { var currVal_1 = (i1.ɵnov(_v, 6).disabled || null); var currVal_2 = (i1.ɵnov(_v, 6)._animationMode === "NoopAnimations"); var currVal_3 = (i1.ɵnov(_v, 7).ariaLabel || null); var currVal_4 = i1.ɵnov(_v, 7).type; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
function View_PincodeRequestDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PincodeRequestDialogComponent_2)), i1.ɵdid(2, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PincodeRequestDialogComponent_3)), i1.ɵdid(4, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PincodeRequestDialogComponent_4)), i1.ɵdid(6, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.data == null) ? null : _co.data.pincodeAvalilable); _ck(_v, 2, 0, currVal_0); var currVal_1 = !((_co.data == null) ? null : _co.data.pincodeAvalilable); _ck(_v, 4, 0, currVal_1); var currVal_2 = (!((_co.data == null) ? null : _co.data.pincodeAvailable) && _co.pincodeRequestForm); _ck(_v, 6, 0, currVal_2); }, null); }
function View_PincodeRequestDialogComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "p", [["class", "font-22 p-10 m-0"], ["fxLayoutAlign", "start center"], ["fxLayoutGap", "10px"]], null, null, null, null, null)), i1.ɵdid(2, 1720320, null, 0, i2.DefaultLayoutGapDirective, [i1.ElementRef, i1.NgZone, i3.Directionality, i4.StyleUtils, [2, i2.LayoutGapStyleBuilder], i4.MediaMarshaller], { fxLayoutGap: [0, "fxLayoutGap"] }, null), i1.ɵdid(3, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i4.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i4.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-check-circle fa-2x text-success"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Request submitted successfully "]))], function (_ck, _v) { var currVal_0 = "10px"; _ck(_v, 2, 0, currVal_0); var currVal_1 = "start center"; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_PincodeRequestDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PincodeRequestDialogComponent_1)), i1.ɵdid(1, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PincodeRequestDialogComponent_5)), i1.ɵdid(3, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 5, "button", [["class", "dialog-close"], ["mat-dialog-close", ""], ["mat-icon-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).dialogRef.close(i1.ɵnov(_v, 6).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(5, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(6, 606208, null, 0, i9.MatDialogClose, [[2, i9.MatDialogRef], i1.ElementRef, i9.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵeld(7, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i11.View_MatIcon_0, i11.RenderType_MatIcon)), i1.ɵdid(8, 9158656, null, 0, i12.MatIcon, [i1.ElementRef, i12.MatIconRegistry, [8, null], [2, i12.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.requestedSuccessfully; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.requestedSuccessfully; _ck(_v, 3, 0, currVal_1); var currVal_6 = ""; _ck(_v, 6, 0, currVal_6); _ck(_v, 8, 0); }, function (_ck, _v) { var currVal_2 = (i1.ɵnov(_v, 5).disabled || null); var currVal_3 = (i1.ɵnov(_v, 5)._animationMode === "NoopAnimations"); var currVal_4 = (i1.ɵnov(_v, 6).ariaLabel || null); var currVal_5 = i1.ɵnov(_v, 6).type; _ck(_v, 4, 0, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_7 = i1.ɵnov(_v, 8).inline; var currVal_8 = (((i1.ɵnov(_v, 8).color !== "primary") && (i1.ɵnov(_v, 8).color !== "accent")) && (i1.ɵnov(_v, 8).color !== "warn")); _ck(_v, 7, 0, currVal_7, currVal_8); }); }
export function View_PincodeRequestDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "pincode-request-dialog", [], null, null, null, View_PincodeRequestDialogComponent_0, RenderType_PincodeRequestDialogComponent)), i1.ɵdid(1, 114688, null, 0, i13.PincodeRequestDialogComponent, [i9.MAT_DIALOG_DATA, i14.FormBuilder, i15.SpinnerService, i16.UserSubscriberService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PincodeRequestDialogComponentNgFactory = i1.ɵccf("pincode-request-dialog", i13.PincodeRequestDialogComponent, View_PincodeRequestDialogComponent_Host_0, {}, {}, []);
export { PincodeRequestDialogComponentNgFactory as PincodeRequestDialogComponentNgFactory };
