import * as i0 from "@angular/core";
var CanDeactivateGuardService = /** @class */ (function () {
    function CanDeactivateGuardService() {
    }
    CanDeactivateGuardService.prototype.canDeactivate = function (component, route, state) {
        var url = state.url;
        console.log('Url: ' + url);
        return component.canDeactivate ? component.canDeactivate() : true;
    };
    CanDeactivateGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CanDeactivateGuardService_Factory() { return new CanDeactivateGuardService(); }, token: CanDeactivateGuardService, providedIn: "root" });
    return CanDeactivateGuardService;
}());
export { CanDeactivateGuardService };
