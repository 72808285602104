import { HttpWithAuthService } from './http-interceptor.service';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./http-interceptor.service";
var SearchService = /** @class */ (function () {
    function SearchService(httpWithAuth) {
        this.httpWithAuth = httpWithAuth;
    }
    SearchService.prototype.search = function (keyword, request, baseCategory, l2, l3, l4) {
        var url = environment.baseUrl;
        request = request || {};
        // https://www.kalgudi.com/rest/v1/store/bizstore/productsearch?keyword=shirt&offset=0&limit=20&category=
        url += "/v2/search/bizstore/products?keyword=" + keyword + "&offset=" + (request.offset || 0) + "&limit=" + (request.limit || 10) + "&category=" + (baseCategory || '') + "&l2Id=" + l2 + "&l3Id=" + l3 + "&l4Id=" + l4;
        url = url + "&store=ANDHRAGREEN";
        return this.httpWithAuth.get(url).pipe(map(function (data) {
            return data;
        }));
    };
    SearchService.prototype.loadMore = function (request, from, category) {
        var url = environment.baseUrl;
        request = request || {};
        url += "/v2/search/bizstore/products?from=" + from + "&offset=" + (request.offset || 0) + "&limit=" + (request.limit || 10) + "&category=" + category;
        return this.httpWithAuth.get(url).pipe(map(function (data) {
            return data;
        }));
    };
    SearchService.prototype.getSuggessions = function (keyword, request, category) {
        if (category === void 0) { category = ""; }
        var url = environment.baseUrl;
        request = request || {};
        // http://192.168.1.65/rest/v1/store/bizstore/autocomplete/storeproduct?keyword=a&offset=0&limit=20
        url += "/rest/v1/store/biz/autocomplete/bizstoreproduct?keyword=" + keyword + "&offset=" + (request.offset || 0) + "&limit=" + (request.limit || 10);
        url = url + "&store=ANDHRAGREEN&category=" + category;
        return this.httpWithAuth.get(url).pipe(map(function (res) {
            return res;
        }));
    };
    SearchService.prototype.searchUsers = function (_a) {
        var keyword = _a.keyword, offset = _a.offset, limit = _a.limit;
        var url = environment.baseUrl;
        url += "/rest/v1/search/profile?q=" + keyword + "&offset=" + offset + "&limit=" + limit;
        return this.httpWithAuth.get(url).pipe(map(function (res) {
            var result = res;
            // Parsing all individual users here instead of doing it in consumers of this method
            var _a = result.data, data = _a === void 0 ? [] : _a; // Destructuring data from result object
            result.data = data.map(function (i) { return JSON.parse(i); });
            return result;
        }));
    };
    SearchService.prototype.searchProducts = function (keyword, profileKey, limit, offset) {
        if (limit === void 0) { limit = 20; }
        if (offset === void 0) { offset = 0; }
        var url = environment.baseUrl + "/v2/search/farmstore/products?keyword=" + keyword + "&offset=" + offset + "&limit=" + limit + "&sellerId=" + profileKey;
        return this.httpWithAuth.get(url).pipe(map(function (res) { return res; }));
    };
    SearchService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SearchService_Factory() { return new SearchService(i0.ɵɵinject(i1.HttpWithAuthService)); }, token: SearchService, providedIn: "root" });
    return SearchService;
}());
export { SearchService };
