var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HttpClient } from '@angular/common/http';
import { AssistantRole } from 'src/app/project-common/models/requirement.model';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { environment } from './../../environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpWithAuthService } from '../project-common/services/http-interceptor.service';
import { NotificationService } from '../project-common/services/notification.service';
import { DbUpdateService } from '../project-common/services/db-update.service';
import { CartService } from '../project-common/services/cart.service';
import { QuotationService } from '../quotation/quotation.service';
import { FcmService } from '../project-common/services/fcm.service';
import { Storefront } from '../project-common/properties/constants';
import * as i0 from "@angular/core";
import * as i1 from "../project-common/services/http-interceptor.service";
import * as i2 from "@angular/common/http";
import * as i3 from "@ngx-pwa/local-storage";
import * as i4 from "../project-common/services/db-update.service";
import * as i5 from "../project-common/services/notification.service";
import * as i6 from "../project-common/services/cart.service";
import * as i7 from "../project-common/services/fcm.service";
import * as i8 from "../quotation/quotation.service";
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(httpWithAuth, http, localStorage, updateDB, notification, cart, fcmService, quotationService) {
        var _this = this;
        this.httpWithAuth = httpWithAuth;
        this.http = http;
        this.localStorage = localStorage;
        this.updateDB = updateDB;
        this.notification = notification;
        this.cart = cart;
        this.fcmService = fcmService;
        this.quotationService = quotationService;
        this.countryCode = '+91';
        this.signupObject = {
            name: '',
            mobileTelecomCode: '',
            mobileNumber: '',
            email: '',
            expressSignup: true,
            profileTypeId: 'PT000006',
            profileTypeName: 'I\'ll tell later',
            businessTypeId: 'BT000012',
            businessTypeName: 'I\'ll tell later',
            nativeBusinessTypeId: 'BT000012',
            nativeBusinessTypeName: 'I\'ll tell later',
            businessName: '',
            organizationName: '',
            password: '12345678',
            address: 'Hyderabad, Telangana, India',
            googleLocationTo: {
                locality: 'Hyderabad',
                adminLevel2: 'Hyderabad',
                adminLevel1: 'Telangana',
                countryName: 'India',
                latitude: '17.385044',
                longitude: '78.486671'
            },
            lstOfProducts: [],
            studentDetails: {
                universityName: '',
                universityKey: '',
                degreeName: '',
                courseId: '',
                courseName: '',
                courseStartYear: '',
                courseEndYear: ''
            },
            industryId: '',
            companyName: '',
            jobDescription: '',
            sourcePortal: Storefront.APP_NAME
        };
        this.localStorage.getItem('userdata').subscribe(function (response) {
            if (response) {
                _this.userData = response;
                _this.getProfileDataFromDynamoDB(response.profileKey, true).subscribe(function (userDataOnStorage) {
                });
            }
        });
        this.notification.userDataChanges.subscribe(function () {
            _this.localStorage.getItem('userdata').subscribe(function (response) {
                if (response) {
                    _this.userData = response;
                }
            });
        });
    }
    AuthenticationService.prototype.getCountryCode = function () {
        var _this = this;
        if (!this.countryDetails$) {
            this.countryDetails$ = new BehaviorSubject(null);
            this.localStorage.getItem('countryDetails').subscribe(function (country) {
                if (country) {
                    _this.countryDetails$.next(country);
                }
                else {
                    _this.countryDetailsApi().subscribe(function (country) {
                        _this.localStorage.setItemSubscribe('countryDetails', country);
                        _this.localStorage.setItemSubscribe('countryId', country.MobileTelecomcode);
                        _this.localStorage.setItemSubscribe('mobileTelecomCode', country.MobileTelecomcode);
                        _this.countryDetails$.next(country);
                    });
                }
            });
        }
        return new Observable(function (observer) {
            _this.countryDetails$.asObservable().subscribe(function (country) {
                if (country) {
                    observer.next(country.MobileTelecomcode);
                    observer.complete();
                }
            });
        });
    };
    AuthenticationService.prototype.countryDetailsApi = function () {
        var url = environment.baseUrl + "/rest/v1/profiles/coudtls";
        return this.http.get(url).pipe(map(function (res) { return JSON.parse(res.data); }));
    };
    AuthenticationService.prototype.login = function (request) {
        var _this = this;
        var url = environment.baseUrl + "/v2/auth/login";
        request.password = btoa(request.password);
        return this.http.post(url, request).pipe(map(function (response) {
            _this.redirectionUrl = _this.httpWithAuth.redirectUrl || window.localStorage['blockedUrl'];
            if (response.code === 200) {
                // setting 'tk' , 'c' in localStorage to share the login information b/w core app & store.
                var userData = response.data.userBasicDetail;
                var credentials = JSON.parse(JSON.stringify(request));
                credentials.userName = userData.mobileNo;
                localStorage.setItem('tk', btoa(JSON.stringify(response.data.auth)));
                userData.password = atob(request.password);
                credentials.password = userData.password;
                localStorage.setItem('c', btoa(JSON.stringify(credentials)));
                _this.updateAuthenticationFlags(userData);
                _this.updateAssistantLocalStorage(request.userName);
            }
            return response;
        }));
    };
    AuthenticationService.prototype.updateAuthenticationFlags = function (userData) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.httpWithAuth.isLoggedIn = true;
                        this.httpWithAuth.userData = userData;
                        window.localStorage['YUdGelRHOW5aMlZrU1c0PQ=='] = true;
                        window.localStorage[btoa('profileKey')] = btoa(userData.profileKey);
                        return [4 /*yield*/, this.httpWithAuth.setImediateHeaders(userData.mobileNo, userData.password)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AuthenticationService.prototype.updateAssistantLocalStorage = function (email) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.quotationService.getAssistants().subscribe(function (response) {
                    var salesManagers = response.salesManagers;
                    var salesTeam = response.salesTeam;
                    var bosLeads = response.bosLeads;
                    var bosTeam = response.bosTeam;
                    var contributors = response.contributors;
                    var salesPartners = response.salesPartners;
                    var tmpEmail = email.includes('@kalgudi.com') ? email.replace('@kalgudi.com', '@vasudhaika.net') : email.replace('@vasudhaika.net', '@kalgudi.com');
                    // console.log(email,'--------',tmpEmail);
                    var assistant = salesManagers.find(function (manager) { return manager.email === email || manager.email === tmpEmail; });
                    if (assistant) {
                        assistant.role = AssistantRole.MANAGER;
                        localStorage.setItem('assistant', JSON.stringify(assistant));
                        return;
                    }
                    assistant = bosTeam.find(function (supportGuy) { return supportGuy.email === email || supportGuy.email === tmpEmail; });
                    if (assistant) {
                        assistant.role = AssistantRole.BOS;
                        localStorage.setItem('assistant', JSON.stringify(assistant));
                    }
                    assistant = bosLeads.find(function (lead) { return lead.email === email || lead.email === tmpEmail; });
                    if (assistant) {
                        assistant.role = AssistantRole.LEAD;
                        localStorage.setItem('assistant', JSON.stringify(assistant));
                        return;
                    }
                    assistant = salesTeam.find(function (salesman) { return salesman.email === email || salesman.email === tmpEmail; });
                    if (assistant) {
                        assistant.role = AssistantRole.SALESMAN;
                        localStorage.setItem('assistant', JSON.stringify(assistant));
                        return;
                    }
                    // Product contributor
                    assistant = contributors.find(function (contributor) { return contributor.email === email; });
                    if (assistant) {
                        assistant.role = AssistantRole.CONTRIBUTOR;
                        localStorage.setItem('assistant', JSON.stringify(assistant));
                        return;
                    }
                    // Sales Patner
                    assistant = salesPartners.find(function (partner) { return partner.email === email; });
                    if (assistant) {
                        assistant.role = AssistantRole.SALESPARTNER;
                        localStorage.setItem('assistant', JSON.stringify(assistant));
                        return;
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    AuthenticationService.prototype.getProfileDataFromDynamoDB = function (profileKey, updateIndexDB) {
        var _this = this;
        var url = environment.baseUrl + "/rest/v1/profiles/" + profileKey;
        return this.httpWithAuth.get(url).pipe(map(function (data) {
            var response = data;
            response = JSON.parse(response.data);
            if (updateIndexDB) {
                _this.updateDB.updateUserData(response);
            }
            return response;
        }));
    };
    AuthenticationService.prototype.getProfileDataFromS3 = function (profileKey) {
        var url = environment.baseUrl + "/data/profiles/" + profileKey;
        return this.httpWithAuth.get(url).pipe(map(function (data) {
            var response = data;
            return response;
        }));
    };
    AuthenticationService.prototype.signup = function (request) {
        var url = environment.baseUrl + "/v2/auth/simplesignup";
        this.signupObject.email = request.email;
        this.signupObject.mobileTelecomCode = request.countryCode;
        this.signupObject.mobileNumber = (request.email ? '' : request.countryCode) + request.mobile;
        this.signupObject.name = request.name;
        return this.http.post(url, this.signupObject).pipe(map(function (data) {
            return data;
        }));
    };
    AuthenticationService.prototype.verifyOtp = function (request) {
        var url = environment.baseUrl + "/rest/v1/profiles/expresssignin";
        return this.http.post(url, request).pipe(map(function (data) {
            return data;
        }));
    };
    AuthenticationService.prototype.logout = function (navigationPath) {
        if (environment.production)
            this.processFcm();
        this.updateDB.clearUserData(navigationPath);
    };
    AuthenticationService.prototype.generateOtpToRecoverPassword = function (request) {
        return this.http.post(environment.baseUrl + "/rest/v1/profiles/forgotpwdgenkey", request).pipe(map(function (data) {
            return data;
        }, function (error) {
            throw Error('Server is busy: ' + error);
        }));
    };
    AuthenticationService.prototype.recoverPassword = function (request) {
        return this.http.post(environment.baseUrl + "/rest/v1/profiles/forgotpwdverify", request).pipe(map(function (data) {
            return data;
        }, function (error) {
            throw Error('Server is busy: ' + error);
        }));
    };
    AuthenticationService.prototype.processFcm = function (profileKey) {
        var tokenKey = window.localStorage['fcmToken'];
        if (tokenKey && environment.production)
            this.fcmService.updateDeviceDetails({ refreshedToken: tokenKey, profileKey: profileKey ? profileKey : '' }).subscribe(function (res) {
                // console.log('Device details updated successfully ');
            });
    };
    AuthenticationService.prototype.checkAssistanceEligibility = function (profileKey) {
        var url = environment.baseUrl + "/rest/v1/farmerstore/assist?profileKey=" + profileKey;
        return this.httpWithAuth.get(url).pipe(map(function (response) {
            var result = response;
            return result;
        }));
    };
    /**
       *
       * @param req expected object link { emailId : '', mobileNo: '' } ( either one or both fields )
       * @param otpLogin
       */
    AuthenticationService.prototype.verifyRegistration = function (req, otpLogin) {
        var url = environment.baseUrl + "/rest/v1/profiles/isRegistered";
        var otp = otpLogin && otpLogin === 'OTP' ? '&otpLogin=1' : '';
        if (req.emailId && req.mobileNo) {
            url += "?mobileNo=" + req.mobileNo + "&emailId=" + req.emailId + otp;
        }
        else if (req.emailId) {
            url += "?emailId=" + req.emailId + otp;
        }
        else if (req.mobileNo) {
            url += "?mobileNo=" + req.mobileNo + otp;
        }
        return this.httpWithAuth.get(url).pipe(map(function (response) {
            var result = response;
            return result;
        }));
    };
    AuthenticationService.prototype.assistedSignup = function (request) {
        this.signupObject.mobileNumber = "+91" + request.mobileNumber;
        this.signupObject.name = request.firstName;
        this.signupObject.mobileTelecomCode = '+91';
        if (request.address)
            this.signupObject.address = request.address;
        if (request.googleLocationTo)
            this.signupObject.googleLocationTo = request.googleLocationTo;
        return this.generateOtpForSignup(this.signupObject);
    };
    AuthenticationService.prototype.generateOtpForSignup = function (request) {
        var url = environment.baseUrl + "/v2/auth/simplesignup";
        return this.http.post(url, request);
    };
    AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.HttpWithAuthService), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.LocalStorage), i0.ɵɵinject(i4.DbUpdateService), i0.ɵɵinject(i5.NotificationService), i0.ɵɵinject(i6.CartService), i0.ɵɵinject(i7.FcmService), i0.ɵɵinject(i8.QuotationService)); }, token: AuthenticationService, providedIn: "root" });
    return AuthenticationService;
}());
export { AuthenticationService };
