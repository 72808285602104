<div fxHide.xs>
    <div class="search-wrapper" fxLayoutAlign="center center">
        <div class="search-container" fxFlex>
            <form>
                <input type="text" placeholder="{{ 'Search here...' | translate }}" class="searchbar" [(ngModel)]="searchText" (keyup.enter)="keyEnter()" [formControl]="myControl" [matAutocomplete]="auto" (keyup)="autoComplete(searchText, $event)" matInput (keydown)="keydown()"
                />
                <mat-autocomplete #auto="matAutocomplete" class="AC">
                    <div *ngIf="isTrue">
                        <mat-option *ngFor="let option of products" [value]="option.doc" (click)="check(option.doc)">
                            <span [innerHTML]="option.doc.productLevel2Title"></span> in
                            <span style="color: #0060a9;">{{
                option.doc.baseCategory.value.toLowerCase()
              }}</span>
                        </mat-option>
                    </div>
                </mat-autocomplete>
            </form>
        </div>
        <div class="categories">
           <!-- <span fxLayoutAlign="center center">
                <span translate class="selected-category">{{ selectedCategory?.value || 'All Categories'}}</span> <i class="fa fa-angle-down ml-5" aria-hidden="true"></i>
            </span>
            <div class="categories-list mat-elevation-z5">
                <div matRipple class="cat-list-item" (click)="selectedCategory = {}" translate>All Categories</div>
                <ng-container *ngFor="let category of categories$ | async">
                    <div matRipple class="cat-list-item" (click)="selectedCategory = category" translate>
                        {{ category?.value | lowercase }}
                    </div>
                </ng-container>
            </div>-->
        </div>
        <div class="search-btn" fxLayout (click)="search(searchText)">
            <mat-icon>search</mat-icon>
        </div>
    </div>
</div>

<div fxHide fxShow.xs class="search-overlay-section" *ngIf="showSearch">
    <div class="search-placeholder" fxLayout>
        <div class="back-nav" fxLayoutAlign="center center">
            <mat-icon (click)="showSearch = false">arrow_back</mat-icon>
        </div>
        <input id="search-input" type="text" style="padding: 0 16px;" [(ngModel)]="searchText" (keyup.enter)="keyEnter()" placeholder="Search for products..." fxFlex [matAutocomplete]="auto" (keyup)="autoComplete(searchText, $event)" matInput (keydown)="keydown()"
        />
        <div class="back-nav" fxLayoutAlign="center center" (click)="searchText = ''">
            <i class="fa fa-times-circle" style="font-size: 20px;" aria-hidden="true"></i>
        </div>
        <mat-autocomplete #auto="matAutocomplete">
            <div *ngIf="isTrue">
                <mat-option *ngFor="let option of products" [value]="option.doc.productLevel2Title" (click)="check(option.doc)">
                    <div fxLayout fxLayoutGap="5px;">
                        <div>
                            <mat-icon style="font-size: 20px;width: 24px;color: #777">search</mat-icon>
                        </div>
                        <div class="cut-text pl-10">
                            <span class="search-text" [innerHTML]="option.doc.productLevel2Title"></span> in
                            <span style="color: #0060a9;">{{
                option.doc.baseCategory.value.toLowerCase()
              }}</span>
                        </div>
                    </div>
                </mat-option>
            </div>
        </mat-autocomplete>
    </div>
    <div mat-subheader *ngIf="recentSearches">
        Recent Search History
    </div>
    <mat-list dense>
        <div matRipple class="p-9" *ngFor="let search of recentSearches" (click)="searchText = search; keyEnter()">
            {{search}}
        </div>
    </mat-list>
    <div mat-subheader>
        Trending
    </div>
    <mat-list dense>
        <div matRipple class="p-9" fxLayoutAlign="space-between" *ngFor="let trending of suggestedCategory" (click)="searchText = trending; keyEnter()">
            {{trending}}
            <img src="assets/icons/north-west.svg" width="20">
        </div>
    </mat-list>
</div>