import { OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';

/**
 * Base class definition for all classes which require resource cleanup. It
 * implements `OnDestroyed` interface provided by angular core.
 *
 * It defines an observable `destroyed$` that gets emitted when the component is destroyed.
 * Override the method `onDestroyed()` to perform any memory cleanup.
 *
 * @author Pankaj Prakash
 */
export abstract class KalgudiDestroyable implements OnDestroy {

  protected destroyed$: Observable<any>;

  private readonly destroyedSubject = new Subject();

  constructor() {
    this.destroyed$ = this.destroyedSubject.asObservable();
  }

  /**
   * Called once, before the instance is destroyed.
   * Internally it calls the onDestroyed() method.
   */
  ngOnDestroy(): void {
    this.destroyedSubject.next();
    this.destroyedSubject.complete();

    this.onDestroyed();
  }

  /**
   * Implement this method to clean up resources
   */
  protected abstract onDestroyed(): void;
}
