import { OnInit, EventEmitter } from '@angular/core';
var CartInfoComponent = /** @class */ (function () {
    function CartInfoComponent() {
        this.deleteProduct = new EventEmitter();
    }
    CartInfoComponent.prototype.ngOnInit = function () {
    };
    Object.defineProperty(CartInfoComponent.prototype, "cartItemsTotal", {
        get: function () {
            if (!this.cart)
                return 0;
            return this.cart.products.reduce(function (total, p) { return (total + (p.pricePerUnit * +p.productQuantity)); }, 0);
        },
        enumerable: true,
        configurable: true
    });
    return CartInfoComponent;
}());
export { CartInfoComponent };
