export const Storefront = {
    offerStarts: new Date(2019, 2, 1, 0, 0, 0), // 5 Mar 2019
    offerEnds  : new Date(2019, 2, 15, 23, 59, 59), // 15th Mar 2019
    freeShippingLimit: 999,
    MIN_CART_AMOUNT: 350,
    MAX_CART_LIMIT: 50,
    APP_NAME: `APGREEN`,
    promotionItem: {
        level2Id:'334g', 
        level3Id: '334h'
    },
    limitedShippingCategory: ['FRUITS', 'VEGETABLES', 'FRUITS_AND_VEGETABLES'],
    limitedShippingProducts: ['37pu'],
    emailRegEx: /^(?:[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[A-Za-z0-9-]*[A-Za-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/
}

export const ThirdPartyLibraries = {
    'instamojo' : 'https://js.instamojo.com/v1/checkout.js',
    'photoswipe': 'assets/lib/photoswipe-4.1.2/photoswipe.min.js',
    'photoswipeDefaultSkin': 'assets/lib/photoswipe-4.1.2/photoswipe-ui-default.min.js',
    'cordova' : 'cordova.js',
    // 'Materialize': 'assets/lib/materialize.min.js',

    'googleFontsCss': 'assets/fonts/google/fonts.css',
    // 'fontAwesomeCss': 'assets/lib/font-awesome-4.7.0/css/font-awesome.min.css',
    'photoswipeCss': 'assets/lib/photoswipe-4.1.2/photoswipe.min.css',
    'photoswipeDefaultSkinCss': 'assets/lib/photoswipe-4.1.2/default-skin.min.css',
    'courgetteFontFamily': 'https://fonts.googleapis.com/css?family=Courgette&display=swap',
    'PTSansNarrowFont': 'https://fonts.googleapis.com/css?family=PT+Sans+Narrow',
    'AkronimFont': 'https://fonts.googleapis.com/css?family=Akronim',
    'RobotoFont': 'https://fonts.google.com/specimen/Roboto?query=roboto',
    'PoppinsFont': 'https://fonts.google.com/specimen/Poppins?query=Poppins'
}
