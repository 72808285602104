import { HttpWithAuthService } from '../project-common/services/http-interceptor.service';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { map, switchMap } from 'rxjs/operators';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { S3UploadService } from '../project-common/services/s3-upload.service';
import { DbUpdateService } from '../project-common/services/db-update.service';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "../project-common/services/http-interceptor.service";
import * as i2 from "@ngx-pwa/local-storage";
import * as i3 from "../project-common/services/s3-upload.service";
import * as i4 from "../project-common/services/db-update.service";
import * as i5 from "@angular/common/http";
var ProfileService = /** @class */ (function () {
    function ProfileService(httpWithAuth, storage, s3, updateDB, httpClient) {
        this.httpWithAuth = httpWithAuth;
        this.storage = storage;
        this.s3 = s3;
        this.updateDB = updateDB;
        this.httpClient = httpClient;
        this.getMyWishList().subscribe();
        // this.validPins$ = new BehaviorSubject<string[]>([]);
    }
    /**
     * To get loggedin user data from index DB
     */
    ProfileService.prototype.getUserDataFromLocalStorage = function () {
        return this.storage.getItem('userdata');
    };
    /**
     * To get list of save adderess, which can be used to complete the chekcout proccess.
     *
     * @param profileKey Loggedin user's profile key
     */
    ProfileService.prototype.getSavedAddresses = function (profileKey) {
        var url = environment.nodeServer + "/rest/v2/bizstore/profiles/addresses";
        if (profileKey) {
            url += "?profileKey=" + profileKey;
        }
        return this.httpWithAuth.get(url).pipe(map(function (response) {
            var result = response;
            return result;
        }));
    };
    /**
     * To save new address or to update the existing address.
     *
     * @param profileKey Loggedin user's profile key
     * @param requestPayload ShippingForm models object, containing user address
     */
    ProfileService.prototype.saveAddress = function (profileKey, requestPayload) {
        var url = environment.nodeServer + "/rest/v2/bizstore/profiles/addresses";
        if (profileKey) {
            url += "?profileKey=" + profileKey;
        }
        if (requestPayload.id) {
            return this.httpWithAuth.
                put(url, requestPayload).pipe(map(function (resposne) {
                var result = resposne;
                return result;
            }));
        }
        else {
            return this.httpWithAuth.
                post(url, requestPayload).pipe(map(function (resposne) {
                var result = resposne;
                return result;
            }));
        }
    };
    /**
     * To delete an address from profile
     *
     * @param profileKey Loggedin user's profile key
     * @param addressId Will be id of address, which user wants to delete from his profile
     */
    ProfileService.prototype.removeAddress = function (profileKey, addressId) {
        var url = environment.nodeServer + "/rest/v2/bizstore/profiles/addresses/" + addressId;
        if (profileKey) {
            url += "?profileKey=" + profileKey;
        }
        return this.httpWithAuth
            .delete(url).pipe(map(function (resposne) {
            var result = resposne;
            return result;
        }));
    };
    /**
     * To change the password
     * @param profileKey Loggedin user's profile key
     * @param requestPayload PasswordForm object containing currentPassword and newPassword
     */
    ProfileService.prototype.updatePassword = function (profileKey, requestPayload) {
        return this.httpWithAuth.
            put(environment.baseUrl + "/rest/v1/profiles/" + profileKey + "/Password", requestPayload).pipe(map(function (resposne) {
            var result = resposne;
            return result;
        }));
    };
    /**
     * To update the location object of a user
     * @param profileKey Profile key of the user for whom you want to update the loaction on his profile
     * @param businessKey Default business key of the user for whom you want to update the location on his profile
     * @param requestPayload A GoogleLocationTo object, see google-autocomple.ts for reference
     */
    ProfileService.prototype.updateLocation = function (profileKey, businessKey, requestPayload) {
        var _this = this;
        var url = environment.baseUrl + "/rest/v1/profiles/" + profileKey + "/Businesses/" + businessKey + "/gglLocation";
        return this.httpWithAuth.put(url, requestPayload)
            .pipe(switchMap(function (resposne) {
            var result = resposne;
            if (result.code === 201) {
                return _this.getProfileDataFromDynamoDB(profileKey);
            }
            throwError('Unable to update the location!');
        }));
    };
    ProfileService.prototype.getProfileDataFromDynamoDB = function (profileKey) {
        var _this = this;
        var url = environment.baseUrl + "/rest/v1/profiles/" + profileKey;
        return this.httpWithAuth.get(url).pipe(map(function (data) {
            var response = data;
            response = JSON.parse(response.data);
            _this.updateDB.updateUserData(response);
            return response;
        }));
    };
    ProfileService.prototype.setDefaultAddress = function (profileKey, id) {
        return this.httpWithAuth.
            put(environment.nodeServer + "/rest/v2/store/profiles/addresses/" + id, {}).pipe(map(function (resposne) {
            var result = resposne;
            return result;
        }));
    };
    /**
     * To upload image file to S3 and get a S3Url as response
     * @param profileKey ProfileKey of the user who is uploading the image to S3
     * @param file Image file object
     */
    ProfileService.prototype.uploadToS3 = function (profileKey, file, filePath) {
        var fileName = "data/profilepics/" + profileKey + ".png";
        var s3FolderName = 'profilepic';
        if (filePath) {
            // Cover pic upload
            fileName = "" + filePath + profileKey + ".png";
            s3FolderName = 'profilepic';
        }
        return this.s3.uploadImage(file, fileName, s3FolderName).pipe(map(function (response) {
            response += ('?' + new Date().getTime().toString());
            return response;
        }));
    };
    /**
     * To update the profile picture of a user
     * @param profileKey Profile key of the user for whom you want to update the profile pic URL
     * @param s3Url Image location on S3
     */
    ProfileService.prototype.updateImageOnProfile = function (profileKey, s3Url) {
        var requestUrl = environment.baseUrl + "/rest/v1/profiles/" + profileKey + "/profilePic";
        return this.httpWithAuth.post(requestUrl, s3Url).pipe(map(function (response) {
            return response;
        }));
    };
    /**
     * To get the limited set of orders placed by user
     * @param type default value is empty string
     * @param offset default value is 0
     * @param limit default value is 100
     */
    ProfileService.prototype.getOrdersList = function (type, offset, limit, searchKeyword, searchType) {
        if (type === void 0) { type = ''; }
        if (offset === void 0) { offset = 0; }
        if (limit === void 0) { limit = 100; }
        if (searchKeyword === void 0) { searchKeyword = ''; }
        if (searchType === void 0) { searchType = ''; }
        var requestUrl = environment.baseUrl + "/rest/v1/apgreen/orders?type=" + type + "&limit=" + limit + "&offset=" + offset + "&searchKeyword=" + searchKeyword + "&searchType=" + searchType;
        return this.httpWithAuth.get(requestUrl).pipe(map(function (response) {
            return response;
        }));
    };
    /**
     * To get the complete details about an order
     * @param orderId of placed order for which full detail is required
     */
    ProfileService.prototype.getOrderDetails = function (orderId, orderStatus) {
        var sufix = orderStatus ? '?isMasterOrder=true' : '';
        var requestUrl = environment.baseUrl + "/rest/v1/apgreen/orders/" + (orderId + sufix);
        return this.httpWithAuth.get(requestUrl).pipe(map(function (response) {
            return response;
        }));
    };
    /**
     * To generate an invoice of order and to get an encoded URL of S3 url
     * @param orderId of placed order for which you want to generate invoice
     */
    ProfileService.prototype.generateInvoice = function (orderId, reportName) {
        var requestUrl = environment.baseUrl + "/rest/v1/apgreen/orders/" + orderId + "/reports?reportName=" + reportName;
        return this.httpWithAuth.post(requestUrl, {}).pipe(map(function (response) {
            return response;
        }));
    };
    /**
     * To download various reports from S3 using filename.
     * @param fileName available on S3, which can be found by decoding response of @generateInvoice method
     */
    ProfileService.prototype.downloadInvoice = function (fileName) {
        var requestUrl = environment.baseUrl + "/rest/download?filename=" + fileName;
        return this.httpClient.get(requestUrl, { responseType: 'text' });
    };
    /**
     * To submit a review for product.
     * @param productId for which you want to submit the review
     * @param requestPayload should consist of rating and review along with reviewer details and product details
     */
    ProfileService.prototype.submitReview = function (productId, requestPayload) {
        var requestUrl = environment.baseUrl + "/rest/v2/bizstore/review/product/" + productId;
        // const requestUrl = `${environment.nodeServer}/rest/v2/bizstore/review/product/${productId}`;
        return this.httpWithAuth.post(requestUrl, requestPayload).pipe(map(function (response) {
            return response;
        }));
    };
    ProfileService.prototype.getMyWishList = function () {
        var _this = this;
        return new Observable(function (observer) {
            if (_this.myWishlist) {
                observer.next(_this.myWishlist);
                observer.complete();
            }
            else {
                _this.storage.getItem('userWishList').subscribe(function (wishlist) {
                    _this.myWishlist = JSON.parse(wishlist || '{}');
                    observer.next(_this.myWishlist);
                    observer.complete();
                });
            }
        });
    };
    /**
     * To get all submitted reviews of a user
     * @param profileKey of user, whose review list we wanted to get
     */
    ProfileService.prototype.getMyReviews = function (profileKey) {
        var requestUrl = environment.nodeServer + "/rest/v2/bizstore/review/myreview/products";
        return this.httpWithAuth.get(requestUrl).pipe(map(function (response) {
            return response;
        }, function (error) {
            throw new Error('No reviews yet');
        }));
    };
    ProfileService.prototype.getChatParticipants = function (orderId) {
        var requestUrl = environment.baseUrl + "/rest/v1/bizstore/orders/" + orderId + "/participants";
        return this.httpWithAuth.get(requestUrl).pipe(map(function (response) {
            return response;
        }));
    };
    ProfileService.prototype.getChatMessages = function (orderId) {
        var requestUrl = environment.baseUrl + "/rest/v1/bizstore/orders/" + orderId + "/chat?threadId=" + orderId + "_Buyer_Group";
        return this.httpWithAuth.get(requestUrl).pipe(map(function (response) {
            return response;
        }));
    };
    ;
    ProfileService.prototype.postMessage = function (orderId, payload) {
        var requestUrl = environment.baseUrl + "/rest/v1/bizstore/orders/" + orderId + "/chat";
        return this.httpWithAuth.post(requestUrl, payload).pipe(map(function (response) {
            return response;
        }));
    };
    ;
    /**
    * To update the personal Information of a user
    * @param profileKey Profile key of the user for whom you want to update the loaction on his profile
    * @param requestPayload A personaInfo object
    */
    ProfileService.prototype.updatePersonalInfo = function (profileKey, requestPayload) {
        return this.httpWithAuth.
            put(environment.baseUrl + "/rest/v1/profiles/" + profileKey, requestPayload).pipe(map(function (resposne) {
            var result = resposne;
            return result;
        }));
    };
    ProfileService.prototype.getUpdates = function (req) {
        // https://kalgudi.com/rest/v1/stream/socialactivity?profileKey=M01l4lt0PRFREG2019021316021151UNH001&offset=0&limit=10
        var requestUrl = environment.baseUrl + "/rest/v1/stream/socialactivity?profileKey=" + req.profileKey + "&offset=" + req.offset + "&limit=" + req.limit;
        return this.httpWithAuth.get(requestUrl).pipe(map(function (response) {
            return response;
        }));
    };
    ;
    /**
     * To update the profile picture of a user
     * @param profileKey Profile key of the user for whom you want to update the profile pic URL
     * @param s3Url Image location on S3
     */
    ProfileService.prototype.updateCoverPicOnProfile = function (profileKey, req) {
        var requestUrl = environment.baseUrl + "/rest/v1/profiles/" + profileKey + "/coverPic";
        return this.httpWithAuth.put(requestUrl, req).pipe(map(function (response) {
            return response;
        }));
    };
    ProfileService.prototype.getShareAthought = function (profileKey) {
        // https://kalgudi.com/rest/v1/stream/share/search/shareAthought?profileKey=M00j48o0PRFREG2017020640673778UNH001
        var requestUrl = environment.baseUrl + "/rest/v1/stream/share/search/shareAthought?profileKey=" + profileKey;
        return this.httpWithAuth.get(requestUrl).pipe(map(function (response) {
            return response;
        }));
    };
    ProfileService.prototype.getQAndA = function (profileKey) {
        // https://kalgudi.com/rest/v1/stream/qa/search/QandA?profileKey=M00j48o0PRFREG2017020640673778UNH001
        var requestUrl = environment.baseUrl + "/rest/v1/stream/share/search/QandA?profileKey=" + profileKey;
        return this.httpWithAuth.get(requestUrl).pipe(map(function (response) {
            return response;
        }));
    };
    ProfileService.prototype.updateRfqAssistants = function (payLoad) {
        var url = environment.nodeServer + "/v2/bizstore/profiles/assistants";
        return this.httpWithAuth.
            put(url, payLoad).pipe(map(function (resposne) {
            var result = resposne;
            return result;
        }));
    };
    ProfileService.prototype.userGallary = function (profileKey) {
        // https://dashboard.kalgudi.com/v1/dashboards/gallary/M00kd9h0PRFREG2017122443013063UNH001
        var requestUrl = "https://dashboard.kalgudi.com/v1/dashboards/gallary/" + profileKey;
        return this.httpWithAuth.get(requestUrl).pipe(map(function (response) {
            return response;
        }));
    };
    /**
     *
     * @param entityId To the respective page details of a seller
     */
    ProfileService.prototype.getEntityDetails = function (entityId) {
        var requestUrl = environment.baseUrl + "/rest/v1/entities/common/getentity?entityId=" + entityId;
        return this.httpWithAuth.get(requestUrl).pipe(map(function (response) {
            return response;
        }));
    };
    /**
     *
     * @param profileKey seller profileKey to get parent organization details
     */
    ProfileService.prototype.getParentorganizationDetails = function (profileKey) {
        var requestUrl = environment.baseUrl + "/rest/v1/profiles/myParent?profileKey=" + profileKey;
        return this.httpWithAuth.get(requestUrl).pipe(map(function (response) { return response; }));
    };
    ProfileService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProfileService_Factory() { return new ProfileService(i0.ɵɵinject(i1.HttpWithAuthService), i0.ɵɵinject(i2.LocalStorage), i0.ɵɵinject(i3.S3UploadService), i0.ɵɵinject(i4.DbUpdateService), i0.ɵɵinject(i5.HttpClient)); }, token: ProfileService, providedIn: "root" });
    return ProfileService;
}());
export { ProfileService };
