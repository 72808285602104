import { BehaviorSubject } from "rxjs";
import { UtilityService } from "./utility.service";
import * as i0 from "@angular/core";
import * as i1 from "./utility.service";
var CategoryService = /** @class */ (function () {
    function CategoryService(util) {
        this.util = util;
        this.categoriesSubject = new BehaviorSubject([]);
        this.categoryCardsSubject = new BehaviorSubject(categoryCardMap);
        this.categories$ = this.categoriesSubject.asObservable();
        this.categoriesSubject.next(categoriesMenu /* .sort((c1, c2) => c2.count - c1.count) */);
        this.categoryCards$ = this.categoryCardsSubject.asObservable();
    }
    CategoryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CategoryService_Factory() { return new CategoryService(i0.ɵɵinject(i1.UtilityService)); }, token: CategoryService, providedIn: "root" });
    return CategoryService;
}());
export { CategoryService };
var categoriesMenu = [
    { "id": "SWEET_SNACKS_AND_SAVORIES", "name": "Sweets, Snacks & Savories", "count": 21, "subCategory": [{ "id": "SWEETS", "name": "Sweets", "count": 10, "subCategory": [] }, { "id": "SNACKS", "name": "Snacks", "count": 11, "subCategory": [] }] },
    { "id": "FRUITS_AND_VEGETABLES", "name": "Fruits & Vegetables", "count": 18, "subCategory": [{ "id": "FRUITS", "name": "Fruits", "count": 13, "subCategory": [] }, { "id": "VEGETABLES", "name": "Vegetables", "count": 5, "subCategory": [] }] },
    { "id": "SPICES", "name": "Spices", "count": 68, "subCategory": [{ "id": "POWDERED_MASALAS", "name": "Powder & Masala's", "count": 53, "subCategory": [] }, { "id": "WHOLE_SPICES", "name": "Whole Spices", "count": 14, "subCategory": [] }] },
    { "id": "DRY_FRUITS_NUTS_AND_SEEDS", "name": "Dry Fruits, Nuts & Seeds", "count": 16, "subCategory": [{ "id": "DRY_FRUITS", "name": "Dry Fruits", "count": 9, "subCategory": [] }, { "id": "EDIBLE_SEEDS", "name": "Edible Seeds", "count": 7, "subCategory": [] }] },
    { "id": "CEREALS_PULSES_AND_MILLETS", "name": "Cereals, Pulses, Millets....", "count": 48, "subCategory": [{ "id": "RAVA", "name": "Rava/Rawa", "count": 11, "subCategory": [] }, { "id": "PULSES", "name": "Pulses", "count": 12, "subCategory": [] }, { "id": "CEREALS", "name": "Cereals", "count": 9, "subCategory": [] }, { "id": "MILLETS", "name": "Millets", "count": 4, "subCategory": [] }, { "id": "FLOURS", "name": "Flours", "count": 4, "subCategory": [] }, { "id": "FLAKES", "name": "Flakes", "count": 8, "subCategory": [] }] },
    { "id": "PICKLES_AND_PRESERVES", "name": "Pickles & Preserves", "count": 26, "subCategory": [{ "id": "PICKLES", "name": "Pickles", "count": 26, "subCategory": [] }] },
    { "id": "HEALTH_AND_HERBAL", "name": "Health & Herbal", "count": 40, "subCategory": [{ "id": "SOAP_SHAMPOO_AND_BATH_POWDER", "name": "Soap, Shampoo & Bath Powder", "count": 25, "subCategory": [] }, { "id": "HERBAL_MEDICINE", "name": "Herbal Medicine", "count": 10, "subCategory": [] }, { "id": "HERBAL_POOJA_ARTICLES", "name": "Herbal Pooja Articles", "count": 3, "subCategory": [] }, { "id": "HERBAL_DRINKS", "name": "Herbal Drinks", "count": 2, "subCategory": [] }] },
    { "id": "OIL_AND_OIL_SEEDS", "name": "Oil & Oil Seeds", "count": 26, "subCategory": [{ "id": "OILS", "name": "Oils", "count": 24, "subCategory": [] }, { "id": "OIL_SEEDS", "name": "Oil Seeds", "count": 2, "subCategory": [] }] },
    { "id": "BEVERAGES", "name": "Beverages", "count": 4, "subCategory": [{ "id": "COFFEE", "name": "Coffee", "count": 4, "subCategory": [] }] },
    { "id": "FOREST_PRODUCTS", "name": "Forest Products", "count": 2, "subCategory": [{ "id": "TAMARIND", "name": "Tamarind", "count": 1, "subCategory": [] }, { "id": "HONEY", "name": "Honey", "count": 1, "subCategory": [] }] }
];
var categoryCardMap = {
    SWEET_SNACKS_AND_SAVORIES: {
        id: "SWEET_SNACKS_AND_SAVORIES",
        name: "Sweets, Snacks & Savories",
        title: "100% Healthy",
        imgUrl: "assets/category-card-bg/biscuits.jpg",
    },
    FRUITS_AND_VEGETABLES: {
        id: "FRUITS_AND_VEGETABLES",
        name: "Fruits & Vegetables",
        title: "Fruits & Vegetables",
        imgUrl: "assets/category-card-bg/others.png",
        backgroundColor: '#3bc44f'
    },
    SPICES: {
        id: "SPICES",
        name: "Spices",
        title: "All spicy flavors available",
        imgUrl: "assets/category-card-bg/spices.jpg",
    },
    DRY_FRUITS_NUTS_AND_SEEDS: {
        id: "DRY_FRUITS_NUTS_AND_SEEDS",
        name: "Dry Fruits, Nuts & Seeds",
        title: "Healthy nuts & seeds",
        imgUrl: "assets/category-card-bg/nuts.jpg",
    },
    CEREALS_PULSES_AND_MILLETS: {
        id: "CEREALS_PULSES_AND_MILLETS",
        name: "Cereals, Pulses, Millets",
        title: "Cereals, Pulses, Millets",
        imgUrl: "assets/category-card-bg/others.png",
        backgroundColor: '#e6ab08'
    },
    PICKLES_AND_PRESERVES: {
        id: "PICKLES_AND_PRESERVES",
        name: "Pickles & Preserves",
        title: "Pickles & Preserves",
        imgUrl: "assets/category-card-bg/others.jpg",
        backgroundColor: 'chocolate'
    },
    HEALTH_AND_HERBAL: {
        id: "HEALTH_AND_HERBAL",
        name: "Health & Herbal",
        title: "Pure herbal",
        imgUrl: "assets/category-card-bg/herbal.jpg",
    },
    OIL_AND_OIL_SEEDS: {
        id: "OIL_AND_OIL_SEEDS",
        name: "Oil & Oil Seeds",
        title: "Oil & Oil Seeds",
        imgUrl: "assets/category-card-bg/others.png",
        backgroundColor: 'olive'
    },
    BEVERAGES_OTHERS: {
        id: "BEVERAGES",
        name: "Beverages & Others",
        title: "Beverages & Others",
        imgUrl: "assets/category-card-bg/others.jpg",
    },
};
