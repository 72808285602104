<app-route-progress-bar></app-route-progress-bar>

<div>
  <app-header></app-header>

  <div
    class="spinner-wrapper"
    [class.non-blocking]="!isBlockingSpinner"
    *ngIf="isSpinnerActive"
  >
    <div class="spinnner">
      <div class="baar"></div>
      <div class="baar"></div>
      <div class="baar"></div>
      <div class="baar"></div>
    </div>
  </div>

  <!-- Notification popUp for lie chat in RFQ -->
  <custom-notification-popup fxHide.xs *ngIf="isUserLoggedIn"></custom-notification-popup>

  <div class="fixed-outlet">
    <router-outlet></router-outlet>
  </div>
  <!-- footer -->
  <app-footer></app-footer>
  <ngx-toasta [position]="'left-bottom'"></ngx-toasta>

  <!-- <app-back-to-top></app-back-to-top> -->
  <side-links></side-links>


  <!-- Kalgudi photoswipe Don't remove it-->
  <!-- Single place photoswipe injection -->
  <kl-photoswipe></kl-photoswipe>
  <!-- / Kalgudi photoswipe -->
</div>

<notifier fxHide.xs></notifier>