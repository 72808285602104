var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Location } from '@angular/common';
import { ElementRef, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { fromEvent, Observable, BehaviorSubject, merge } from 'rxjs';
import { distinctUntilChanged, map, startWith, skip, tap, delay, switchMap, filter, takeUntil } from 'rxjs/operators';
import { PincodeRequestDialogComponent, } from 'src/app/project-common/components/pincode-request-dialog/pincode-request-dialog.component';
import { DeliveryAddressService } from 'src/app/project-common/services/delivery-address.service';
import { UtilityService } from 'src/app/project-common/services/utility.service';
import { environment } from '../../../environments/environment';
import { NotificationService } from '../../project-common/services/notification.service';
import { StorefrontService } from '../storefront.service';
import { AuthenticationService } from './../../authentication/authentication.service';
import { CartService } from './../../project-common/services/cart.service';
import { TranslateService } from '@ngx-translate/core';
import { KalgudiDestroyable } from 'src/app/classes/kalgudi-destroyable';
var HeaderComponent = /** @class */ (function (_super) {
    __extends(HeaderComponent, _super);
    function HeaderComponent(dialog, storage, cartService, notification, authService, router, location, util, storeService, deliveryAddressService, translator) {
        var _this = _super.call(this) || this;
        _this.dialog = dialog;
        _this.storage = storage;
        _this.cartService = cartService;
        _this.notification = notification;
        _this.authService = authService;
        _this.router = router;
        _this.location = location;
        _this.util = util;
        _this.storeService = storeService;
        _this.deliveryAddressService = deliveryAddressService;
        _this.translator = translator;
        _this.searchPlaceHolder = 'Search here ... ';
        _this.showSearch = false;
        _this.showSideNav = false;
        _this.showTopHeader = false;
        _this.isKalgudiTeam = false;
        _this.showDeliveryLocationSelection = false;
        _this.shippingMsgDialog = new BehaviorSubject(false);
        _this.shippingMessage = "";
        return _this;
    }
    HeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isMobileDevice = this.util.isMobileDevice();
        this.availableLanguage = {
            en: 'English',
            te: 'తెలుగు',
        };
        this.selectedLanguage = localStorage['lang'];
        this.getUserData();
        this.notification.userDataChanges
            .pipe(takeUntil(this.destroyed$))
            .subscribe(function () {
            _this.getUserData();
        });
        this.cartService.cartValueChanges
            .pipe(takeUntil(this.destroyed$))
            .subscribe(function (response) {
            _this.updateCartData();
        });
        this.cartService.dispatchCartUpdate();
        this.storeService
            .isSaleOpen()
            .subscribe(function (status) { return (_this.isSaleOpen = status); });
        /**
         * Notifier to indicate user got a new notification
         */
        this.notification.isNewNotification.subscribe(function (res) {
            _this.newNotification = res;
            setTimeout(function () {
                _this.newNotification = false;
            }, 10000);
        });
        this.deliveryCheckForm = {
            pinCode: '',
        };
        /* if (!localStorage.getItem('isVisitedBefore')) {
                this.showTopHeader = true;
                setTimeout(() => {
                    this.showTopHeader = false;
                }, 10000);
                localStorage.setItem('isVisitedBefore', 'visited');
            } */
        this.updateBulkCartItemCount();
        this.notification.getBulkOrderProducts().subscribe(function (products) {
            _this.updateBulkCartItemCount();
        });
        // delete window.localStorage['offers'];
        // this.hasValidOffers = this.util.checkOfferValidity();
        this.notification.languageChanges
            .pipe(takeUntil(this.destroyed$))
            .subscribe(function (language) {
            _this.selectedLanguage = language;
        });
        // this.getCategoriesMenu();
        this.initHeaderCollapse();
        this.handleDeliveryLocationChanges();
    };
    HeaderComponent.prototype.handleDeliveryLocationChanges = function () {
        var _this = this;
        this.deliveryLocationSelected$ = this.deliveryAddressService.deliveryLocationSelected$;
        var deliveryPin = '';
        this.deliveryLocationSelected$
            .pipe(takeUntil(this.destroyed$), skip(1), distinctUntilChanged(), switchMap(function (deliveryLocationSelected) {
            deliveryPin = deliveryLocationSelected.postalCode || deliveryLocationSelected;
            return _this.storeService.deliverablePinCodes$;
        }), filter(function (validPins) { return !!(validPins && validPins.length); }), tap(function (validPins) {
            var allProductsDeliverable = validPins.includes(deliveryPin);
            _this.translator.get(['Yay ! Complete range of Andhragreens products including Fruits & Vegetables are available in your delivery location', 'All Andhragreens products except Fruits & Vegetables are available in your delivery location']).subscribe(function (translatedText) {
                _this.shippingMessage = allProductsDeliverable
                    ? translatedText['Yay ! Complete range of Andhragreens products including Fruits & Vegetables are available in your delivery location']
                    : translatedText['All Andhragreens products except Fruits & Vegetables are available in your delivery location'];
            });
            localStorage.setItem("canShipToLocation", JSON.stringify(allProductsDeliverable));
            _this.shippingMsgDialog.next(true);
        }), delay(5000), tap(function () { return _this.shippingMsgDialog.next(false); })).subscribe();
    };
    HeaderComponent.prototype.initHeaderCollapse = function () {
        var _this = this;
        var scrollEvent$ = fromEvent(window, 'scroll', { passive: true });
        this.headerCollapseChange$ = scrollEvent$.pipe(startWith(false), map(function () {
            if (!_this.headerSec)
                return false;
            var offsetTop = _this.headerSec.nativeElement.offsetTop;
            var scrollHeight = window.scrollY;
            return scrollHeight > offsetTop;
        }), distinctUntilChanged());
        this.mobileHeaderCollapse$ = merge.apply(void 0, [scrollEvent$, this.router.events]).pipe(startWith(false), map(function () {
            var scrollHeight = window.scrollY;
            return (scrollHeight > 80) || !_this.homePage;
        }), distinctUntilChanged());
    };
    HeaderComponent.prototype.onDestroyed = function () { };
    // convertToArray (data) {
    //     const arr = [];
    //     for (const key in data) {
    //         if (data[key].subCategories) {
    //             data[key].subCategories = this.convertToArray(data[key].subCategories);
    //         }
    //         const obj = {
    //             'id': data[key].id,
    //             'subCategories' : data[key].subCategories,
    //             'showSubCategories': false,
    //             'name': data[key].value
    //         };
    //         arr.push(obj);
    //     }
    //     return arr;
    // }
    HeaderComponent.prototype.updateBulkCartItemCount = function () {
        var bulkCart = JSON.parse(localStorage['bulkCart'] || '[]');
        var itemCount = 0;
        bulkCart.forEach(function (shgGroup) {
            itemCount += shgGroup.products.length;
        });
        this.bulkCartItems = itemCount;
    };
    HeaderComponent.prototype.getSwitchLanguageImage = function (languageCode) {
        switch (languageCode) {
            case 'en':
                return "url(assets/icons/header/english.svg)";
            case 'te':
                return "url(assets/icons/header/telugu.png)";
        }
    };
    /**
     * To scroll into an anchor
     *
     * @param categoryId unique id for category
     */
    HeaderComponent.prototype.navigateTo = function (categoryId) {
        var _this = this;
        // this.scroller.scrollToAnchor(categoryId);
        if (this.router.url.length > 1) {
            setTimeout(function () {
                _this.startScroll(categoryId);
            }, 3000);
            this.router.navigate(['/']);
        }
        else {
            this.startScroll(categoryId);
        }
    };
    HeaderComponent.prototype.startScroll = function (categoryId) {
        var top = document.getElementById(categoryId).offsetTop;
        window.scrollTo({
            top: top - 150,
            behavior: 'smooth',
            left: 0,
        });
    };
    HeaderComponent.prototype.switchLanguage = function (languageCode) {
        this.selectedLanguage = languageCode;
        this.notification.notifyForLanguageChange(languageCode);
    };
    HeaderComponent.prototype.searchForCategoryMobile = function (request) {
        this.showSideNav = false;
        if (request.isBaseCategory) {
            this.router.navigateByUrl('product-list/' + request.category);
        }
        else {
            var navigationExtras = {
                queryParams: request,
            };
            navigationExtras.queryParams.limit = 20;
            navigationExtras.queryParams.view = 'grid';
            this.router.navigate(['/search', request.keyword], navigationExtras);
        }
    };
    HeaderComponent.prototype.updateCartData = function () {
        var _this = this;
        this.cartService.getCartData().subscribe(function (cartData) {
            _this.cart = cartData;
            _this.cartCount();
        });
    };
    HeaderComponent.prototype.search = function (request) {
        // console.log(request.keyword);
        if (!request.keyword)
            return;
        var navigationExtras = {
            queryParams: {
                category: request.category,
                limit: 20,
                view: 'grid',
            },
        };
        this.router.navigate(['/search', request.keyword], navigationExtras);
    };
    HeaderComponent.prototype.getUserData = function () {
        var _this = this;
        this.storage.getItem('userdata').subscribe(function (userData) {
            _this.userData = {};
            _this.isUserLoggedIn = false;
            _this.isKalgudiTeam = false;
            if (userData && userData.firstName) {
                var regex = /(@vasudhaika\.net|@kalgudi\.com)$/;
                _this.isKalgudiTeam = userData.emailId && regex.test(userData.emailId);
                _this.userData = userData;
                _this.canAccessSupportPage = _this.checkCustomerSupportContributor;
                _this.isUserLoggedIn = true;
                _this.loggedInUserName = userData.firstName;
                var url = environment.baseUrl + "/data/profilepics/" + _this.userData.profileKey + ".png";
                if (_this.userData.profilePicUrl === url) {
                    _this.userData.profilePicUrl = 'assets/icons/header/user.svg';
                }
            }
        });
    };
    HeaderComponent.prototype.logout = function () {
        this.authService.logout();
        this.loggedInUserName = '';
    };
    HeaderComponent.prototype.openSidebarOrNavigateBack = function () {
        this.isHome ? (this.showSideNav = true) : this.location.back();
    };
    HeaderComponent.prototype.cartCount = function () {
        var _this = this;
        this.productQuantityInCart = 0;
        this.cart &&
            this.cart.products.forEach(function (product) {
                _this.productQuantityInCart += parseInt(product.productQuantity);
            });
    };
    HeaderComponent.prototype.deleteProduct = function (product) {
        this.cartService.removeProductFromCart(product);
    };
    Object.defineProperty(HeaderComponent.prototype, "isHome", {
        get: function () {
            var pages = ['login', 'signup'];
            var url = this.router.url;
            return pages.reduce(function (a, p) { return url.includes(p) || url === '/'; }, false);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HeaderComponent.prototype, "assistant", {
        get: function () {
            return JSON.parse(localStorage['assistant'] || null);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HeaderComponent.prototype, "isDashboard", {
        get: function () {
            return this.router.url.includes('dashboard');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HeaderComponent.prototype, "storeNotifications", {
        get: function () {
            return []; // this.fcm.notifications;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HeaderComponent.prototype, "homePage", {
        get: function () {
            return this.router.url === '/';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HeaderComponent.prototype, "isProductFullView", {
        get: function () {
            return this.router.url.includes('/p/c');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HeaderComponent.prototype, "isAdminPage", {
        get: function () {
            return this.router.url.includes('/support');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HeaderComponent.prototype, "categories$", {
        get: function () {
            return this.storeService.categories$;
        },
        enumerable: true,
        configurable: true
    });
    HeaderComponent.prototype.isNumber = function (event) {
        return this.util.numberOnly(event);
    };
    /**
     * To check if delivery is enabled for some pin code
     */
    HeaderComponent.prototype.checkPinCode = function () {
        if (isNaN(+this.deliveryCheckForm.pinCode))
            return;
        var canShipToLocation = this.validPins.includes(this.deliveryCheckForm.pinCode);
        this.dialog.open(PincodeRequestDialogComponent, {
            panelClass: 'position-relative',
            autoFocus: false,
            data: {
                pincode: this.deliveryCheckForm.pinCode,
                pincodeAvalilable: canShipToLocation,
                userData: this.userData,
            },
        });
        localStorage.setItem("pin", this.deliveryCheckForm.pinCode);
        localStorage.setItem("canShipToLocation", JSON.stringify(canShipToLocation));
    };
    Object.defineProperty(HeaderComponent.prototype, "checkCustomerSupportContributor", {
        /*
         * Checks the logged in user is contributor of Kalgudi Support Program
         */
        get: function () {
            var isSupportPage = false;
            var listOfPageDetails = this.userData.listOfPageDetails;
            if (listOfPageDetails) {
                listOfPageDetails.forEach(function (l) {
                    if ((l.pageId === 'O00k4jn1PROCRE2018111425291806UNH001' ||
                        l.pageId === 'O00000u1PROCRE2018111424408497UNH001') &&
                        (l.relation === 'CONTRIBUTOR' || l.relation === 'FOLLOWER')) {
                        isSupportPage = true;
                        return;
                    }
                });
            }
            return isSupportPage;
        },
        enumerable: true,
        configurable: true
    });
    return HeaderComponent;
}(KalgudiDestroyable));
export { HeaderComponent };
