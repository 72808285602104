import { OnInit, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
var SideLinksComponent = /** @class */ (function () {
    function SideLinksComponent(router) {
        this.router = router;
        this.showBackToTopBtn = false;
        this.isButtonAvailableInBottom = false;
        this.scrollTop = function () {
            var rootElement = document.getElementById('app-root');
            rootElement.scrollIntoView({ behavior: 'smooth' });
        };
    }
    // @HostListener('window: scroll', [])
    SideLinksComponent.prototype.onWindowScroll = function () {
        this.showBackToTopBtn = window.pageYOffset > 500 ? true : false;
    };
    SideLinksComponent.prototype.setChatBoxPosition = function () {
        var smartsupp = document.getElementById('backToTop');
        var buttonsInBottom = document.getElementById('placeBtnInBottom');
        if (smartsupp) {
            if (window.screen.width < 600) {
                if (buttonsInBottom) {
                    smartsupp.style.bottom = "60px";
                }
                else {
                    smartsupp.style.bottom = "20px";
                }
            }
        }
    };
    Object.defineProperty(SideLinksComponent.prototype, "isHome", {
        get: function () {
            var url = this.router.url;
            return url.includes('/auth');
        },
        enumerable: true,
        configurable: true
    });
    SideLinksComponent.prototype.ngOnInit = function () {
        // setTimeout(() => {
        //     this.setChatBoxPosition();
        // }, 5000);
        this.isProduction = environment.production && location.origin.includes('andhragreens');
        window.addEventListener('scroll', this.onWindowScroll.bind(this), { passive: true });
    };
    SideLinksComponent.prototype.ngOnDestroy = function () {
        window.removeEventListener('scroll', this.onWindowScroll.bind(this), false);
    };
    Object.defineProperty(SideLinksComponent.prototype, "hideRequirement", {
        get: function () {
            var url = this.router.url;
            return url.includes('/auth') || url.includes('/quotation/send') || this.assistant;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SideLinksComponent.prototype, "assistant", {
        get: function () {
            return JSON.parse(localStorage['assistant'] || null);
        },
        enumerable: true,
        configurable: true
    });
    return SideLinksComponent;
}());
export { SideLinksComponent };
