import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var AppNotifierService = /** @class */ (function () {
    function AppNotifierService() {
        this.notificationsSubject = new BehaviorSubject([]);
        this.notifications$ = this.notificationsSubject.asObservable();
    }
    AppNotifierService.prototype.pushNotification = function (notification) {
        var notifications = this.notificationsSubject.value;
        notifications.unshift(notification);
        this.sendNotifications(notifications);
        this.onNotificationPush(notification);
    };
    AppNotifierService.prototype.onNotificationPush = function (notification) {
        var _this = this;
        setTimeout(function () {
            var notifications = _this.notificationsSubject.value;
            notifications.splice(notifications.length - 1, 1);
            _this.sendNotifications(notifications);
        }, 5000);
    };
    AppNotifierService.prototype.sendNotifications = function (notifications) {
        this.notificationsSubject.next(notifications);
    };
    AppNotifierService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppNotifierService_Factory() { return new AppNotifierService(); }, token: AppNotifierService, providedIn: "root" });
    return AppNotifierService;
}());
export { AppNotifierService };
