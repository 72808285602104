import { Injectable } from '@angular/core';
import { KalgudiCoreRouteConfig } from "@kalgudi/core/config";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { RouteQueryParams, PartialData } from "@kalgudi/types";


@Injectable({
    providedIn: 'root'
})
export class CoreRouteConfigService implements KalgudiCoreRouteConfig {

    readonly pageNotFoundPath: string;
    readonly authGuardFailedPath: string;
    readonly noAuthGuardFailedPath: string;

    constructor(
        private router: Router,
    ) {
        this.pageNotFoundPath = '';
        this.authGuardFailedPath = '/auth/signin';
        this.noAuthGuardFailedPath = '/';
    }

    /**
     * Method called by the auth guards on success route.
     */
    onGuardSuccess(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {}

    /**
     * Method called by auth guards on failed route.
     */
    onAuthGuardFailure(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {}

    /**
     * Method called by auth guards on failed route.
     */
    onNoAuthGuardFailure(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {}

    /**
     * Routes app to the home page
     */
    toHome(params?: PartialData, queryParams?: RouteQueryParams): string {
        const url = `/`;

        this.route(url, queryParams);

        return url;
    }

    toSocialQaFullview(params?: PartialData, queryParams?: RouteQueryParams): string {
        let url = 'https://core.kalgudi.com/app/social/qa/:questionId';

        url = url.replace(':questionId', params.questionId);

        window.open(url, '_blank')

        return url;
    }

    /**
   * Routes the app to the specific url
   */
    private route(url: string, queryParams?: RouteQueryParams): void {

        this.router.navigate([url], { queryParams });
    }

}