
    <div class="category-container">
        <div (click)="isExpanded = !isExpanded" class="category" fxLayoutAlign="space-between center">
            <div class="name" translate>{{ subCategory.name | lowercase }}</div>
            <i class="fa" [class.fa-angle-right]="!isExpanded" [class.fa-angle-down]="isExpanded"></i>
        </div>
        <div [@expandCollapse]="isExpanded ? 'expanded' : 'collapsed'">
            <div class="sub-category-container" *ngFor="let sub of subCategory.subCategory">
                
                <div class="category" fxLayoutAlign="space-between center" (click)="selectCategory(sub)" *ngIf="!sub.subCategory">
                <div class="name" translate>{{ sub.name |lowercase }}</div>
                </div>

                <div class="category-container">
                    <div (click)="sub.isExpanded = !sub.isExpanded" class="category" fxLayoutAlign="space-between center">
                    <div class="name" translate>{{ sub.name | lowercase }}</div>
                        <i class="fa fa-angle-{{ sub.isExpanded ? 'down': 'right'}}"></i>
                    </div>
                    <div [@expandCollapse]="sub.isExpanded ? 'expanded' : 'collapsed'">
                        <div class="sub-category-container" *ngFor="let c of sub.subCategory" (click)="selectCategory(c, 4, sub, subCategory)">
                            <div class="category" fxLayoutAlign="space-between center">
                            <div class="name" translate>{{ c.name |lowercase }}</div>
                            </div>
                        </div>
                        <div class="sub-category-container" (click)="selectCategory(sub, 3, subCategory)">
                            <div class="category" fxLayoutAlign="space-between center">
                            <div class="name" translate><span translate>All</span> {{ sub.name |lowercase  }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="sub-category-container" (click)="selectCategory(subCategory, 2)">
                <div class="category" fxLayoutAlign="space-between center">
                <div class="name" translate><span translate>All</span> {{ subCategory.name | lowercase }}</div>
                </div>
            </div>
        </div>
    </div>
    