import { Injectable } from '@angular/core';
import { DeliveryAddress } from '../models/shipping-form.model';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeliveryAddressService {

  private deliveryLocationLocalStorageKey = 'deliveryLocationSelected';
  private pincodeLocalStorageKey = 'pin';

  private deliveryLocationSelectedSubject = new BehaviorSubject<DeliveryAddress | string>('');
  deliveryLocationSelected$: Observable<DeliveryAddress | string>

  constructor() {
    this.deliveryLocationSelected$ = this.deliveryLocationSelectedSubject.asObservable();
    let deliveryLocationSelected = '';
    try {
      deliveryLocationSelected = (
        JSON.parse(localStorage.getItem(this.deliveryLocationLocalStorageKey) || null)
        || localStorage[this.pincodeLocalStorageKey]
        || ''
      );

    } catch (e) {
      console.warn(`Failed to parse existing pin`);
    }
    this.deliveryLocationSelectedSubject.next(deliveryLocationSelected);
  }

  setDeliveryAddress(address: DeliveryAddress | string) {
    this.deliveryLocationSelectedSubject.next(address);

    const delAddress = (typeof address === 'string') ? address : JSON.stringify(address);
    localStorage[this.deliveryLocationLocalStorageKey] = delAddress;

    if (address) localStorage[this.pincodeLocalStorageKey] = (address as DeliveryAddress).postalCode || address;
  }
}
