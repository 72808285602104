import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FooterHelpComponent } from './project-common/components/footer-help/footer-help.component';
import { CanDeactivateGuardService } from './project-common/services/can-deactivate-guard.service';
import { StoreNotificationsComponent } from './project-common/components/store-notifications/store-notifications.component';
import { PreBookComponent } from './project-common/components/pre-book/pre-book.component';
import { CampaignGuard } from './project-common/services/campaign.guard';
import { LogoutHandlerComponent } from './storefront/logout-handler/logout-handler.component';


const routes: Routes = [
  { path: 'pre-book', component: PreBookComponent },
  { path: 'notifications', component: StoreNotificationsComponent }, //Store Notification route for mobile app
  { path: 'checkout', loadChildren: '../app/checkout/checkout.module#CheckoutModule', canActivate: [CampaignGuard] },
  { path: 'auth', loadChildren: '../app/authentication/authentication.module#AuthenticationModule' },
  { path: 'account', loadChildren: '../app/profile/profile.module#ProfileModule' },
  { path: 'order', loadChildren: '../app/orders/orders.module#OrdersModule' },
  { path: 'support', loadChildren: '../app/support/support.module#SupportModule' },
  { path: 'search', loadChildren: '../app/modules/search/search.module#SearchModule', data: { title: 'Search results' } },
  { path: 'order_details/:id', redirectTo: 'order/view/:id' }, // Deprecated
  { path: 'order_summary/:id', redirectTo: 'order/success/:id' }, // Deprecated
  { path: 'payment-failure/:orderId', redirectTo: 'order/failed', data: { title: 'Payment failed' } }, // Deprecated
  { path: 'order-payment-success/:orderId', redirectTo: 'order/payment/success', data: { title: 'Order payment success' } },
  { path: 'help/:helpertext', component: FooterHelpComponent, data: { title: 'Kalgudi help' } },
  {
    path: '',
    loadChildren: '../app/storefront/storefront.module#StorefrontModule'
  },
  {
    path: 'home',
    redirectTo: '',
    pathMatch: 'full'
  },
  {

    path: 'logout', component: LogoutHandlerComponent

  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {

    /* Restores the previous scroll position on backward navigation, 
      else sets the position to the anchor if one is provided, 
      or sets the scroll position to [0, 0] (forward navigation). */
    scrollPositionRestoration: 'enabled'

  })],
  providers: [
    CanDeactivateGuardService
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
