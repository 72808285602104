import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { StoreType } from '@kalgudi/types';
import { KalgudiEnvironmentConfig } from '@kalgudi/core/config';

@Injectable({
  providedIn: 'root'
})
export class CoreEnvironmentConfigService implements KalgudiEnvironmentConfig {

  production: boolean;
  awsAccessKey: string;
  s3BucketUrl: string;
  domain: string;
  consumerStoreDomain: string;
  bizStoreDomain: string;
  farmerStoreDomain: string;
  restBaseUrl: string;
  restBaseUrlV2: string;
  storeType: StoreType;
  dirUrl:``;
  coreDomain: string;

  constructor() {
    this.production = environment.production;
    this.awsAccessKey = environment.awsAccessKey;
    this.s3BucketUrl = environment.bucketUrl;
    this.domain = environment.baseUrl;
    this.consumerStoreDomain = environment.baseUrl;
    this.bizStoreDomain = environment.bizStoreDomain;
    this.farmerStoreDomain = environment.farmerStoreDomain;
    this.restBaseUrl = environment.restBaseUrl;
    this.restBaseUrlV2 = environment.restBaseUrlV2;
    this.storeType = StoreType.FARM_STORE;
    this.coreDomain = environment.coreDomain;
    this.dirUrl = ``;
  }

  getStoreType(): StoreType {
    return StoreType.FARM_STORE;
  }
}
