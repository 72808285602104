import { ViewportScroller } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouteReuseStrategy, RouterModule, Scroll } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ToastaModule } from 'ngx-toasta';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BackToTopComponent } from './back-to-top.component';
import { FooterComponent } from './footer/footer.component';
import { InterceptorService } from './interceptor.service';
import { HttpLoaderFactory } from './project-common/factories/translator.factory';
import { ProjectCommonModule } from './project-common/project-common.module';
import { ReuseStrategyService } from './reuse-strategy.service';
import { StoreErrorHandlerService } from './store-error-handler.service';
import { HeaderComponent } from './storefront/header/header.component';
import { LogoutHandlerComponent } from './storefront/logout-handler/logout-handler.component';
import { CORE_LIB_CONFIG } from './config/core-lib-config';
import { KalgudiCoreModule } from '@kalgudi/core';
import { KalgudiCommonModule } from '@kalgudi/common';
import { KalgudiGooglePlacesModule } from '@kalgudi/third-party/google-places';
import { KalgudiLazyLoaderModule } from '@kalgudi/third-party/lazy-loader';
import { KalgudiPhotoswipeModule } from '@kalgudi/third-party/photoswipe';
import { KalgudiGoogleLanguageTranslatorModule } from '@kalgudi/third-party/google-language-translator';



/* To handle exceptions globally  */
@Injectable()
export class StoreErrorHandlers implements ErrorHandler {
  constructor(private injector: Injector) { }
  handleError(error) {
    const errorHandler = this.injector.get(StoreErrorHandlerService);
    errorHandler.handleError(error);
  }
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    BackToTopComponent,
    LogoutHandlerComponent,
    
  ],
  imports: [
    AppRoutingModule,
    ProjectCommonModule,
    RouterModule,
    BrowserAnimationsModule,
    // StorefrontModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { 
      enabled: environment.production && location.origin.includes(environment.baseUrl) 
    }),
    ToastaModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    FlexLayoutModule,
    FormsModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp({
      apiKey: "AIzaSyATOCHP3Zg-JVsQEG5L2Mm1fAbmEbW7nOg",
      authDomain: "andhragreens.firebaseapp.com",
      databaseURL: "https://andhragreens.firebaseio.com",
      projectId: "andhragreens",
      storageBucket: "andhragreens.appspot.com",
      messagingSenderId: "998307551323",
      appId: "1:998307551323:web:b2ff8f7852a538f030077e"
    }),


    // Kalgudi libraries
    KalgudiCoreModule.forRoot(CORE_LIB_CONFIG),
    KalgudiCommonModule.forChild(CORE_LIB_CONFIG),
    KalgudiLazyLoaderModule.forRoot(),
    KalgudiGooglePlacesModule.forRoot(environment.googleApiKey),
    KalgudiGoogleLanguageTranslatorModule.forRoot({ apiKey: environment.googleApiKey }),
    KalgudiPhotoswipeModule.forRoot({
        scripts: [
          'assets/lib/photoswipe-4.1.2/photoswipe.min.js',
          'assets/lib/photoswipe-4.1.2/photoswipe-ui-default.min.js'
        ],
        css: [
          'assets/lib/photoswipe-4.1.2/photoswipe.min.css',
          'assets/lib/photoswipe-4.1.2/default-skin.min.css'
        ],
      }),
  ],
  exports: [
    // ProjectCommonModule,
    ToastaModule,
    TranslateModule
  ],
  providers: [
    { provide: ErrorHandler, useClass: StoreErrorHandlers },
    { provide: RouteReuseStrategy, useClass: ReuseStrategyService },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    /* { provide: LocationStrategy, useClass: HashLocationStrategy }, */
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(router: Router, viewportScroller: ViewportScroller) {
    router.events.subscribe(e => {
      if(!(e instanceof Scroll)) return;

      if (e.position) {
        // backward navigation
        setTimeout(() => {
          viewportScroller.scrollToPosition(e.position);
        }, 50);
      } else if (e.anchor) {
        // anchor navigation
        viewportScroller.scrollToAnchor(e.anchor);
      } else {
        // forward navigation
        viewportScroller.scrollToPosition([0, 0]);
      }
    });
  }
}


