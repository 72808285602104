import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilityService } from '../project-common/services/utility.service';
import { FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { SpinnerService } from '../project-common/services/spinner.service';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { TranslateService } from '@ngx-translate/core';
import { UserSubscriberService } from '../project-common/services/user-subscriber.service';
var FooterComponent = /** @class */ (function () {
    function FooterComponent(
    // private notification: NotificationService,
    // private s3: S3UploadService,
    router, utility, subscriberService, snackbar, translator, spinner, storage) {
        this.router = router;
        this.utility = utility;
        this.subscriberService = subscriberService;
        this.snackbar = snackbar;
        this.translator = translator;
        this.spinner = spinner;
        this.storage = storage;
        this.email = new FormControl('', [Validators.required, Validators.email]);
    }
    FooterComponent.prototype.ngOnInit = function () {
        var _this = this;
        // this.footerColor = false;
        this.footerData = {};
        /* this.s3.getJsonDataFromS3('/data/footerlinks/footer_link_sections.json').subscribe(data =>{
          if(data){
            this.footerData = data.data;
            this.footerKey = Object.keys(this.footerData);
          }
        }, error => {
          this.footerData = {};
        }); */
        this.storage.getItem('userdata').subscribe(function (userData) {
            _this.userProfile = userData;
        });
        this.storage.getItem('countryDetails').subscribe(function (country) {
            _this.countryDetails = country;
        });
    };
    FooterComponent.prototype.openHelp = function (key) {
        if (this.utility.isMobileDevice()) {
            this.router.navigate([
                'help', key,
            ]);
        }
        else {
            var url = window.location.origin + this.utility.getPathName(window.location.pathname) + 'help/' + key;
            window.open(url, '_blank');
        }
    };
    Object.defineProperty(FooterComponent.prototype, "isMobileDevice", {
        get: function () {
            return this.utility.isMobileDevice();
        },
        enumerable: true,
        configurable: true
    });
    FooterComponent.prototype.subscribeUs = function () {
        var _this = this;
        var first = "Please enter a valid e-mail address";
        var last = "OK";
        if (this.email.invalid) {
            return this.translator.get([first, last]).subscribe(function (translatedText) {
                _this.snackbar.open("" + translatedText[first], "" + translatedText[last], {
                    duration: 4000, panelClass: ['snakbar-color']
                });
            });
        }
        this.spinner.start();
        this.subscriberService.subscribeToNewsLetters(this.email.value).subscribe(function (res) {
            _this.spinner.stop();
            if (res.code == 201) {
                _this.email.reset();
                return _this.snackbar.open(res.info, 'OK', {
                    duration: 6000, panelClass: ['snakbar-color']
                });
            }
        }, function (error) {
            _this.spinner.stop();
            _this.snackbar.open(error.info, '', { duration: 6000, panelClass: ['snakbar-color'] });
        });
    };
    Object.defineProperty(FooterComponent.prototype, "isAdminPage", {
        get: function () {
            return this.router.url.includes('/support');
        },
        enumerable: true,
        configurable: true
    });
    return FooterComponent;
}());
export { FooterComponent };
