import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { Attachment } from '../../models/attachment.model';
import { ScriptLoaderService } from '../../services/script-loader.service';
import { ThirdPartyLibraries } from '../../properties/constants';
import { UtilityService } from '../../services/utility.service';

@Component({
    selector: 'display-gallery-dialog',
    templateUrl: './display-gallery-dialog.component.html',
    styleUrls: ['./display-gallery-dialog.component.scss']
})
export class DisplayGalleryDialogComponent implements OnInit {

    attachments: Attachment[] = [];
    selectedIndex: number;
    gallery: any[];
    constructor(@Inject(MAT_DIALOG_DATA) private data: any,
        public dialogRef: MatDialogRef<DisplayGalleryDialogComponent>,
        private loader: ScriptLoaderService,
        private util: UtilityService ) { }

    ngOnInit() {
        this.attachments = this.data.attachments;
    }

    loadRequiredLibraries() {
        this.loader.loadScript(ThirdPartyLibraries.photoswipe).subscribe();
        this.loader.loadScript(ThirdPartyLibraries.photoswipeDefaultSkin).subscribe();
        // this.loader.loadStyle(ThirdPartyLibraries.fontAwesomeCss).subscribe();
        this.loader.loadStyle(ThirdPartyLibraries.photoswipeCss).subscribe();
        this.loader.loadStyle(ThirdPartyLibraries.photoswipeDefaultSkinCss).subscribe();
    }

    setGallery() {
        for (const i in this.attachments) {
            this.util.getImageDimension(this.attachments[i], i).subscribe(image => {
                this.attachments[image.index] = image;
            });
        }
    }
    /**
     * On close attachments should not get displayed
     */
    close() {
        this.attachments = [];
        this.dialogRef.close(this.attachments);
    }

    /**
     * To open image in full view
     * @param index image index
     */
    openInImageViewer(index): void {
        this.gallery = this.attachments;
        this.selectedIndex = index;
    }

    /**
     * To close the full-view
     */
    closeImageDialog() {
        this.gallery = [];
    }
}
