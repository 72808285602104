import { Component, OnInit, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { ScaleImagePipe } from 'src/app/project-common/pipes/scale-image.pipe';
declare var PhotoSwipeUI_Default: any;
declare var PhotoSwipe: any;
@Component({
  selector: 'photo-swipe',
  templateUrl: './photo-swipe.component.html',
  styleUrls: ['./photo-swipe.component.scss']
})
export class PhotoSwipeComponent implements OnInit, OnChanges, OnDestroy{

  @Input() images: any;
  @Input() index: any;
  @Output() close = new EventEmitter();
  scaleImage: ScaleImagePipe;
  gallery: any[];
  photoSwipe: any;
  component: any;
  constructor() { }

  ngOnInit() {
    this.scaleImage = new ScaleImagePipe();
    this.gallery = [];
  }
  
  ngOnChanges () {
    setTimeout(() => {
      this.initializePhotoSwipe();
    }, 300);
  }

  initializePhotoSwipe () {
    const psElement = document.querySelectorAll('.pswp')[0];
    this.images.forEach(image => {
      const ratio = image.width / image.height;
      const obj = {
        src: this.scaleImage.transform(image.url),
        w: ratio > 1 ? 960 : (image.width * 960) / 100,
        h: ratio <= 1 ? 960 : (image.height * 960) / 100,
        msrc: this.scaleImage.transform(image.url, 'sm'),
        title: image.context
      }
      // console.log(obj);
      this.gallery.push(obj);
      
    });
    const options = {
      index : this.index,
    }
    this.photoSwipe = new PhotoSwipe( psElement, PhotoSwipeUI_Default, this.gallery, options);
    this.photoSwipe.init();
    this.photoSwipe.listen('destroy', this.closePopup);
    this.photoSwipe.component = this;
  }

  closePopup () {
    this.component && this.component.close.emit();
  }

  ngOnDestroy () {
    this.photoSwipe = null;
  }

}
