var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Headers } from '@angular/http';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SpinnerService } from 'src/app/project-common/services/spinner.service';
import { environment } from 'src/environments/environment';
import { NotificationService } from './notification.service';
import { HttpClient } from '@angular/common/http';
var HttpWithAuthService = /** @class */ (function () {
    function HttpWithAuthService(http, localStorage, notification, platformId, snackbar, router, spinner) {
        var _this = this;
        this.http = http;
        this.localStorage = localStorage;
        this.notification = notification;
        this.snackbar = snackbar;
        this.router = router;
        this.spinner = spinner;
        this.userAuthHeader = new Headers();
        this.userAuthHeader.append('Content-Type', 'application/json');
        this.userAuthHeader.append('version', environment.version);
        this.userAuthHeader.append('source', environment.source);
        // console.log("isBroswer: ", isPlatformBrowser(platformId));
        // For authGaurd
        this.isLoggedIn = JSON.parse(window.localStorage['YUdGelRHOW5aMlZrU1c0PQ=='] || 'false');
        if (this.isLoggedIn && window.localStorage['dXNlcm5hbWU='] && window.localStorage['cGFzc3dvcmQ=']) {
            this.setAssistedHeader();
            this.userAuthHeader.append('userName', atob(window.localStorage['dXNlcm5hbWU=']));
            this.userAuthHeader.append('password', atob(window.localStorage['cGFzc3dvcmQ=']));
            this.setAuthHeaders().subscribe(function (res) {
                _this.getUserData();
            });
        }
        this.notification.userDataChanges.subscribe(function () {
            _this.getUserData();
            _this.setAssistedHeader();
        });
    }
    HttpWithAuthService.prototype.setAuthHeaders = function () {
        var _this = this;
        return new Observable(function (observer) {
            _this.getUserName().subscribe(function (userName) {
                _this.localStorage.getItem('userdata').subscribe(function (userData) {
                    if (!userData) {
                        userData = {
                            mobileNo: ''
                        };
                    }
                    _this.userAuthHeader.set('userName', userData.mobileNo || userName);
                    localStorage['dXNlcm5hbWU='] = btoa(userData.mobileNo || userName);
                    _this.userName = userData.mobileNo || userName;
                    observer.next(userData.mobileNo || userName);
                    if (_this.password) {
                        observer.complete();
                    }
                });
            });
            _this.getUserPassword().subscribe(function (password) {
                _this.userAuthHeader.set('password', password);
                _this.password = password;
                localStorage['cGFzc3dvcmQ='] = btoa(password);
                observer.next(password);
                if (_this.userName) {
                    observer.complete();
                }
            });
        });
    };
    HttpWithAuthService.prototype.setImediateHeaders = function (userName, password) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                localStorage.setItem('dXNlcm5hbWU=', btoa(userName));
                localStorage.setItem('cGFzc3dvcmQ=', btoa(password));
                this.userAuthHeader.set('userName', userName);
                this.userAuthHeader.set('password', password);
                return [2 /*return*/];
            });
        });
    };
    HttpWithAuthService.prototype.setAssistedHeader = function () {
        this.userAuthHeader.set('isAssisting', window.localStorage.getItem('Y2FuQXNzaXN0') || 'false');
    };
    HttpWithAuthService.prototype.getUserName = function () {
        var _this = this;
        return new Observable(function (observer) {
            _this.localStorage.getItem('username').subscribe(function (userName) {
                _this.userName = userName;
                observer.next(userName);
                observer.complete();
            });
        });
    };
    HttpWithAuthService.prototype.getUserPassword = function () {
        var _this = this;
        return new Observable(function (observer) {
            _this.localStorage.getItem('password').subscribe(function (password) {
                _this.password = password;
                observer.next(password);
                observer.complete();
            });
        });
    };
    HttpWithAuthService.prototype.getUserData = function () {
        var _this = this;
        this.localStorage.getItem('userdata').subscribe(function (userData) {
            _this.userData = userData;
            _this.isLoggedIn = !!userData;
            _this.userData && _this.userAuthHeader.set('userName', _this.userData.mobileNo);
            !_this.userData && _this.userAuthHeader.set('userName', '');
            localStorage['YUdGelRHOW5aMlZrU1c0PQ=='] = _this.isLoggedIn;
        });
    };
    HttpWithAuthService.prototype.get = function (url, params) {
        var _this = this;
        return this.http.get(url, {
            headers: this.userAuthHeader,
            params: params
        }).pipe(map(function (response) {
            var result;
            try {
                result = response;
                _this.processResponse(result);
            }
            catch (e) {
                console.error('Unable to parse\n', e);
            }
            return result;
        }), catchError(function (error) {
            var result = error;
            _this.processResponse(result);
            throw error;
        }));
    };
    HttpWithAuthService.prototype.post = function (url, data) {
        var _this = this;
        return this.http.post(url, data, {
            headers: this.userAuthHeader
        }).pipe(map(function (response) {
            var result;
            try {
                result = response;
                _this.processResponse(result);
            }
            catch (e) {
                console.error('Unable to parse\n', e);
            }
            return result;
        }), catchError(function (error) {
            var result = error;
            _this.processResponse(result);
            throw error;
        }));
    };
    HttpWithAuthService.prototype.put = function (url, data) {
        var _this = this;
        return this.http.put(url, data, {
            headers: this.userAuthHeader
        }).pipe(map(function (response) {
            var result;
            try {
                result = response;
                _this.processResponse(result);
            }
            catch (e) {
                console.error('Unable to parse\n', e);
            }
            return result;
        }), catchError(function (error) {
            var result = error;
            _this.processResponse(result);
            throw error;
        }));
    };
    HttpWithAuthService.prototype.delete = function (url) {
        var _this = this;
        return this.http.delete(url, {
            headers: this.userAuthHeader
        }).pipe(map(function (response) {
            var result;
            try {
                result = response;
                _this.processResponse(result);
            }
            catch (e) {
                console.error('Unable to parse\n', e);
            }
            return result;
        }), catchError(function (error) {
            var result = error;
            _this.processResponse(result);
            throw error;
        }));
    };
    /**
     * To handle error responses from service
     * @param response Service response
     */
    HttpWithAuthService.prototype.processResponse = function (response) {
        var errorCode = response.error ? response.error.code : null;
        if (errorCode && errorCode > 399) {
            this.spinner.changeStatus(false, false);
            var errorInfo = response.info || (response.error && response.error.info);
            this.checkForAuthentication(errorCode);
            var info = errorInfo || "Something went wrong, please try again " + errorCode;
            this.snackbar.open(info, 'OK', {
                duration: 7000, panelClass: ['snakbar-color']
            });
        }
    };
    HttpWithAuthService.prototype.checkForAuthentication = function (code) {
        if (code == 401 || code == 502) {
            this.navigateToLoginPage();
            return true;
        }
        return false;
    };
    HttpWithAuthService.prototype.navigateToLoginPage = function () {
        var _this = this;
        delete localStorage['YUdGelRHOW5aMlZrU1c0PQ=='];
        delete localStorage['cGFzc3dvcmQ='];
        delete localStorage['dXNlcm5hbWU='];
        delete localStorage[btoa('profileKey')];
        delete localStorage['assistant'];
        // removing 'tk' , 'c', 'pf' from localStorage to remove login information.
        localStorage.removeItem('pf');
        localStorage.removeItem('tk');
        localStorage.removeItem('c');
        this.localStorage.removeItem('userdata').subscribe(function (response) {
            _this.localStorage.removeItemSubscribe('username');
            _this.localStorage.removeItemSubscribe('password');
            _this.localStorage.removeItemSubscribe('userWishList');
            _this.localStorage.removeItemSubscribe('FAVOURITE_MAKERS');
            _this.localStorage.removeItemSubscribe('SAVED_PRODUCTS');
            var cart = {
                productIds: [],
                products: []
            };
            _this.localStorage.setItemSubscribe('cart', cart);
            _this.notification.notifyForUserDataChange();
            _this.router.navigateByUrl('auth/login');
        });
    };
    return HttpWithAuthService;
}());
export { HttpWithAuthService };
