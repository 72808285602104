import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { KalgudiDestroyable } from 'src/app/classes/kalgudi-destroyable';

@Directive({
  selector: '[fxIf]'
})
export class FxIfDirective extends KalgudiDestroyable {

  private hasView = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private mediaObserver: MediaObserver
  ) { super(); }


  @Input()
  set fxIf(mqAliases: string | string[]) {
    this.updateView(mqAliases);
  }

  private updateView(mqAliases: string | string[]) {
    if(!mqAliases) this.clearView();

    this.mediaObserver.asObservable()
    .pipe(
      takeUntil(this.destroyed$),

      distinctUntilChanged(),

      tap(_ => this.mediaObserver.isActive(mqAliases) ? this.showView() : this.clearView())
    ).subscribe();
  }

  private clearView() {
    if(!this.hasView) return;

    this.viewContainer.clear();

    this.hasView = false;
  }

  private showView() {
    if(this.hasView) return;

    this.viewContainer.createEmbeddedView(this.templateRef);

    this.hasView = true;
  }

  protected onDestroyed() { }

}
