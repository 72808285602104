import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { HttpWithAuthService } from './../project-common/services/http-interceptor.service';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Storefront } from '../project-common/properties/constants';
import * as i0 from "@angular/core";
import * as i1 from "../project-common/services/http-interceptor.service";
import * as i2 from "@angular/common/http";
var StorefrontService = /** @class */ (function () {
    function StorefrontService(httpWithAuth, http) {
        this.httpWithAuth = httpWithAuth;
        this.http = http;
        this.categories$ = of(categories);
        /**
         * List of pincodes for which all products can be delivered including fruits & vegetables
         */
        this.deliverablePinCodes$ = this.http
            .get(environment.baseUrl + "/data/storeProducts/biz/" + Storefront.APP_NAME)
            .pipe(shareReplay());
        this.isSaleOpen$ = new BehaviorSubject(JSON.parse(localStorage['isSaleOpen'] || 'true'));
    }
    StorefrontService.prototype.getBasketProducts = function (l2Id) {
        var url = environment.restBaseUrlV2 + "/listing/products/l2/" + l2Id + "/basketItems?storeType=BIZ_STORE&languageId=1";
        return this.http.get(url).pipe(map(function (res) { return res.data; }));
    };
    StorefrontService.prototype.getProductList = function (request, language) {
        var _this = this;
        var categoriesCount = request.length;
        return new Observable(function (observer) {
            var url = environment.baseUrl + "/data/storeProducts/biz/categories/" + environment.sellerProfile + "_";
            var sufix = _this.getSuffix(language);
            request.forEach(function (category) {
                var temp = '';
                if (request[0].includes('PRFREG')) {
                    temp = '_STORE_PRODUCT_CARDS';
                }
                _this.http.get(url + category + sufix + temp).subscribe(function (response) {
                    --categoriesCount;
                    try {
                        var data = response;
                        data.listOfLevel2Cards = _this.suffleProducts(data.listOfLevel2Cards);
                        // debugger
                        observer.next(data);
                    }
                    catch (e) {
                        ga('send', 'exception', {
                            'exDescription': 'Unable to parse: ' + e.url,
                            'exFatal': false
                        });
                        /* throw new Error('EXCEPTION: FILE_NOT_FOUND\n ' + e); */
                    }
                    if (!categoriesCount) {
                        observer.complete();
                    }
                }, function (error) {
                    --categoriesCount;
                    ga('send', 'exception', {
                        'exDescription': 'File not found: ' + error.url,
                        'exFatal': false
                    });
                    // observer.error(error);
                    if (!categoriesCount) {
                        observer.complete();
                    }
                    /* throw new Error('No data found'); */
                });
            });
        });
    };
    StorefrontService.prototype.getProductDetails = function (postId) {
        var url = environment.baseUrl;
        url += '/rest/v1/business/requirements/quote/' + postId + '_master/thread/' + postId + '_master';
        return this.httpWithAuth.get(url).pipe(map(function (data) {
            return data;
        }));
    };
    StorefrontService.prototype.getOffers = function () {
        var url = environment.baseUrl + "/rest/v1/ecom/orders/sankrantiOffers";
        return this.http.get(url).pipe(map(function (data) {
            var response = data;
            response = JSON.parse(response.data);
            // response.offers[0].from = new Date();
            return response;
        }));
    };
    StorefrontService.prototype.viewTrace = function (traceId) {
        var url = environment.baseUrl;
        url += '/rest/v1/trace/viewTrace/' + traceId;
        return this.httpWithAuth.get(url).pipe(map(function (data) {
            return data;
        }));
    };
    StorefrontService.prototype.isTraceExistsForAmp = function (postId) {
        var url = environment.baseUrl;
        url += '/rest/v1/trace/amp/status?postId=' + postId;
        return this.httpWithAuth.get(url).pipe(map(function (data) {
            return data;
        }));
    };
    StorefrontService.prototype.getFullViewData = function (productId, language, isPreview) {
        var url = (isPreview) ? environment.bucketUrl : environment.baseUrl;
        var sufix = this.getSuffix(language);
        url += "/data/storeProducts/biz/" + productId + sufix;
        return this.http.get(url).pipe(map(function (data) {
            return data;
        }, function (error) {
            throw new Error('No product found');
        }));
    };
    StorefrontService.prototype.getProductReviewMetaData = function (productId, loadMore) {
        var url = environment.baseUrl;
        url += "/data/storeProducts/biz/reviewAndRating/" + productId + "_" + loadMore.start + "_" + loadMore.end;
        return this.httpWithAuth.get(url).pipe(map(function (data) {
            return data;
        }, function (error) {
            throw new Error('No reviews found');
        }));
    };
    /**
     * We get the data related to reviews and ratings from s3.
     * @param productId productLevel2Id
     */
    StorefrontService.prototype.getProductReviewRatingsMetaData = function (productId) {
        var url = environment.baseUrl;
        url += "/data/reviewAndRating/biz/" + productId + "_METADATA";
        return this.httpWithAuth.get(url).pipe(map(function (data) {
            return data;
        }, function (error) {
            throw new Error('No reviews found');
        }));
    };
    StorefrontService.prototype.getSellerCatalogueList = function (profileKey, isML1, isML2, productlevel2Id) {
        var url = environment.baseUrl;
        ;
        url += isML1 || isML2 ? "/data/similar-products/" + (isML2 ? 'v1/' + productlevel2Id : productlevel2Id) + "_SAME_SELLER" :
            "/data/storeProducts/biz/categories/" + profileKey + "_BIZ_CATALOG";
        return this.httpWithAuth.get(url).pipe(map(function (data) {
            return data;
        }, function (error) {
            throw new Error('No products found');
        }));
    };
    StorefrontService.prototype.getHomeCategories = function (langCode) {
        var _this = this;
        var languageId = this.getSuffix(langCode).replace('_', '') || '1';
        if (this.homeCategories$ && languageId === this.languageId) {
            return this.homeCategories$.asObservable();
        }
        else {
            this.languageId = languageId;
            this.homeCategories$ = new BehaviorSubject(null);
            var url = environment.baseUrl + "/v2/listing/products/l2/home-page-categories-tiles";
            var params = {
                store: 'ANDHRAGREEN',
                languageId: languageId || '1'
            };
            this.http.get(url, { params: params }).subscribe(function (response) {
                var categoryList = [];
                Object.keys(response.data).forEach(function (key) {
                    var category = response.data[key];
                    category.listOfLevel2Cards = category.listOfLevel2Cards.sort(function (p1, p2) { return (+p2.pricePerSKU - +p1.pricePerSKU); });
                    category.listOfLevel2Cards.forEach(function (p) { return !p.productCategory ? p.productCategory = key : p.productCategory; });
                    categoryList.push(category);
                });
                categoryList = categoryList.sort(function (a, b) {
                    return b.listOfLevel2Cards.length - a.listOfLevel2Cards.length;
                });
                _this.homeCategories$.next(response.data);
                _this.categories$ = of(categoryList.map(function (c) {
                    return {
                        id: c.id,
                        value: c.value
                    };
                }));
            });
            return this.homeCategories$.asObservable();
        }
        /* return new Observable(observer => {
          this.getSellerCatalogueList(environment.sellerProfile).subscribe((res: any) => {
            let items: any[] = res.listOfLevel2Cards
              .filter(item => {
                return new Date(item.createdTS) > new Date(2020, 4, 18)
              });
            const updatedList = [];
            items.forEach(item => {
              this.getFullViewData(item.productId_level2).subscribe(level2Data => {
                updatedList.push(this.prepareCardObject(level2Data, item.productId_level3));
                res.listOfLevel2Cards = updatedList;
                observer.next(res);
                if (updatedList.length === items.length) {
                  observer.complete();
                }
              });
            })
          })
        }); */
    };
    StorefrontService.prototype.getCardObject = function (level2Id, level3Id) {
        var _this = this;
        return this.getFullViewData(level2Id)
            .pipe(map(function (level2Data) { return _this.prepareCardObject(level2Data, level3Id); }));
    };
    StorefrontService.prototype.prepareCardObject = function (level2Data, l3Id) {
        var level3 = level2Data.level3ProductsList.find(function (i) { return i.productLevel3Id === l3Id; });
        var seller = level2Data.resellerDetails || level2Data.manufacturerDetails;
        var _a = (level2Data.aka.find(function (_a) {
            var language = _a.language;
            return language.toLowerCase() === 'telugu';
        }) || {}).name, teluguName = _a === void 0 ? "" : _a;
        var cardObj = {
            LUT: level3.LUT,
            SKUSmartElements: level3.smartElements,
            createdTS: level3.LUT,
            approxDeliveryTime: level3.approxDeliveryTime,
            description: level2Data.description,
            isBulkOrder: level3.isBulkOrder,
            level2published: level2Data.isPublished,
            level3published: level3.isPublished,
            mrp: level3.mrp,
            pricePerSKU: level3.pricePerUnit,
            productId_level2: level3.productLevel2Id,
            productId_level3: level3.productLevel3Id,
            productName_level2: level3.productLevel2Title,
            productName_level3: level3.productLevel3Title,
            productPicURL_level3: level3.attachments.attachments[0].url,
            selectedSKU: level3.selectedSKU,
            sellerId: seller.profileKey,
            sellerLocation: seller.location,
            sellerName: seller.firstName,
            category: level2Data.baseCategory.value,
            // Extra properties other than card object
            freeShippingEligible: level3.freeShippingEligible,
            shipping: level3.shipping,
            teluguName: teluguName,
            baseProductName: level2Data.baseProduct.productName
        };
        if (level2Data.reviewsAndRatingsTo) {
            cardObj.reviewsAndRatingsTo = level2Data.reviewsAndRatingsTo;
        }
        return cardObj;
    };
    StorefrontService.prototype.getCategorySellers = function (category, offset, limit) {
        var url = environment.baseUrl + "/rest/v1/store/biz/productdetails?action=unique&require=sellerdetails&match=" + category + "&offset=" + offset + "&limit=" + limit;
        return this.httpWithAuth.get(url).pipe(map(function (data) {
            return data;
        }, function (error) {
            throw new Error('No reviews found');
        }));
    };
    StorefrontService.prototype.getSimilarProductsFromOtherSeller = function (productName, profileKey, isML1, isML2, productlevel2Id) {
        var url = environment.baseUrl;
        url += isML1 || isML2 ? "/data/similar-products/" + (isML2 ? 'v1/' + productlevel2Id : productlevel2Id) + "_DIFFERENT_SELLER" :
            "/rest/v1/store/biz/productsearch?keyword=" + productName + "&offset=0&limit=10&filter=excludeseller:" + profileKey;
        return this.httpWithAuth.get(url).pipe(map(function (data) {
            return data;
        }, function (error) {
            throw new Error('No reviews found');
        }));
    };
    StorefrontService.prototype.suffleProducts = function (productList) {
        var currentIndex = productList.length, temporaryValue, randomIndex;
        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;
            // And swap it with the current element.
            temporaryValue = productList[currentIndex];
            productList[currentIndex] = productList[randomIndex];
            productList[randomIndex] = temporaryValue;
        }
        return productList;
    };
    StorefrontService.prototype.getSuffix = function (language) {
        switch (language) {
            case 'en':
                language = '';
                break;
            case 'hi':
                language = '2';
                break;
            case 'te':
                language = '6';
                break;
            default:
                language = '';
                break;
        }
        return language ? '_' + language : '';
        /* [{ languageId: 1, languageName: "English", displayName: "English" },
            { languageId: 2, languageName: "Hindi", displayName: "हिंदी" },
            { languageId: 3, languageName: "Kannada", displayName: "ಕನ್ನಡ" },
            { languageId: 4, languageName: "Marathi", displayName: "मराठी" },
            { languageId: 5, languageName: "Tamil", displayName: "தமிழ்" },
            { languageId: 6, languageName: "Telugu", displayName: "తెలుగు" },
            ]; */
    };
    /*
     * Service To get the Source of procurement (Transactions Data)
     * @param {string} type Operation Type
     * @param {number} limit Limit of data to fetch
     * @param {number} offset Offset of data to fetch
     */
    StorefrontService.prototype.getSHGProcurementDetails = function (shgBusinessKey, offset, limit) {
        var url = environment.baseUrl + '/rest/v1/transactions/sellerPurchaseTransactions' + '?sellerBusinessKey=' + shgBusinessKey + '&limit=' + limit + '&offset=' + offset;
        return this.httpWithAuth.get(url).pipe(map(function (data) {
            return data;
        }, function (error) {
            throw new Error('No Procurement Data Found');
        }));
    };
    StorefrontService.prototype.getSellerRatings = function (profileKey) {
        var url = environment.baseUrl + "/data/profiles/ratingsAndReviews/" + profileKey + "_METADATA";
        return this.httpWithAuth.get(url).pipe(map(function (data) {
            return data;
        }, function (error) {
            throw new Error('No reviews found');
        }));
    };
    /* public getCategories(): Observable<any> {
      return this.http.get(`${environment.baseUrl}/data/storeProducts/biz/categories/store-product-categories.json`).pipe(map(data => {
        return data;
      }));
    } */
    StorefrontService.prototype.getSmartElementLabel = function (smartElement) {
        var availableSku = {
            color: 'Color',
            diameter: 'Diameter',
            dimensions: 'Dimension',
            setCount: 'Count of',
            shape: 'Shape',
            sizeByName: 'Size',
            sizeByNumber: 'Size',
            smartLabel: '',
            weight: 'Weight'
        };
        return availableSku[smartElement];
    };
    StorefrontService.prototype.getPrivacyTerm = function (request) {
        return this.http.get("assets/templates/" + request, { responseType: 'text' });
    };
    StorefrontService.prototype.saveFarmerDetails = function (payload) {
        var url = environment.restBaseUrlV2;
        url += '/listing/outputstore/users';
        return this.http.post(url, payload);
    };
    StorefrontService.prototype.preBook = function (request) {
        var url = environment.nodeServer + "/rest/v2/store/OUTPUTS/order/prebook";
        return this.http.post(url, request);
    };
    /* public getSaleableProducts(productIds: { l2Id: string, l3Id: string }[]): Observable<ProductLevel3[]> {
  
      if (!this.productList$) {
        this.productList$ = new BehaviorSubject<ProductLevel3[]>(JSON.parse(localStorage['saleableItems'] || '[]'));
        const products = [];
        productIds.forEach(item => {
          this.getFullViewData(item.l2Id).subscribe(data => {
            const level3 = data.level3ProductsList.find(p => p.productLevel3Id === item.l3Id);
            // level3.productLevel2Id = item.l2Id;
            products.push(level3);
            if (products.length === productIds.length) {
              localStorage['saleableItems'] = JSON.stringify(products);
              this.productList$.next(products);
              const isSaleOpen = products.filter(i => i.isPublished).length > 0;
              localStorage['isSaleOpen'] = isSaleOpen;
              this.isSaleOpen$.next(isSaleOpen);
            }
          });
        });
      }
      return this.productList$.asObservable();
    } */
    /**
     * To check if products available in store is still open for sale
     */
    StorefrontService.prototype.isSaleOpen = function () {
        return this.isSaleOpen$.asObservable();
    };
    /**
     * To get product reviews
     */
    StorefrontService.prototype.getAllReview = function () {
        var url = environment.nodeServer + "/v2/store/APGREEN/review/allReviews";
        return this.http.get(url).pipe(map(function (res) {
            return res.data.reviewList;
        }));
    };
    StorefrontService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StorefrontService_Factory() { return new StorefrontService(i0.ɵɵinject(i1.HttpWithAuthService), i0.ɵɵinject(i2.HttpClient)); }, token: StorefrontService, providedIn: "root" });
    return StorefrontService;
}());
export { StorefrontService };
var categories = [
    {
        "id": "SPICES",
        "value": "Spices"
    },
    {
        "id": "HERBAL PRODUCTS",
        "value": "Herbal products",
    },
    {
        "id": "FRUITS",
        "value": "Fruits",
    },
    {
        "id": "FOOD_PRODUCTS",
        "value": "Food Products"
    },
    {
        "id": "OTHERS",
        "value": "Others"
    },
    {
        "id": "PULSES",
        "value": "Pulses",
    },
    {
        "id": "EDIBLE_NUTS_AND_SEEDS",
        "value": "Edible Nuts & Seeds",
    },
];
