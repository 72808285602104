import { Injectable } from '@angular/core';

declare global {
  interface Window { smartsupp: (...arg: any) => void; }
}
declare var _smartsupp: any;

@Injectable({
  providedIn: 'root'
})
export class SmartsuppService {

  constructor() { }

  /**
   * To open smart-support chat window
   */
  public openChatWindow() {
    window.smartsupp('chat:open');
  }

  /**
   * To minimize smart-support chat window
   */
  public closeChatWindow() {
    window.smartsupp('chat:close');
  }

  /**
   * To hide smart-support app from view
   */
  public hideSmartSupport() {
    try {
      const chatWindow = document.getElementById('chat-application');
      chatWindow.style.visibility = 'hidden';
    } catch (e) { }
  }

  /**
   * To show smart-support in view
   */
  public showSmartSupport() {
    try {
      const chatWindow = document.getElementById('chat-application');
      chatWindow.style.visibility = 'visible';
    } catch (e) { }
  }

  /**
   * To set chat window color
   *
   * @param colorHashCode in #code format
   */
  public setThemeColor(colorHashCode: string = '#33da58') {
    window.smartsupp('theme:colors', {
      widget: colorHashCode,
      primary: colorHashCode
    });
  }

  /**
   * To initialize the smartsupp window
   *
   * Also it is required put below code in index.html to register smartsupp in application
   *
   * ------------------------------
   *
   *    <script type="text/javascript">
   *      var _smartsupp = {}
   *      var smartsupp;
   *    </script>
   *
   * ---------------------------------
   */
  public registerSmartSupport() {
    _smartsupp = {
      key: 'fb35f3da74fb5f8cbbd3b0bf8ecf4e6d04a93845'
    };
    const chatBoxRightDistance =  15 // window.innerWidth > 599 ? 48 : 15;
    _smartsupp.widget = 'button';
    _smartsupp.offsetX = chatBoxRightDistance;
    _smartsupp.triggerable = true;
    window.smartsupp || (function (d) {
      var scripts, node, app: any = window.smartsupp = function () { app._.push(arguments) }; app._ = [];
      scripts = d.getElementsByTagName('script')[0]; node = d.createElement('script');
      node.type = 'text/javascript'; node.charset = 'utf-8'; node.async = true;
      node.src = '//www.smartsuppchat.com/loader.js?'; scripts.parentNode.insertBefore(node, scripts);
    })(document);

    this.setThemeColor();
  }
}
