/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bookings-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "@angular/material/dialog";
import * as i7 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i8 from "@angular/material/icon";
import * as i9 from "@angular/flex-layout/extended";
import * as i10 from "@angular/flex-layout/core";
import * as i11 from "./bookings-dialog.component";
import * as i12 from "@angular/router";
var styles_BookingsDialogComponent = [i0.styles];
var RenderType_BookingsDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BookingsDialogComponent, data: {} });
export { RenderType_BookingsDialogComponent as RenderType_BookingsDialogComponent };
export function View_BookingsDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["mat-dialog-close", ""], ["mat-icon-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).dialogRef.close(i1.ɵnov(_v, 2).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(2, 606208, null, 0, i6.MatDialogClose, [[2, i6.MatDialogRef], i1.ElementRef, i6.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i7.View_MatIcon_0, i7.RenderType_MatIcon)), i1.ɵdid(4, 9158656, null, 0, i8.MatIcon, [i1.ElementRef, i8.MatIconRegistry, [8, null], [2, i8.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "a", [["fxHide.xs", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.preBook() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 4866048, null, 0, i9.DefaultShowHideDirective, [i1.ElementRef, i9.ShowHideStyleBuilder, i10.StyleUtils, i10.MediaMarshaller, i10.LAYOUT_CONFIG, i1.PLATFORM_ID, [2, i10.SERVER_TOKEN]], { "fxHide.xs": [0, "fxHide.xs"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 0, "img", [["alt", ""], ["src", "assets/tfresh/images/popup.jpg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "a", [["fxHide.gt-xs", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.preBook() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 4866048, null, 0, i9.DefaultShowHideDirective, [i1.ElementRef, i9.ShowHideStyleBuilder, i10.StyleUtils, i10.MediaMarshaller, i10.LAYOUT_CONFIG, i1.PLATFORM_ID, [2, i10.SERVER_TOKEN]], { "fxHide.gt-xs": [0, "fxHide.gt-xs"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 0, "img", [["alt", ""], ["src", "assets/tfresh/images/popup-mobile.jpg"]], null, null, null, null, null))], function (_ck, _v) { var currVal_4 = ""; _ck(_v, 2, 0, currVal_4); _ck(_v, 4, 0); var currVal_7 = ""; _ck(_v, 7, 0, currVal_7); var currVal_8 = ""; _ck(_v, 10, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); var currVal_2 = (i1.ɵnov(_v, 2).ariaLabel || null); var currVal_3 = i1.ɵnov(_v, 2).type; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_5 = i1.ɵnov(_v, 4).inline; var currVal_6 = (((i1.ɵnov(_v, 4).color !== "primary") && (i1.ɵnov(_v, 4).color !== "accent")) && (i1.ɵnov(_v, 4).color !== "warn")); _ck(_v, 3, 0, currVal_5, currVal_6); }); }
export function View_BookingsDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "bookings-dialog", [], null, null, null, View_BookingsDialogComponent_0, RenderType_BookingsDialogComponent)), i1.ɵdid(1, 114688, null, 0, i11.BookingsDialogComponent, [i12.Router, i6.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BookingsDialogComponentNgFactory = i1.ɵccf("bookings-dialog", i11.BookingsDialogComponent, View_BookingsDialogComponent_Host_0, {}, {}, []);
export { BookingsDialogComponentNgFactory as BookingsDialogComponentNgFactory };
