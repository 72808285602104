/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirm-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "@ngx-translate/core";
import * as i7 from "@angular/material/dialog";
import * as i8 from "@angular/flex-layout/flex";
import * as i9 from "@angular/cdk/bidi";
import * as i10 from "@angular/flex-layout/core";
import * as i11 from "@angular/common";
import * as i12 from "./confirm-dialog.component";
var styles_ConfirmDialogComponent = [i0.styles];
var RenderType_ConfirmDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmDialogComponent, data: {} });
export { RenderType_ConfirmDialogComponent as RenderType_ConfirmDialogComponent };
function View_ConfirmDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "cancel"], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNoClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(2, 0, ["", ""])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("No")); _ck(_v, 2, 0, currVal_2); }); }
export function View_ConfirmDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i7.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "p", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(3, 8536064, null, 0, i6.TranslateDirective, [i6.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 10, "div", [["class", "mat-dialog-actions"], ["fxLayoutAlign", "center center"], ["fxLayoutGap", "10px"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(6, 16384, null, 0, i7.MatDialogActions, [], null, null), i1.ɵdid(7, 1720320, null, 0, i8.DefaultLayoutGapDirective, [i1.ElementRef, i1.NgZone, i9.Directionality, i10.StyleUtils, [2, i8.LayoutGapStyleBuilder], i10.MediaMarshaller], { fxLayoutGap: [0, "fxLayoutGap"] }, null), i1.ɵdid(8, 671744, null, 0, i8.DefaultLayoutAlignDirective, [i1.ElementRef, i10.StyleUtils, [2, i8.LayoutAlignStyleBuilder], i10.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 4, "button", [["class", "ok"], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).dialogRef.close(i1.ɵnov(_v, 11).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(10, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(11, 606208, null, 0, i7.MatDialogClose, [[2, i7.MatDialogRef], i1.ElementRef, i7.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(12, 0, ["", ""])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmDialogComponent_1)), i1.ɵdid(15, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; _ck(_v, 3, 0, currVal_0); var currVal_2 = "10px"; _ck(_v, 7, 0, currVal_2); var currVal_3 = "center center"; _ck(_v, 8, 0, currVal_3); var currVal_8 = true; _ck(_v, 11, 0, currVal_8); var currVal_10 = !((_co.data == null) ? null : _co.data.hideCancel); _ck(_v, 15, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.data == null) ? null : _co.data.message); _ck(_v, 4, 0, currVal_1); var currVal_4 = (i1.ɵnov(_v, 10).disabled || null); var currVal_5 = (i1.ɵnov(_v, 10)._animationMode === "NoopAnimations"); var currVal_6 = (i1.ɵnov(_v, 11).ariaLabel || null); var currVal_7 = i1.ɵnov(_v, 11).type; _ck(_v, 9, 0, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_9 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform((((_co.data == null) ? null : _co.data.okText) || "Yes"))); _ck(_v, 12, 0, currVal_9); }); }
export function View_ConfirmDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-confirm-dialog", [], null, null, null, View_ConfirmDialogComponent_0, RenderType_ConfirmDialogComponent)), i1.ɵdid(1, 114688, null, 0, i12.ConfirmDialogComponent, [i7.MatDialogRef, i7.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmDialogComponentNgFactory = i1.ɵccf("app-confirm-dialog", i12.ConfirmDialogComponent, View_ConfirmDialogComponent_Host_0, {}, {}, []);
export { ConfirmDialogComponentNgFactory as ConfirmDialogComponentNgFactory };
