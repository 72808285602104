import { BehaviorSubject } from 'rxjs';
import { Router, NavigationStart, NavigationEnd, NavigationError, NavigationCancel } from '@angular/router';
import { OnInit } from '@angular/core';
var RouteProgressBarComponent = /** @class */ (function () {
    function RouteProgressBarComponent(router) {
        this.router = router;
        this.routeLoading = new BehaviorSubject(false);
    }
    RouteProgressBarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.router.events.subscribe(function (e) {
            if (e instanceof NavigationStart) {
                _this.routeLoading.next(true);
            }
            else if (e instanceof NavigationEnd) {
                _this.routeLoading.next(false);
            }
            else if (e instanceof NavigationError) {
                _this.routeLoading.next(false);
            }
            else if (e instanceof NavigationCancel) {
                _this.routeLoading.next(false);
            }
        });
    };
    Object.defineProperty(RouteProgressBarComponent.prototype, "routeLoading$", {
        get: function () {
            return this.routeLoading.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    return RouteProgressBarComponent;
}());
export { RouteProgressBarComponent };
