var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { TemplateRef, ViewContainerRef } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { KalgudiDestroyable } from 'src/app/classes/kalgudi-destroyable';
var FxIfDirective = /** @class */ (function (_super) {
    __extends(FxIfDirective, _super);
    function FxIfDirective(templateRef, viewContainer, mediaObserver) {
        var _this = _super.call(this) || this;
        _this.templateRef = templateRef;
        _this.viewContainer = viewContainer;
        _this.mediaObserver = mediaObserver;
        _this.hasView = false;
        return _this;
    }
    Object.defineProperty(FxIfDirective.prototype, "fxIf", {
        set: function (mqAliases) {
            this.updateView(mqAliases);
        },
        enumerable: true,
        configurable: true
    });
    FxIfDirective.prototype.updateView = function (mqAliases) {
        var _this = this;
        if (!mqAliases)
            this.clearView();
        this.mediaObserver.asObservable()
            .pipe(takeUntil(this.destroyed$), distinctUntilChanged(), tap(function (_) { return _this.mediaObserver.isActive(mqAliases) ? _this.showView() : _this.clearView(); })).subscribe();
    };
    FxIfDirective.prototype.clearView = function () {
        if (!this.hasView)
            return;
        this.viewContainer.clear();
        this.hasView = false;
    };
    FxIfDirective.prototype.showView = function () {
        if (this.hasView)
            return;
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.hasView = true;
    };
    FxIfDirective.prototype.onDestroyed = function () { };
    return FxIfDirective;
}(KalgudiDestroyable));
export { FxIfDirective };
