import { OnInit, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SearchService } from '../../services/search.service';
import { StorefrontService } from 'src/app/storefront/storefront.service';
var SearchbarComponent = /** @class */ (function () {
    function SearchbarComponent(searchService, storeFrontService) {
        this.searchService = searchService;
        this.storeFrontService = storeFrontService;
        this.change = new EventEmitter();
        this.myControl = new FormControl();
        this.isTrue = true;
        this.selectedCategory = { id: '', value: '' };
    }
    SearchbarComponent.prototype.ngOnInit = function () {
        this.request = {
            offset: 0,
            limit: 10
        };
        this.initSuggestedCategory();
        var searchtextData = localStorage.getItem("recentSearches");
        var recentSearches = JSON.parse(searchtextData);
        if (recentSearches && recentSearches.length > 0) {
            this.recentSearches = recentSearches.reverse();
        }
        else {
            this.recentSearches = recentSearches;
        }
    };
    SearchbarComponent.prototype.autoComplete = function (text, event) {
        var _this = this;
        if (event.keyCode == 37 || event.keyCode == 38 || event.keyCode == 39 || event.keyCode == 40) {
            return;
        }
        ;
        if (text && text.length >= 3 && text != this.selectedText) {
            clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(function () {
                _this.getSuggestions(text);
            }, 500);
        }
        else {
            clearTimeout(this.timeoutId);
        }
    };
    SearchbarComponent.prototype.getSuggestions = function (text) {
        var _this = this;
        this.searchService.getSuggessions(text, this.request, this.selectedCategory.id).subscribe(function (res) {
            if (typeof (res.data) == "string") {
                res.data = JSON.parse(res.data);
            }
            res.data.forEach(function (suggestion) {
                var title = suggestion.doc.productLevel2Title;
                var exp = new RegExp(text.toLowerCase(), 'g');
                var result = title.toLowerCase().replace(exp, "<span class='bold'>" + text + "</span>");
                suggestion.doc.productLevel2Title = result;
            });
            _this.products = res.data;
        });
    };
    SearchbarComponent.prototype.check = function (item) {
        var exp1 = new RegExp("<span class='bold'>", 'g');
        var exp2 = new RegExp("</span>", 'g');
        item.productLevel2Title = item.productLevel2Title.replace(exp1, '').replace(exp2, '');
        this.searchText = item.productLevel2Title;
        this.selectedText = item.productLevel2Title;
        this.search(this.searchText, item.baseCategory.id);
        // this.change.emit(this.searchText);
        this.showSearch = false;
    };
    SearchbarComponent.prototype.keyEnter = function () {
        var exp1 = new RegExp("<span class='bold'>", 'g');
        var exp2 = new RegExp("</span>", 'g');
        var category = '';
        if (this.searchText.productLevel2Title) {
            category = this.searchText.baseCategory.id;
            this.searchText = this.searchText.productLevel2Title.replace(exp1, '').replace(exp2, '');
        }
        this.search(this.searchText, category);
        // this.change.emit(this.searchText);
        this.isTrue = false;
        this.showSearch = false;
    };
    SearchbarComponent.prototype.keydown = function () {
        this.isTrue = true;
    };
    Object.defineProperty(SearchbarComponent.prototype, "categories$", {
        get: function () {
            return this.storeFrontService.categories$;
        },
        enumerable: true,
        configurable: true
    });
    SearchbarComponent.prototype.search = function (keyword, category) {
        var search = {
            keyword: keyword,
            category: category || this.selectedCategory.id || ''
        };
        this.change.emit(search);
        var searchText = JSON.parse(localStorage.getItem('recentSearches') || '[]');
        if (!searchText.includes(this.searchText)) {
            if (searchText.length == 6) {
                searchText.splice(0, 1);
            }
            searchText.push(this.searchText.toLowerCase());
            localStorage.setItem('recentSearches', JSON.stringify(searchText));
        }
    };
    SearchbarComponent.prototype.initSuggestedCategory = function () {
        this.suggestedCategory = [
            'Girijan Honey',
            'Pickles',
            'Karam',
            'Coffee',
            'Herbal Soaps',
            'Turmeric',
            'Pulses'
        ];
    };
    return SearchbarComponent;
}());
export { SearchbarComponent };
