import { Injectable } from '@angular/core';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Storefront } from '../properties/constants';
import { Observable } from 'rxjs';
import { ServiceResponse } from '../models/network-request.model';
import { CountryDetails, SessionInformation, SubscribeUsPayload } from '../models/subscriber-payload';
import { KalgudiUser } from '@kalgudi/types';
import { UtilityService } from './utility.service';
import { Router } from '@angular/router';
import { NotificationService } from './notification.service';



@Injectable({
  providedIn: 'root'
})
export class UserSubscriberService {

  private countryDetails: CountryDetails;
  private sessionInfo: SessionInformation;

  constructor(
    private storage: LocalStorage,
    private http: HttpClient,
    private util: UtilityService,
    private router: Router,
    private notification: NotificationService,
    
  ) {
    this.storage.getItem('countryDetails').subscribe(countryDetails => this.countryDetails = countryDetails);
    this.getUserData();
    this.notification.userDataChanges.subscribe(response => { 
      this.getUserData();
    });
  }

  public subscribeToNewsLetters(emailId: string): Observable<ServiceResponse> {
    const payload = {
      countryCodeInfo: this.countryDetails,
      page: this.router.url,
      email: emailId,
      sessionInfo: this.sessionInfo
    }

    return this.subscribeUsApi(new SubscribeUsPayload("apGreen subscription", payload));
  }



  public subscribeToGetAppLink(phone: number): Observable<ServiceResponse> {
    const payload = {
      page: this.router.url,
      phone: phone,
      countryCodeInfo: this.countryDetails,
      sessionInfo: this.sessionInfo
    }

    return this.subscribeUsApi(new SubscribeUsPayload("apGreen subscription", payload));
  }




  public requestForNewPin(payload: SubscribeUsPayload) {
    payload = {
      ...payload,
      countryCodeInfo: this.countryDetails,
      page: this.router.url,
      sessionInfo: this.sessionInfo
    }
    return this.subscribeUsApi(new SubscribeUsPayload('apGreens Pincode', payload));
  }

  private subscribeUsApi(request: SubscribeUsPayload): Observable<ServiceResponse> {
    const api = `${environment.nodeServer}/v2/bizstore/profiles/${Storefront.APP_NAME}/signup-request`;
    return this.http.post<ServiceResponse>(api, request);
  }

  private getUserData() {
    this.storage.getItem('userdata').subscribe((user: KalgudiUser) => {
      if (user) {
        this.sessionInfo = {
          firstName: user.firstName,
          profileKey: user.profileKey,
          emailId: this.util.getEmailId(user),
          mobileNo: this.util.getUserMobileNumber(user),
          loginId: user.mobileNo
        }
      }
    });
  }

  public subscribeForProduct(request: any): Observable<ServiceResponse> {
    const payload = {
      page: this.router.url,
      enquiryType: 'Product subscription',
      subscriptionFrequency: '', // Once a week | Twice a week | Once a month | Twice a month
      productId: request.productLevel2Id,
      productName: request.productLevel2Title,
      email: request.email,
      name: request.name,
      countryCodeInfo: this.countryDetails,
      sessionInfo: this.sessionInfo
    }

    return this.subscribeUsApi(new SubscribeUsPayload('product subscription', payload));
  }
}
