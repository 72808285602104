export class Attachment {
    context: string;
    msgType: string;
    width?: any;
    height?: any;
    index?: string;

    constructor(public url: string, public type: AttachmentType){}
}

export enum AttachmentType {
    image = 'IMAGE',
    document = 'DOCUMENT'
}