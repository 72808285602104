import { Router } from "@angular/router";
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var CoreRouteConfigService = /** @class */ (function () {
    function CoreRouteConfigService(router) {
        this.router = router;
        this.pageNotFoundPath = '';
        this.authGuardFailedPath = '/auth/signin';
        this.noAuthGuardFailedPath = '/';
    }
    /**
     * Method called by the auth guards on success route.
     */
    CoreRouteConfigService.prototype.onGuardSuccess = function (route, state) { };
    /**
     * Method called by auth guards on failed route.
     */
    CoreRouteConfigService.prototype.onAuthGuardFailure = function (route, state) { };
    /**
     * Method called by auth guards on failed route.
     */
    CoreRouteConfigService.prototype.onNoAuthGuardFailure = function (route, state) { };
    /**
     * Routes app to the home page
     */
    CoreRouteConfigService.prototype.toHome = function (params, queryParams) {
        var url = "/";
        this.route(url, queryParams);
        return url;
    };
    CoreRouteConfigService.prototype.toSocialQaFullview = function (params, queryParams) {
        var url = 'https://core.kalgudi.com/app/social/qa/:questionId';
        url = url.replace(':questionId', params.questionId);
        window.open(url, '_blank');
        return url;
    };
    /**
   * Routes the app to the specific url
   */
    CoreRouteConfigService.prototype.route = function (url, queryParams) {
        this.router.navigate([url], { queryParams: queryParams });
    };
    CoreRouteConfigService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CoreRouteConfigService_Factory() { return new CoreRouteConfigService(i0.ɵɵinject(i1.Router)); }, token: CoreRouteConfigService, providedIn: "root" });
    return CoreRouteConfigService;
}());
export { CoreRouteConfigService };
