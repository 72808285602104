import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
var InterceptorService = /** @class */ (function () {
    function InterceptorService() {
    }
    InterceptorService.prototype.intercept = function (request, next) {
        var userName = localStorage.getItem('dXNlcm5hbWU=') ? atob(localStorage.getItem('dXNlcm5hbWU=')) : '';
        var password = localStorage.getItem('cGFzc3dvcmQ=') ? atob(localStorage.getItem('cGFzc3dvcmQ=')) : '';
        if (!(request.body instanceof FormData)) {
            request = request.clone({
                headers: request.headers.append('Content-Type', 'application/json')
            });
        }
        request = request.clone({
            headers: request.headers.append('version', environment.version)
        });
        request = request.clone({
            headers: request.headers.append('source', environment.source)
        });
        if (userName && password) {
            request = request.clone({
                headers: request.headers.append('userName', userName)
            });
            request = request.clone({
                headers: request.headers.append('password', password)
            });
        }
        return next.handle(request);
    };
    InterceptorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InterceptorService_Factory() { return new InterceptorService(); }, token: InterceptorService, providedIn: "root" });
    return InterceptorService;
}());
export { InterceptorService };
