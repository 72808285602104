import { Injectable } from '@angular/core';
import { SpinnerService } from 'src/app/project-common/services/spinner.service';
import { MatSnackBar } from '@angular/material';
import { KalgudiNotification } from '@kalgudi/core/config';

@Injectable({
  providedIn: 'root'
})
export class CoreNotificationConfigService implements KalgudiNotification{

  constructor(private spinner: SpinnerService, private snackbar: MatSnackBar) { }

  showMessage(message: string, action = '', duration?: number): void {

    this.snackbar.open(message, action, {
      duration: duration || 5000
    });
  }

  showSpinner(blocking?: boolean): void {

    this.spinner.changeStatus(true, true);
  }

  hideSpinner(): void {

    this.spinner.changeStatus(false);
  }
}
